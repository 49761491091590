import React from 'react';

const valuesBlog = [
    {icon: 'icon-diamond',title:'Be an ambassador',description:'We are proud to be problem solvers. We like the people we work with – coworkers, managers, clients – and genuinely enjoy helping one another succeed.'},
    {icon: 'icon-chart',title:'Enjoy the journey',description:'We make sure our team feels appreciated and valued. We take the time to stop and acknowledge where we came from so that we don’t miss how impressive the climb is.'},
    {icon: 'icon-trophy',title:'Get it done',description:'Clients trust us to build things that work and we take that seriously. Our team will overcome obstacles, find solutions and deliver exceptional results'},
]

const valueBlog2 = [
    {icon: 'icon-present',title:'Evolve and adapt',description:'Our employees are intellectually curious and open-minded.We know that there is always room for growth. We embrace failure and mistakes, as long as the lesson is learnt and the error never repeated. We believe your time at BVM should make you better.?'},
    {icon: 'icon-envelope-open',title:'Do the right thing',description:'What do you do when no one else is looking? Our teams act with integrity and honesty, and focus on putting ourselves in the shoes of others.'},
    {icon: 'icon-globe',title:'Make something better, today',description:' We\'re hungry, we\'re passionate, and we love tough problems and new challenges. You don\'t hear a lot of "I don\'t know how" or "I can\'t" at BVM Infotech. When faced with a hurdle, we jump.'},
]

const Values = () =>{
    return(
        <div className="flat-row  pad-bottom90px">
            <div className="container">
                <div className="row">
                    <div className="title-section ">
                        <h3 className="title1 text-center">Our Values</h3>
                    </div>
                </div>
                <div className="flat-divider d40px"/>
                <div className="row">
                    {
                        valuesBlog.map((blog,i) => (
                            <div key={i} className="col-md-4">
                                <div className="iconbox style_4">
                                    <div className="box-header">
                                        <div className="box-icon"><i className={`fa icons ${blog.icon}`}/></div>
                                        <h5 className="box-title">{blog.title}</h5>
                                    </div>
                                    <div className="box-content">
                                        {blog.description}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="flat-divider d50px"/>
                <div className="row">
                    {
                        valueBlog2.map((blog,i) => (
                            <div key={i} className="col-md-4">
                                <div className="iconbox style_4">
                                    <div className="box-header">
                                        <div className="box-icon"><i className={`fa icons ${blog.icon}`}/></div>
                                        <h5 className="box-title">{blog.title}</h5>
                                    </div>
                                    <div className="box-content">
                                        {blog.description}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
};

export default Values;