import React from 'react';

const About = () => {
    return (
        <>
            <div id="site-content">
                <div id="page-body">
                    <div className="flat-row pad-top60px pad-bottom60px parallax">
                        <div className="container">
                            <div className="row center">
                                <div className="col-lg-12">
                                    <p className="approach">"We are a leading software development company that offers
                                        top-rated Software Development Services due to our vast experience, team of
                                        skilled professionals, key business insights, and a dedicated working
                                        process"</p>
                                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                                        <h1 className="consult h2-heading">OUR APPROACH</h1>
                                        <div className="title text-justify">
                                            <p>With the fast paced life we live, we follow agile methodology in
                                                implementing our projects. We break the need into smaller chunks to show
                                                the results in every sprint.</p><br/>
                                            <p>The more knowledge we have in the technology we deal with and business /
                                                process we deal with, the better it is. We acquire the required
                                                knowledge through effective reverse engineering process. We have few
                                                accelerators to find the required information from Data Layer to
                                                accelerate our understanding of the business and data. In the initial
                                                meetings, we listen more and more to feel the customer so that we become
                                                part of them over time.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flat-row pad-top20px pad-bottom40px parallax">
                        <div className="container">
                            <div className="row center">
                                <div className="col-lg-12">
                                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                                        <div className="text-container-1 text-justify">
                                            <h5>Listen to Customer Requirement</h5>
                                            <ul className="b">
                                                <li>What’s the need? Is it a new development, enhancements, bug fixing
                                                    or a consulting work?
                                                </li>
                                                <li>Based on the type of requirement, set the Goals and move forward.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flat-row pad-top20px pad-bottom40px parallax">
                        <div className="container">
                            <div className="row center">
                                <div className="col-lg-12">
                                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                                        <div className="text-container-1 text-justify">
                                            <h5>Understand available infrastructure, People &amp; Process</h5>
                                            <p>Fewer times, everyone knows what to do. Problem is there are too many
                                                networks, databases and teams to coordinate to get the job done. Involve
                                                customer infrastructure team and stakeholders to be in the same
                                                page.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flat-row pad-top20px pad-bottom40px parallax">
                        <div className="container">
                            <div className="row center">
                                <div className="col-lg-12">
                                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                                        <div className="text-container-1 text-justify">
                                            <h5>Analyze the requirement with different stakeholders</h5>
                                            <p>A team of Infra, BA and Technical person gets the big picture fast. Dwell
                                                into details and get applicable Solutions based on existing &amp; new
                                                technologies. List the Solutions &amp; Pros and Cons of each
                                                Solution.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flat-row pad-top20px pad-bottom40px parallax">
                        <div className="container">
                            <div className="row center">
                                <div className="col-lg-12">
                                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                                        <div className="text-container-1 text-justify">
                                            <h5>Agree on the Solution, Process &amp; Technology </h5>
                                            <p>Options are plenty when it comes to technology in the current
                                                environment. We can choose from Open Source, Pre Packaged Cloud Solution
                                                to Proprietary technologies. Based on the available resources (human),
                                                time to market and budget constraints pick the solution.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flat-row pad-top20px pad-bottom40px parallax">
                        <div className="container">
                            <div className="row center">
                                <div className="col-lg-12">
                                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                                        <div className="text-container-1 text-justify">
                                            <h5>Design &amp; Show the Prototype</h5>
                                            <p>Using the wire frames and office tools to show the high level design to
                                                get the approval.
                                                Build a POC to get a buy in before investing too much time into building
                                                the whole solution.
                                                By this time customer understands us and we understand customers very
                                                well.
                                                Enable the team with enough business knowledge through available
                                                artifacts about the customer. Make the team to feel confident about the
                                                problem they are solving.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flat-row pad-top20px pad-bottom40px parallax">
                        <div className="container">
                            <div className="row center">
                                <div className="col-lg-12">
                                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                                        <div className="text-container-1 text-justify">
                                            <h5>Development</h5>
                                            <p>Enable the developers with right tools and knowledge required.
                                                Follow the agile method and build the application. Involve end customer
                                                in a weekly basis and get the blessing on the output to make sure all
                                                the stakeholders are in same page.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flat-row pad-top20px pad-bottom40px parallax">
                        <div className="container">
                            <div className="row center">
                                <div className="col-lg-12">
                                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                                        <div className="text-container-1 text-justify">
                                            <h5>Quality Assurance</h5>
                                            <p>We become an end user to feel the application and simulate the scenarios
                                                which we captured in our requirements.
                                                Create new sets of data to make sure all the data issues are taken care
                                                of.
                                                Make sure Application flow and Work flow of the application, security
                                                controls are implemented based on the requirements.
                                                Automate the test cases so that we can go regression testing to reduce
                                                the time it takes for new releases.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flat-row pad-top20px pad-bottom40px parallax">
                        <div className="container">
                            <div className="row center">
                                <div className="col-lg-12">
                                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                                        <div className="text-container-1 text-justify">
                                            <h5>Devops &amp; Migration</h5>
                                            <p>Work with industry standard Devops tool to automate the migration.
                                                Work with customer’s Devops team to follow the process and suggest the
                                                improvements.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flat-row pad-top20px pad-bottom40px parallax">
                        <div className="container">
                            <div className="row center">
                                <div className="col-lg-12">
                                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                                        <div className="text-container-1 text-justify">
                                            <h5>Enhancement &amp; Support</h5>
                                            <p>Support the implementation and stabilize the environment. Monitor the
                                                application through log files and tickets which are raised by end users.
                                                Enhance the application on need basis so that application runs on
                                                auto-pilot mode.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="site-content">
                <div id="page-body">
                    <div className="container">
                        <div className="row">
                            <h1 className="values text-center ">OUR CULTURE</h1>
                        </div>
                        <br/>
                        <div className="row center">
                            <div className="col-md-8 col-md-offset-2 col-sm-12">
                                <div className="section-title-parralax text-justify">
                                    <p className="">
                                        We are a company focused in Empowering Employees to take care of Customers
                                        by Adding Value in every aspect of the engagement. We pride ourselves in
                                        Being Agile &amp; Collaborative to make a difference with our dependable
                                        presence and close attention to details. Being Inclusive in every aspect, we
                                        provide opportunity to everyone and script memorable stories, one at a time.
                                        Ultimately, rather than walking fast and alone, we walk far and together,
                                        every step of the way.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>

                </div>
            </div>
        </>
    )
};

export default About;