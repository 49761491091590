import React from "react";

const Contact = () => {
  window.scroll(0, 0);
  return (
    <div className="boxed">
      <div id="site-content">
        <div id="page-header">
          <div className="container">
            <div className="row">
              <div className="page-title">
                <h2 className="title">Contact Us</h2>
              </div>
              <div id="page-breadcrumbs">
                <nav className="breadcrumb-trail breadcrumbs">
                  <h2 className="trail-browse">You are here:</h2>
                  <ul className="trail-items">
                    <li className="trail-item trail-end">Contact Us //</li>
                    <li className="trail-item trail-begin">
                      <a href="/home">Home</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div id="page-body">
          <div className="flat-row parallax parallax16 pad-top60px pad-bottom60px">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="iconbox style_3">
                    <div className="box-header">
                      <div className="box-icon">
                        <i className="fa icons icon-globe" />
                      </div>
                      <h4 className="box-title">Corporate Office</h4>
                    </div>
                    <div className="box-content">
                      <div className="map-icon-one">
                        <i
                          className="fa fa-map-marker"
                          style={{ fontSize: "20px" }}
                        />
                      </div>
                      <div className="container">
                        <a
                          href="https://www.google.com/maps/dir/21.2271104,72.8530944/bvm+infotech/@21.2277388,72.8351557,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3be04ffbc78e317d:0x5f4b5a0a2faa5e55!2m2!1d72.8634371!2d21.233602"
                          target="blank"
                          style={{
                            paddingLeft: 25,
                            paddingRight: 25,
                            textAlign: "center",
                          }}
                        >
                          1049-1051, Silver Business Point,near VIP Circle,
                          Uttran, Surat, Gujarat -
                          <span className="family">394105</span>
                        </a>
                      </div>
                    </div>
                    <div className="box-content" style={{ margin: "15px 0px" }}>
                      <div className="map-icon-two">
                        <i
                          className="fa fa-map-marker"
                          style={{ fontSize: "20px" }}
                        />
                      </div>
                      <div className="container">
                        <a
                          href="https://www.google.com/maps/place/ICC+Complex+building/@21.1812643,72.8052387,14z/data=!4m9!1m2!2m1!1sBlock-B,+A-307,+ICC+building,+Majura+gate,+Surat!3m5!1s0x3be04e6be4ccc641:0xd07b5543f06ee5fc!8m2!3d21.1812624!4d72.8227376!15sCjBCbG9jay1CLCBBLTMwNywgSUNDIGJ1aWxkaW5nLCBNYWp1cmEgZ2F0ZSwgU3VyYXRaLiIsYmxvY2sgYiBhIDMwNyBpY2MgYnVpbGRpbmcgbWFqdXJhIGdhdGUgc3VyYXSSAQ9idXNpbmVzc19jZW50ZXKaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVUnBhMkpEWXpkQlJSQUI"
                          target="blank"
                          style={{
                            paddingLeft: 25,
                            paddingRight: 25,
                            textAlign: "center",
                          }}
                        >
                          Block-B, A-307, Icc building, Majura Gate, Surat,
                          <br />
                          <span className="family">Gujarat -395000</span>
                        </a>
                      </div>
                    </div>
                    <div className="box-content" style={{ margin: "15px 0px" }}>
                      <div className="map-icon-three">
                        <i
                            className="fa fa-map-marker"
                            style={{ fontSize: "20px" }}
                        />
                      </div>
                      <div className="container">
                        <a
                            href="https://www.google.com/maps/dir/21.2148077,72.8633633/R.K.+Empire,+150+Feet+Ring+Road,+Near,+Mavdi+Circle,+Rajkot,+Gujarat+360004/@21.9271003,71.4521053,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3959ca5dbe7afda3:0x6d8e1af5be0f4126!2m2!1d70.7867823!2d22.2628316"
                            target="blank"
                            style={{
                              paddingLeft: 25,
                              paddingRight: 25,
                              textAlign: "center",
                            }}
                        >
                          701, RK Empire, 150ft Ring road, Near Mavdi circle, Rajkot, Gujarat-360004
                          <br />
                        </a>
                      </div>
                    </div>
                    <div className="box-content" style={{ margin: "15px 0px" }}>
                      <div className="map-icon-four">
                        <i
                            className="fa fa-map-marker"
                            style={{ fontSize: "20px" }}
                        />
                      </div>
                      <div className="container">
                        <a
                            href="https://www.google.com/maps/dir/21.2338694,72.863378/Laxmi+Enclave,+Laxmi+Circle,+opposite+Gajera+School,+Katargam,+Surat,+Gujarat+395004/@21.2350878,72.8409115,15z/data=!3m1!4b1!4m13!1m2!2m1!1s618,+Laxmi+anclave-2,+opp.+Gajera+school,+Katargam!4m9!1m1!4e1!1m5!1m1!1s0x3be04ec5b3b07867:0xe19d6dbe04ec5f3d!2m2!1d72.8359546!2d21.2325758!3e0"
                            target="blank"
                            style={{
                              paddingLeft: 25,
                              paddingRight: 25,
                              textAlign: "center",
                            }}
                        >
                          618, Laxmi anclave-2, opp. Gajera school, Katargam, Surat,
                          <br />
                          <span className="family">Gujarat -395004</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="iconbox style_3" style={{height:"427px"}}>
                    <div className="box-header">
                      <div className="box-icon">
                        <i className="fa icons icon-diamond" />
                      </div>
                      <h4 className="box-title">Looking for a career?</h4>
                    </div>
                    <div className="box-content">
                      <div className="container">
                        <p className="telephone center">
                          Telephone:
                          <span className="family">
                            <a href="tel:+918758607151">+91-8758607151</a>
                          </span>
                          <br />
                        </p>
                        <div className="flat-divider d20px" />
                        <p className="box-readmore center">
                          <a href="mailto:info@bvminfotech.com">
                            info@bvminfotech.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
