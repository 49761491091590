import React from 'react';
import img1 from "../Assets/images/portfolio/p1.jpg";
import img2 from "../Assets/images/portfolio/p2.jpg";
import img3 from "../Assets/images/portfolio/p3.jpg";
import img4 from "../Assets/images/portfolio/p4.jpg";
import img5 from "../Assets/images/portfolio/p5.jpg";
import img6 from "../Assets/images/portfolio/p6.jpg";
import img7 from "../Assets/images/portfolio/p7.jpg";
import img8 from "../Assets/images/portfolio/p8.jpg";
import img9 from "../Assets/images/portfolio/p9.jpg";
import img10 from "../Assets/images/portfolio/p10.jpg";
import img11 from "../Assets/images/portfolio/p11.jpg";
import img12 from "../Assets/images/portfolio/p12.jpg";
import img13 from "../Assets/images/portfolio/debuity.png";
import img14 from "../Assets/images/portfolio/luci.jpg";
import img15 from "../Assets/images/portfolio/neobenk.png";
import img16 from "../Assets/images/portfolio/south west.png";

const PortFolio = () => {
    return (
        <>
            <section className="parallax-sec  blox  full-container  portfolio-heading w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                backgroundColor: '#113685',
                backgroundSize: 'cover',
                minHeight: '80px',
                textAlign: "center"
            }} data-stellar-background-ratio="0.6">
                <div className="max-overlay" style={{backgroundColor: ''}}/>
                <div data-stellar-ratio={1} className="wpb_row vc_row-fluid ">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <h1 style={{fontSize: '38px', color: '#ffffff', textAlign: 'center'}}
                                        className="vc_custom_heading"><p/>
                                        <p>
                                        </p>
                                        <center>Our Featured works</center>
                                    </h1>
                                    <hr className="vertical-space1"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x"/>
            </section>
            <section className="parallax-sec  blox  full-container  portfolio-heading w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                backgroundColor: 'rgba(17,54,133,0.01)',
                backgroundSize: 'cover',
                minHeight: '80px',
                textAlign: "center"
            }} data-stellar-background-ratio="0.6">
                <div className="max-overlay" style={{backgroundColor: ''}}/>
                <div data-stellar-ratio={1} className="wpb_row vc_row-fluid ">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space2"/>
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_custom_1562390407225  vc_custom_1562390407225">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                            style={{borderColor: 'rgba(0,0,0,0.01)'}}
                                            className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span
                                        style={{borderColor: 'rgba(0,0,0,0.01)'}} className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x"/>
            </section>
            <section className="wpb_row  text_box_shadow w-animate  wpb_animate_1 full-row">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                            <div
                                className="vc_row wpb_row vc_inner vc_row-fluid row-first vc_column-gap-2 vc_row-o-equal-height vc_row-flex">
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img1}
                                                                   data-rel="prettyPhoto[rel-9738-286775069]"><img
                                                                width={470} height={368}
                                                                src={img1}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade "
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img2}
                                                                   data-rel="prettyPhoto[rel-9738-218906677]"><img
                                                                width={470} height={368}
                                                                src={img2}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img3}
                                                                   data-rel="prettyPhoto[rel-9738-1734073247]"><img
                                                                width={470} height={368}
                                                                src={img3}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img4}
                                                                   data-rel="prettyPhoto[rel-9738-1061803435]"><img
                                                                width={470} height={368}
                                                                src={img4}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="vc_row wpb_row vc_inner vc_row-fluid vc_column-gap-2 vc_row-o-equal-height vc_row-flex">
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img5}
                                                                   data-rel="prettyPhoto[rel-9738-511540149]"><img
                                                                width={470} height={368}
                                                                src={img5}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img6}
                                                                   data-rel="prettyPhoto[rel-9738-922935441]"><img
                                                                width={470} height={368}
                                                                src={img6}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img7}
                                                                   data-rel="prettyPhoto[rel-9738-1270411928]"><img
                                                                width={470} height={368}
                                                                src={img7}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img8}
                                                                   data-rel="prettyPhoto[rel-9738-2031133509]"><img
                                                                width={470} height={368}
                                                                src={img8}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="vc_row wpb_row vc_inner vc_row-fluid vc_column-gap-2 vc_row-o-equal-height vc_row-flex">
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img9}
                                                                   data-rel="prettyPhoto[rel-9738-511540149]"><img
                                                                width={470} height={368}
                                                                src={img9}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img10}
                                                                   data-rel="prettyPhoto[rel-9738-922935441]"><img
                                                                width={470} height={368}
                                                                src={img10}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img11}
                                                                   data-rel="prettyPhoto[rel-9738-1270411928]"><img
                                                                width={470} height={368}
                                                                src={img11}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img12}
                                                                   data-rel="prettyPhoto[rel-9738-2031133509]"><img
                                                                width={470} height={368}
                                                                src={img12}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img13}
                                                                   data-rel="prettyPhoto[rel-9738-1270411928]"><img
                                                                width={470} height={368}
                                                                src={img13}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img14}
                                                                   data-rel="prettyPhoto[rel-9738-1270411928]"><img
                                                                width={470} height={368}
                                                                src={img14}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img15}
                                                                   data-rel="prettyPhoto[rel-9738-1270411928]"><img
                                                                width={470} height={368}
                                                                src={img15}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-one wpb_column vc_column_container vc_col-sm-3">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_gallery wpb_content_element vc_clearfix">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_gallery_slides wpb_flexslider flexslider_fade"
                                                        data-interval={0} data-flex_fx="fade">
                                                        <ul className="slides">
                                                            <li><a className="prettyphoto"
                                                                   href={img16}
                                                                   data-rel="prettyPhoto[rel-9738-1270411928]"><img
                                                                width={470} height={368}
                                                                src={img16}
                                                                className="attachment-full" alt=""
                                                                sizes="(max-width: 470px) 100vw, 470px"/></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x"><p/>
                </div>
            </section>
        </>
    )
};

export default PortFolio;