import React from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Home from "./Home";
import Nav from "./Nav";
import Footer from "./Footer";
import Contact from "./Contact";
import Blog from "./Blog";
import About from "./About";
import Career from "./Career";
import WebDesign from "./Services/Frond End/webDesign";
import Responsive_Mobile from "./Services/Frond End/Responsive_Mobile";
import GraphicDesign from "./Services/Frond End/GraphicDesign";
import CustomWebDesign from "./Services/Frond End/CustomWebDesign";
import WebDevelopment from "./Services/Web & App Development/WebDevelopment";
import PhPDevelopment from "./Services/Web & App Development/PHPDevelopment";
import WordPressDevelopment from "./Services/Web & App Development/WordPressDevelopment";
import eCommerceDevelopment from "./Services/Web & App Development/eCommerceDevelopment";
import IphoneAppDevelopment from "./Services/Web & App Development/IphoneAppDevelopment";
import AngularJSDevelopment from "./Services/Web & App Development/AngularJSDevelopment";
import FullStackDevelopment from "./Services/Web & App Development/FullStackDevelopment";
import ReactJSDevelopment from "./Services/Web & App Development/ReactJsDevelopment";
import VueJSDevelopment from "./Services/Web & App Development/VueJsDevelopment";
import AndroidDevelopment from "./Services/Web & App Development/AndroidDevelopment";
import NetDevelopment from "./Services/Web & App Development/.netDevelopment";
import ReactNativeDevelopment from "./Services/Web & App Development/ReactNativeDevelopment";
import HireDedicatedResources from "./Services/Dedicated Resource/HireDedicatedResources";
import PortFolio from "./PortFolio";
import BlogPage from "./Blog/blogpage";


const App = props => {
    return (
        <>
            <BrowserRouter>
                <Nav/>
                <div className="animation">
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route  path="/home" component={Home}/>
                    <Route  path="/blogpage/:id" component={BlogPage}/>
                    <Route  path="/service/frontEnd/webDesign" component={WebDesign}/>
                    <Route  path="/service/frontEnd/responsive_mobile_website" component={Responsive_Mobile}/>
                    <Route  path="/service/frontEnd/graphicDesign" component={GraphicDesign}/>
                    <Route  path="/service/frontEnd/customWebDesign" component={CustomWebDesign}/>
                    <Route  path="/service/web&app_Development/webDevelopment" component={WebDevelopment}/>
                    <Route  path="/service/web&app_Development/phpDevelopment" component={PhPDevelopment}/>
                    <Route  path="/service/web&app_Development/wordPressDevelopment" component={WordPressDevelopment}/>
                    <Route  path="/service/web&app_Development/eCommerceDevelopment" component={eCommerceDevelopment}/>
                    <Route  path="/service/web&app_Development/iPhoneAppDevelopment" component={IphoneAppDevelopment}/>
                    <Route  path="/service/web&app_Development/angularJSDevelopment" component={AngularJSDevelopment}/>
                    <Route  path="/service/web&app_Development/fullStackDevelopment" component={FullStackDevelopment}/>
                    <Route  path="/service/web&app_Development/reactJSDevelopment" component={ReactJSDevelopment}/>
                    <Route  path="/service/web&app_Development/vueJSDevelopment" component={VueJSDevelopment}/>
                    <Route  path="/service/web&app_Development/androidDevelopment" component={AndroidDevelopment}/>
                    <Route  path="/service/web&app_Development/.netDevelopment" component={NetDevelopment}/>
                    <Route  path="/service/web&app_Development/reactNativeDevelopment" component={ReactNativeDevelopment}/>
                    <Route  path="/service/dedicatedResource/hireDedicatedResource" component={HireDedicatedResources}/>
                    <Route  path="/portFolio" component={PortFolio}/>
                    <Route  path="/blog" component={Blog}/>
                    <Route  path="/about" component={About}/>
                    <Route  path="/career" component={Career}/>
                    <Route path="/contact" component={Contact}/>
                </Switch>
                </div>
                <Footer/>
            </BrowserRouter>

            <a href="#" className="go-top">
                <i className="fa fa-chevron-up"/>
            </a>
        </>
    )
};

export default App;




