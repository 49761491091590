import React from 'react';
import bg from "../../Assets/wp-content/uploads/2017/07/wordpresscont2bg.jpg"
import img1 from "../../Assets/wp-content/uploads/2017/07/Layer-39.png"
import img2 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object-2.png"
import icon1 from "../../Assets/wp-content/uploads/2017/07/Ellipse-13.png"
import icon2 from "../../Assets/wp-content/uploads/2017/07/Ellipse-13-copy-3.png"
import icon3 from "../../Assets/wp-content/uploads/2017/07/Ellipse-13-copy-4.png"
import icon4 from "../../Assets/wp-content/uploads/2017/07/Forma-1.png"
import icon5 from "../../Assets/wp-content/uploads/2017/07/Forma-11.png"
import icon6 from "../../Assets/wp-content/uploads/2017/07/Layer-31.png"
import icon7 from "../../Assets/wp-content/uploads/2017/07/Forma-12.png"
import icon8 from "../../Assets/wp-content/uploads/2017/07/Layer-32.png"
import icon9 from "../../Assets/wp-content/uploads/2017/07/Layer-33.png"
import icon10 from "../../Assets/wp-content/uploads/2017/07/Layer-38.png"
import icon11 from "../../Assets/wp-content/uploads/2017/07/Layer-37.png"
import icon12 from "../../Assets/wp-content/uploads/2017/07/Layer-36.png"
import icon13 from "../../Assets/wp-content/uploads/2017/07/Layer-35.png"
import icon14 from "../../Assets/wp-content/uploads/2017/07/Layer-34.png"
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'

const PhPDevelopment = () => {
    window.scroll(0,0)
    return (
        <div className="bvm">
        <div id="wrap" className="">
            <section id="main-content" className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1563777365199">
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper"><br/>
                                                            <h1 className="cust-title-h2"><strong>PHP Web
                                                                Development</strong></h1>
                                                            <h5 className="cust-sub-p">Result-driven PHP
                                                                websites &amp; applications for your system progress
                                                                needs</h5>
                                                            <p>With a trained PHP development team of expert
                                                                professionals, we have worked on more than 1000
                                                                projects &amp; have achieved concrete results for our
                                                                clients. From building microsites for start-ups,
                                                                fully-featured online presence for SMB’s or
                                                                multi-faceted enterprise applications for fortune 500
                                                                companies, we have hands-on experience in custom PHP web
                                                                development that easily meet the different business
                                                                goals of our clients.</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="vc_btn3-container  custom_button button_11 vc_btn3-inline">
                                                        <a onMouseLeave="this.style.borderColor='#113585'; this.style.backgroundColor='transparent'; this.style.color='#113585'"
                                                           onMouseEnter="this.style.borderColor='#113585'; this.style.backgroundColor='#113585'; this.style.color='#ffffff';"
                                                           style={{backgroundColor: '#113585', color: '#ffffff'}}
                                                           className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-outline-custom"
                                                           href="/portfolio" title>Our Portfolio</a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-5"><br/>
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center   img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={413} height={350}
                                                                     src={img1}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 413px) 100vw, 413px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="parallax-sec blox full-container  w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                background: `url(${bg}) no-repeat center`,
                backgroundSize: 'cover',
                minHeight: 'px',
                textAlign: 'center'
            }} data-stellar-background-ratio="0.5">
                <div className="max-overlay" style={{backgroundColor: ''}}/>
                <div data-stellar-ratio={1} className="wpb_row vc_row-fluid ">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space2"/>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h2 className="cust-1" style={{textAlign: 'center'}}><strong>PHP Development
                                                Services</strong></h2>
                                            <p className="cust-2" style={{textAlign: 'center', fontSize: '15px'}}>With
                                                7+ yrs of hands on experience in PHP, we offer best PHP development
                                                services of outstanding quality. From effective websites to interactive
                                                business applications; customized software to e-commerce solutions; open
                                                source implementation to rapid application framework development; we
                                                have a pool of specialized PHP development team who can produce
                                                applications of every nature, size and complexity. Our agile team of
                                                certified PHP designers work with LAMP stack (Linux, Apache, MySQL,PHP);
                                                Ajax, Web 2.0 &amp; 3.0 technologies to add interactivity to your web
                                                applications and makes them highly secure, reliable &amp; effective.</p>
                                        </div>
                                    </div>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <hr className="vertical-space2"/>
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h3 style={{textAlign: 'left', color: '#113585'}}><strong>Custom
                                                                PHP Development (Web/App)</strong></h3>
                                                            <h5 style={{
                                                                textAlign: 'left',
                                                                color: '#113585'
                                                            }}>Tailor-made PHP web developing services to pass reliable
                                                                websites, flexible web applications &amp; offer more
                                                                features &amp; functionalities to your customers</h5>
                                                            <p>&nbsp;</p>
                                                            <p style={{textAlign: 'left', fontSize: '14px'}}>We develop
                                                                customized websites &amp; web applications using LAMP
                                                                (Linux Apache My SQL PHP) stack to power top class
                                                                businesses, cater to different client
                                                                requirements &amp; meet specific targets. Delivering
                                                                high-performance PHP enhancement services comprising
                                                                fully interactive websites, tweaking open source
                                                                platforms for robust functionality and deploying
                                                                extremely scalable web applications; our PHP web
                                                                development team has successfully performed challenging
                                                                PHP projects of varied complexity for clients across the
                                                                globe using modern tools &amp; best coding
                                                                practices.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center  vc_custom_1563606402437  img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={453} height={448}
                                                                     src={img2}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 453px) 100vw, 453px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="vertical-space3"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x"/>
            </section>
            <section className="blox  w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                backgroundSize: 'cover',
                minHeight: '100px',
                backgroundColor: '#113685',
                textAlign: 'center'
            }}>
                <div className="max-overlay" style={{backgroundColor: ''}}/>
                <div className="wpb_row vc_row-fluid full-row">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space2"/>
                                    <div
                                        className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1563777911084 vc_row-has-fill">
                                        <div className="wpb_column vc_column_container vc_col-sm-8">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h2 className="cust-3" style={{
                                                                textAlign: 'right',
                                                                color: 'white',
                                                                marginBottom: '15px !important'
                                                            }}>Want To Hire A PHP Developer?</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-4">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="vc_btn3-container  custom_button1 button_11 vc_btn3-inline">
                                                        <a style={{backgroundColor: '#4975dd', color: '#ffffff'}}
                                                           className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-custom"
                                                           href="/service/dedicatedResource/hireDedicatedResource" title>Hire PHP
                                                            Developer</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="vertical-space1"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space2"/>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 style={{textAlign: 'center'}}><strong>Why projects executed by us
                                                deliver best results for our clients</strong></h3>
                                            <p style={{textAlign: 'justify'}}>Authority of every website depends on
                                                multiple factors including usability, search engine friendliness,
                                                flexibility, cross-compatibility and security etc. Being a
                                                full-service&nbsp;<a href="../digital-marketing/index.html">digital
                                                    marketing company</a>, we understand that your website needs much
                                                more than just development. It has to be found in search
                                                engines &amp; so it’s structure should be optimized for higher
                                                conversions. Here, we deliver you everything at one place keeping in
                                                mind a complete online presence that helps you succeed.</p>
                                        </div>
                                    </div>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-8">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <div className="wpcont5left">
                                                                <ul style={{listStyleType: 'none'}}>
                                                                    <li>
                                                                        <figure><span className="image_section_width"
                                                                                      style={{marginBottom: '30px'}}><img
                                                                            src={icon1}
                                                                            alt="statistics"/></span><span
                                                                            className="images _conatent">&nbsp;When it grows to ensuring security for the developed websites &amp; web applications, we have set highest standards. Our PHP developing company renders every project through rigorous security testing &amp; complete inspection before delivering it to the client. May it be the front end UI or the back end database, your site or app is protected from the malicious eyes of hackers.</span>
                                                                        </figure>
                                                                    </li>
                                                                    <li>
                                                                        <figure><span
                                                                            className="image_section_width"><img
                                                                            src={icon2}
                                                                            alt="statistics"/></span><span
                                                                            className="images _conatent"> Whatsoever may be your project specifications, simple or complex; our expert developers ensure you get the best possible solution tailored to your business goals &amp; flexible enough to meet future requirements as well.</span>
                                                                        </figure>
                                                                    </li>
                                                                    <li>
                                                                        <figure><span
                                                                            className="image_section_width"><img
                                                                            src={icon2}
                                                                            alt="statistics"/></span>&nbsp; <span
                                                                            className="images _conatent">&nbsp;Our PHP growing team ensure bug free development using latest technology, newest coding standards &amp; best practices.</span>
                                                                        </figure>
                                                                    </li>
                                                                    <li>
                                                                        <figure><span
                                                                            className="image_section_width"> <img
                                                                            src={icon3}
                                                                            alt="statistics"/>&nbsp;</span> <span
                                                                            className="images _conatent">We rely on agile development to make the growth process more measurable in terms of results &amp; flexible for future changes along &amp; that too within your budget.</span>
                                                                        </figure>
                                                                    </li>
                                                                    <li>
                                                                        <figure><span
                                                                            className="image_section_width"><img
                                                                            src={icon3}
                                                                            alt="statistics"/></span> &nbsp;<span
                                                                            className="images _conatent">We have a well-trained team of Project Managers who are adept at estimating right budget, prioritizing tasks, arranging the work flow &amp; delivering complex modules within the project teams</span>
                                                                        </figure>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-4">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_border_width_2 vc_sep_pos_align_center vc_separator-has-text">
                                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                                            style={{borderColor: '#113685'}}
                                                            className="vc_sep_line"/></span><h4>Open Source</h4><span
                                                        className="vc_sep_holder vc_sep_holder_r"><span
                                                        style={{borderColor: '#113685'}}
                                                        className="vc_sep_line"/></span>
                                                    </div>
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <ul style={{listStyleType: 'none', textAlign: 'center'}}>
                                                                <li className="col-md-3 col-xs-3" style={{marginRight:20}}><img
                                                                    src={icon4}
                                                                    alt="drupal icon"/><br/>
                                                                    Drupal
                                                                </li>
                                                                <li className="col-md-3 col-xs-3" style={{marginRight:20}}><img
                                                                    src={icon5}
                                                                    alt="wordpress icon" style={{marginRight:20}}/><br/>
                                                                    Wordpress
                                                                </li>
                                                                <li className="col-md-3 col-xs-3" style={{marginRight:20}}><img
                                                                    src={icon6}
                                                                    alt="magento icon"/><br/>
                                                                    Magento
                                                                </li>
                                                                <li className="col-md-3 col-xs-3" style={{marginRight:20}}><img
                                                                    src={icon7}
                                                                    alt="joomla icon"/><br/>
                                                                    Joomla
                                                                </li>
                                                                <li className="col-md-3 col-xs-3"  style={{marginRight:20}}><img
                                                                    src={icon8}
                                                                    alt="oscommerce icon"/><br/>
                                                                    Oscommerce
                                                                </li>
                                                                <li className="col-md-3 col-xs-3" style={{marginRight:10,marginLeft:10}}><img
                                                                    src={icon9}
                                                                    alt="zencart icon"/><br/>
                                                                    Zencart
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <hr className="vertical-space1"/>
                                                    <div
                                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_border_width_2 vc_sep_pos_align_center vc_separator-has-text">
                                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                                            style={{borderColor: '#113685'}}
                                                            className="vc_sep_line"/></span><h4>Frameworks</h4><span
                                                        className="vc_sep_holder vc_sep_holder_r"><span
                                                        style={{borderColor: '#113685'}}
                                                        className="vc_sep_line"/></span>
                                                    </div>
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <ul style={{listStyleType: 'none', textAlign: 'center'}}>
                                                                <li className="col-md-3 col-xs-3" style={{marginRight:20}}><img
                                                                    src={icon10}
                                                                    alt="zend icon"/><br/>
                                                                    Zend
                                                                </li>
                                                                <li className="col-md-3 col-xs-3" style={{marginRight:20}}><img
                                                                    src={icon11}
                                                                    alt="cake php icon"/><br/>
                                                                     PHP
                                                                </li>
                                                                <li className="col-md-3 col-xs-3" style={{marginRight:20}}><img
                                                                    src={icon12}
                                                                    alt="codeigniter icon"/><br/>
                                                                    Codeigniter
                                                                </li>
                                                                <li className="col-md-3 col-xs-3" style={{marginRight:20}}><img
                                                                    src={icon13}
                                                                    alt="symphony icon"/><br/>
                                                                    Symfony
                                                                </li>
                                                                <li className="col-md-3 col-xs-3" style={{marginRight:20}}><img
                                                                    src={icon14}
                                                                    alt="yii icon"/><br/>
                                                                    Yii
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="vertical-space2"/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="blox w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                backgroundSize: 'cover',
                minHeight: 'px',
                backgroundColor: '#fafafb'
            }}>
                <div className="max-overlay" style={{backgroundColor: ''}}/>
                <div className="wpb_row vc_row-fluid full-row">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space2"/>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h3 style={{lineHeight: '1.7'}}><strong>Why we recommend PHP
                                                                for most of the development projects</strong></h3>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow"><p/>
                                                        <ul className="col-left" style={{fontSize: '15px'}}>
                                                            <li>Multilingual support</li>
                                                            <li>Easy &amp; fast maintenance of your online presence
                                                                without any additional cost
                                                            </li>
                                                            <li>Superior Performance, Greater Reliability &amp; Platform
                                                                Independent Architecture
                                                            </li>
                                                            <li>Easily customised to work seamlessly with widely used
                                                                database such as My SQL &amp; extensive support for
                                                                other databases also like Oracle, Informix, and Sybase
                                                                etc.
                                                            </li>
                                                            <li>Fully harmonious with various operating systems
                                                                including Windows, Linux, Unix etc &amp; web servers
                                                                including-Apache &amp; IIS
                                                            </li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h3 style={{lineHeight: '1.7'}}><strong>How our PHP web
                                                                development services benefited our clients</strong></h3>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow"><p/>
                                                        <ul className="col-left arrow_content"
                                                            style={{fontSize: '15px'}}>
                                                            <li >Our&nbsp; expert
                                                                PHP programmers investigated their business
                                                                vertical &amp; core requirements accurately.
                                                            </li>
                                                            <li>We expanded web pages – both static &amp; dynamic that
                                                                strictly follow every aspect of W3C validation
                                                            </li>
                                                            <li>Our websites are coded with strong MVC architecture and
                                                                so they are immune to every kind of malicious
                                                                tasks &amp; security risks.
                                                            </li>
                                                            <li>Our PHP developers code the websites for high-end
                                                                Adaptability, scalability &amp; inherent database
                                                                support. No compatibility issues with existing modules
                                                                or web programs; all OS ranging from
                                                                Solaris &amp; Windows to Mac &amp; Linux and various
                                                                kinds of web servers as well.
                                                            </li>
                                                            <li>Our technical experts write very scalable source
                                                                code &amp; manage everything from&nbsp;
                                                                     Designing &nbsp;&amp;&nbsp;
                                                                 Development &nbsp;to
                                                                Deployment &amp; Testing on their own.
                                                            </li>
                                                            <li>We offer post transfer support to assist our clients if
                                                                something goes wrong after we handover everything.
                                                            </li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="vertical-space2"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x"><p/>
                </div>
            </section>
        </div>
        </div>
    )
};

export default PhPDevelopment;