import React from 'react';
import 'fa-icons';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import Header from "./header";
import About from "./about";
import Values from "./values";
import Client from "./client";
import Logo from "./logo";

const Home = () => {
    window.scroll(0,0);
    return (
            <div className='App'>

                <div className="boxed">

                    <Header/>

                    <About/>

                    <Values/>

                    <Client/>

                    <Logo/>

                </div>
            </div>
        )
};

export default Home;
