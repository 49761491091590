import React, {useState} from 'react';
import axios from "axios";
import {apiURL} from "../../config"
import obj from "../../Assets/wp-content/uploads/2017/07/Artboard 4.jpg"
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'

const HireDedicatedResources = () => {
    const [dynamic, setDynamic] = useState({});
    const [uploadFile, setUploadFile] = useState({});
    const [errors, setValidation] = useState({});

    const handleOnChange = async (e) => {
        const {name, value} = e.target;
        if (name === "resume") {
            setUploadFile(e.target.files[0]);
        } else {
            setDynamic({...dynamic, [name]: value})
        }
    };

    const resetForm = () => {
        setDynamic({});
        setValidation({});
        document.getElementById("FileName").value = "";
    }

    const onClickHandler = async payload => {
        let allErrors = {};
         const userData = {
            developer: dynamic.developer,
            experience: dynamic.experience,
            firstName: dynamic.firstName,
            lastName: dynamic.lastName,
            email: dynamic.email,
            mobile: dynamic.mobile,
            description: dynamic.description,
            resume: uploadFile && uploadFile.name
        };
        Object.keys(userData).forEach(key => {
            const error = validation(key, userData[key]);
            if (error && error.length) {
                allErrors[key] = error
            }
        })
        if (Object.keys(allErrors).length) {
            setValidation(allErrors)
        } else {
            let result = {};
            try {
                const formData = new FormData();
                formData.append('payload', JSON.stringify(dynamic))
                formData.append('file', uploadFile);
                const res = await axios.post(`${apiURL}/dedicatedHireResources`, formData);
                if(res && res.data && res.data.payload) {
                    resetForm();
                }else {
                    console.log(res)
                }
            } catch (err) {
                console.log("error in getting entities : ", err)
            }
            return result
        }
    };

    const validation = (name, value) => {
        const emailRegx = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/ig;
        switch (name) {
            case 'developer':
                if (!value) {
                    return "'Please select valid field!'"
                } else {
                    return '';
                }
            case 'experience':
                if (!value) {
                    return "'Please select your Experience!'"
                } else {
                    return '';
                }
            case 'firstName':
                if (!value) {
                    return "'Please input your firstName!'"
                } else {
                    return '';
                }
            case 'lastName':
                if (!value) {
                    return "'Please input your lastName!'"
                } else {
                    return '';
                }
            case 'email':
                if (!emailRegx.test(value)) {
                    return "'Please input your email!'"
                } else {
                    return '';
                }
            case 'mobile':
                if (!value) {
                    return 'Please input your mobile number!'
                } else {
                    return ''
                }
            case 'description':
                if (!value) {
                    return 'Please input your description!'
                } else {
                    return ''
                }
            case 'resume':
                if (!(value && (value.match(/doc.*/) || value.match(/docx.*/) || value.match(/pdf.*/) || value.match(/jpg.*/) || value.match(/jpeg.*/) ))) {
                    return 'Please upload your valid file!'
                } else {
                    return ''
                }
            default: {
                return null;
            }
        }
    };

    return (
        <div className="bvm">
            <div id="wrap" className="">

                <section id="main-content" className="container">
                    <div className="row-wrapper-x">
                        <section className="wpb_row   w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper"><br/>
                                                <h1 className="cust-title-h2" style={{textAlign: 'center', color: '#113685'}}>
                                                    <strong>Hire Dedicated Resource</strong>
                                                </h1>
                                            </div>
                                        </div>
                                        <div
                                            className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1501252768616 vc_row-has-fill">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div
                                                            className="wpb_single_image wpb_content_element vc_align_center  vc_custom_1567237229101  img1">
                                                            <figure className="wpb_wrapper vc_figure">
                                                                <div
                                                                    className="vc_single_image-wrapper   vc_box_border_grey">
                                                                    <img width={1171} height={209}
                                                                         src={obj}
                                                                         className="vc_single_image-img attachment-full"
                                                                         alt=""
                                                                         sizes="(max-width: 1171px) 100vw, 1171px"/>
                                                                </div>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="wpb_row  hiring_form w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
                                <div className="vc_column-inner vc_custom_1568461819655">
                                    <div className="wpb_wrapper">
                                        <div role="form" className="wpcf7" id="wpcf7-f11846-p10637-o1" lang="en-US"
                                             dir="ltr">
                                            <div className="screen-reader-response"/>
                                            <form
                                                action="https://bvminfotech.com/hire-dedicated-resource/#wpcf7-f11846-p10637-o1"
                                                method="post" className="wpcf7-form" noValidate="novalidate">
                                                <div style={{display: 'none'}}>
                                                    <input type="hidden" name="_wpcf7" defaultValue={11846}/>
                                                    <input type="hidden" name="_wpcf7_version" defaultValue="4.8"/>
                                                    <input type="hidden" name="_wpcf7_locale" defaultValue="en_US"/>
                                                    <input type="hidden" name="_wpcf7_unit_tag"
                                                           defaultValue="wpcf7-f11846-p10637-o1"/>
                                                    <input type="hidden" name="_wpcf7_container_post"
                                                           defaultValue={10637}/>
                                                    <input type="hidden" name="_wpcf7_nonce" defaultValue="01f59b1193"/>
                                                </div>
                                                <div id="talk-business">
                                                    <div className="row">
                                                        <div className="col-sm-12 main_tilte_section">
                                                            Apply Now
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr width="100%" style={{margin: '20px 0 !important'}}/>
                                                <div className="row">
                                                    <div className="col-sm-5">
                                                        <p className="talk-busiess-title">Dedicated Developer</p>
                                                        <p>
                                                            <span className="wpcf7-form-control-wrap phpExperience">
                                                            <select
                                                            name="developer"
                                                            value={dynamic.developer || ''}
                                                            onChange={handleOnChange}
                                                            className="wpcf7-form-control wpcf7-select"
                                                            aria-invalid="false">
                                                            <option value="">Select Field</option>
                                                            <option value="php">PHP developer</option>
                                                            <option value="webDesign">Web Design</option>
                                                            <option value="wordPress">WordPress developer</option>
                                                            <option value="eCommerce">ECommerce developer</option>
                                                            <option value="iphone">iPhone developer</option>
                                                            <option value="angularJs">Angular Js developer</option>
                                                            <option value="reactJs">React Js developer</option>
                                                            <option value="viewJs">Vue js developer</option>
                                                            <option value="android">Android developer</option>
                                                            <option value=".net">.net developer</option>
                                                            <option value="reactNative">React Native developer</option>
                                                            <option value="fullStack">FullStack developer</option>
                                                            </select>
                                                        </span>
                                                            <span style={{color: 'red'}}>{errors.developer}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <hr width="100%" style={{margin: '20px 0 !important'}}/>
                                        <div className="row">
                                            <div className="col-sm-5">
                                                <p className="talk-busiess-title">Experiance Time</p>
                                                <p>
                                                    <span
                                                    className="wpcf7-form-control-wrap marketing-Experience" >
                                                        <select
                                                            style={{width:"100%"}}
                                                    name="experience"
                                                    value={dynamic.experience || ''}
                                                    onChange={handleOnChange}
                                                    className="wpcf7-form-control wpcf7-select"
                                                    aria-invalid="false">
                                                            <option value="">Select Field</option>
                                                            <option value="freshers">Freshers</option>
                                                            <option value="0-1 Year">0-1 Year</option>
                                                            <option value="1-2 Year">1-2 Year</option>
                                                            <option value="2-3 Year">2-3 Year</option>
                                                            <option value="3-4 Year">3-4 Year</option>
                                                            <option value="4+ Year">4+ Years</option>
                                                        </select>
                                                    </span>
                                                    <span style={{color: 'red'}}>{errors.experience}</span>
                                                </p>
                                            </div>

                                        </div>

                                    </div>
                                    <div id="talk-business">
                                        <div className="row">
                                            <div className="col-sm-12" style={{padding: '0px !important'}}>
                                                <h2 style={{marginBottom: '5px !important'}}
                                                    className="main_tilte_section">Your Personal Details</h2>
                                                <p/></div>
                                        </div>
                                        <div className="row" style={{marginTop: '20px !important'}}>
                                            <div className="col-sm-6"><p>
                                            <span className="wpcf7-form-control-wrap Name">
                                                <input type="text" name="firstName"
                                                       onChange={handleOnChange} value={dynamic.firstName || ''}
                                                       size={40} placeholder="First Name"/>
                                            </span>
                                                <span style={{color: 'red'}}>{errors.firstName}</span>
                                            </p>
                                            </div>
                                            <div className="col-sm-6">
                                                <p>
                                            <span className="wpcf7-form-control-wrap Name">
                                                <input type="text" name="lastName"
                                                       onChange={handleOnChange}
                                                       value={dynamic.lastName || ''}
                                                       size={40}
                                                       placeholder="Last Name"/>
                                            </span>
                                                <span style={{color: 'red'}}>{errors.lastName}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <p><span className="wpcf7-form-control-wrap Email">
                                                    <input type="email" name="email"
                                                           onChange={handleOnChange}
                                                           value={dynamic.email || ''}
                                                           size={40}
                                                           className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email em"
                                                           placeholder="Your Email"/>
                                                </span>
                                                    <span style={{color: 'red'}}>{errors.email}</span>
                                                </p></div>
                                            <div className="col-sm-6">
                                                <p>
                                            <span className="wpcf7-form-control-wrap Mobile">
                                                <input type="tel"
                                                       name="mobile"
                                                       onChange={handleOnChange} size={40}
                                                       className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel mo"
                                                       aria-required="true"
                                                       value={dynamic.mobile || ''}
                                                       placeholder="Your Mobile No..."
                                                       aria-invalid="false"/></span>
                                                <span style={{color: 'red'}}>{errors.mobile}</span>
                                                </p>
                                                <br/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <p>
                                                    <span className="wpcf7-form-control-wrap Message"><textarea
                                                    name="description" cols={40} rows={10}
                                                    className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                                    onChange={handleOnChange}
                                                    value={dynamic.description || ''}
                                                    aria-required="true" aria-invalid="false" placeholder="Description"/>
                                                    </span>
                                                    <span style={{color: 'red'}}>{errors.description}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <p>
                                            <span className="wpcf7-form-control-wrap Name"
                                                  style={{display: 'flex'}}><span style={{marginTop: '20px'}}>Upload Resume:</span><input
                                                id="filename"
                                                style={{marginLeft: '10px'}} type="file" onChange={handleOnChange}
                                                name="resume"/>
                                            </span>
                                                <span style={{color: 'red'}}>{errors.resume}</span>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-2 col-sm-offset-5">
                                                <p><input type="submit" defaultValue="Submit" onClick={onClickHandler}
                                                          className="wpcf7-form-control wpcf7-submit cust-btn"/>
                                                </p></div>
                                        </div>
                                    </div>
                                    <div className="wpcf7-response-output wpcf7-display-none"/>
                                </div>
                                <hr className="vertical-space1"/>
                            </div>
                        </section>
                    </div>
                </section>
            </div>
        </div>
    )
};

export default HireDedicatedResources;