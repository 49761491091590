import React, {useEffect} from 'react';
import layer1 from "../../Assets/wp-content/uploads/2017/07/Layer-45.jpg"
import ig1 from "../../Assets/wp-content/uploads/2017/07/Forma-1-1.png"
import ig2 from "../../Assets/wp-content/uploads/2017/07/Forma-11-1.png"
import ig3 from  "../../Assets/wp-content/uploads/2017/07/L00011.png"
import ig4 from  "../../Assets/wp-content/uploads/2017/07/Forma-13.png"
import ig5 from "../../Assets/wp-content/uploads/2017/07/L00011.png"
import ig6 from "../../Assets/wp-content/uploads/2017/07/Forma-12-1.png"
import i1 from "../../Assets/wp-content/uploads/2019/07/Forma-15.png"
import i2 from "../../Assets/wp-content/uploads/2019/07/Forma-14.png"
import i3 from "../../Assets/wp-content/uploads/2019/07/Forma-16.png"
import i4 from "../../Assets/wp-content/uploads/2019/07/Forma-17.png"
import i5 from "../../Assets/wp-content/uploads/2019/07/Forma-18.png"
import i6 from "../../Assets/wp-content/uploads/2019/07/Forma-19.png"
import i7 from "../../Assets/wp-content/uploads/2019/07/Forma-110.png"
import ic1 from "../../Assets/wp-content/uploads/2019/07/Forma-1-copy.jpg"
import ic2 from "../../Assets/wp-content/uploads/2019/07/Forma-111.jpg"
import '../../web.scss'
import '../../App.css'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'

const WordPressDevelopment = () => {
    window.scroll(0,0)
    const settings = {
        dots: true,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay:true,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    useEffect(() => {
        const ele = document.getElementById("wrap");
        if(ele) {
            const script = document.createElement("script");
            script.src = "/Assets/wp-content/themes/easyweb/js/webnus-custom.js";
            script.async = true;
            document.body.appendChild(script);
        }
    }, []);

    return (
        <div className="bvm wordpress">
        <div className="bvm">
            <div id="wrap" className="colorskin-custom ">
                <section id="main-content" className="container">
                    <div className="row-wrapper-x">
                        <section className="wpb_row   w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1563787520191">
                                            <div className="wpb_column vc_column_container vc_col-sm-7">
                                                <div className="vc_column-inner vc_custom_1563779773613">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper"><br/>
                                                                <h2 style={{color: '#113685'}}><strong>WordPress Development
                                                                    Company</strong></h2>
                                                                <p style={{fontSize: '17px'}}>Whether you’re a small
                                                                    business, start up organization or an INC 500 company,
                                                                    we implement result-oriented WordPress solutions for
                                                                    everyone.</p>
                                                                <ul>
                                                                    <li>Responsive themes to target mobile traffic and boost
                                                                        conversions
                                                                    </li>
                                                                    <li>Social media integration to increase user
                                                                        engagement
                                                                    </li>
                                                                    <li>Custom WP solutions to give you full control</li>
                                                                    <li>SEO friendly architecture to improve organic
                                                                        rankings
                                                                    </li>
                                                                    <li>High-end Security implementation</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="vc_btn3-container  custom_button vc_btn3-inline">
                                                            <a onMouseLeave="this.style.borderColor='#113685'; this.style.backgroundColor='transparent'; this.style.color='#113685'"
                                                               onMouseEnter="this.style.borderColor='#113685'; this.style.backgroundColor='#113685'; this.style.color='#ffffff';"
                                                               style={{backgroundColor: '#113685', color: '#113685'}}
                                                               className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-outline-custom vc_btn3-icon-right"
                                                               href="/portFolio" title>Our Portfolio <i
                                                                className="vc_btn3-icon fa fa-angle-double-right"/></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-5">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div
                                                            className="wpb_single_image wpb_content_element vc_align_center   img-hidden">
                                                            <figure className="wpb_wrapper vc_figure">
                                                                <div
                                                                    className="vc_single_image-wrapper   vc_box_border_grey">
                                                                    <img width={478} height={448}
                                                                         src={layer1}
                                                                         className="vc_single_image-img attachment-full"
                                                                         alt=""
                                                                         sizes="(max-width: 478px) 100vw, 478px"/></div>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>

                <section className="container">
                    <div className="row-wrapper-x"/>
                </section>
                <section className="blox   full-container   w-animate " style={{
                    paddingTop: 'px',
                    paddingBottom: 'px',
                    backgroundSize: 'cover',
                    minHeight: 'px',
                    backgroundColor: '#113685'
                }}>
                    <div className="max-overlay" style={{backgroundColor: ''}}/>
                    <div className="wpb_row vc_row-fluid full-row">
                        <div className="container">
                            <div className="wpb_column vc_column_container vc_col-sm-8" style={{marginLeft:-35}}>
                                <div className="vc_column-inner vc_custom_1563789005044">
                                    <div className="wpb_wrapper">
                                        <hr className="vertical-space1"/>
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                                <h3 className="cust-4" style={{color: 'white', textAlign: 'right'}}>For
                                                    Dedicated WordPress Development Services</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container vc_col-sm-4">
                                <div className="vc_column-inner vc_custom_1563789029524">
                                    <div className="wpb_wrapper">
                                        <hr className="vertical-space1"/>
                                        <div className="vc_btn3-container  custom_button1 button_11 vc_btn3-center">
                                            <a style={{backgroundColor: '#4975dd', color: '#ffffff'}}
                                               className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-custom"
                                               href="/service/dedicatedResource/hireDedicatedResource" title>Hire WordPress
                                                Developer</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container">
                    <div className="row-wrapper-x">
                        <section className="wpb_row   w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <hr className="vertical-space3"/>
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                                <h2 className="cust-5" style={{textAlign: 'center', color: '#24242a'}}>
                                                    <strong>Why consider customization of your WordPress site</strong></h2>
                                            </div>
                                        </div>
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <div className="Wpsite-box">
                                                                    <div className="col-md-2 col-xs-2"><img
                                                                        src={ig1}
                                                                        alt="superior quality design"/></div>
                                                                    <div className="col-md-10 col-xs-10">
                                                                        <h4 className="cust-6"><strong>Superior Quality
                                                                            Design</strong></h4>
                                                                        <p>Similar studying themes never sell, rather spoil
                                                                            the brand image. To hook visitants &amp; boost
                                                                            revenue, you need an intuitively stylish mobile
                                                                            friendly theme with beautiful UI (user
                                                                            interface).</p>
                                                                    </div>
                                                                </div>
                                                                <p>&nbsp;</p>
                                                                <div className="Wpsite-box">
                                                                    <div className="col-md-2 col-xs-2">
                                                                        <p><img
                                                                            src={ig2}
                                                                            alt="technical support"/></p>
                                                                    </div>
                                                                    <div className="col-md-10 col-xs-10">
                                                                        <h4 className="cust-6"><strong>24 x 7
                                                                            Support</strong></h4>
                                                                        <p>Using free themes, plugins &amp; features won’t
                                                                            support you in trouble. You need round the clock
                                                                            technical support in cases of sudden
                                                                            breakdown.</p>
                                                                    </div>
                                                                </div>
                                                                <p>&nbsp;</p>
                                                                <div className="Wpsite-box">
                                                                    <div className="col-md-2 col-xs-2"><img
                                                                        src={ig3}
                                                                        alt="version updates"/></div>
                                                                    <div className="col-md-10 col-xs-10">
                                                                        <h4 className="cust-6"><strong>Version
                                                                            Updates</strong></h4>
                                                                        <p>Free stuff never gets updated with WordPress
                                                                            updates. Availing our WordPress Web Developing
                                                                            services will upgrade your site with latest WP
                                                                            version updates, plugin updates etc.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <div className="Wpsite-box">
                                                                    <div className="col-md-2 col-xs-2"><img
                                                                        src={ig4}
                                                                        alt="unique functionality"/></div>
                                                                    <div className="col-md-10 col-xs-10">
                                                                        <h4 className="cust-6"><strong>Unique
                                                                            Functionality</strong></h4>
                                                                        <p>Pre-built WordPress plugins deliver rise to
                                                                            compatibility issues &amp; their wrong
                                                                            integration can even harm your site. For better
                                                                            site functionality and display, avail custom
                                                                            WordPress plugin development &amp; integration
                                                                            services.</p>
                                                                    </div>
                                                                </div>
                                                                <p>&nbsp;</p>
                                                                <div className="Wpsite-box">
                                                                    <div className="col-md-2 col-xs-2"><img
                                                                        src={ig5}
                                                                        alt="updates"/></div>
                                                                    <div className="col-md-10 col-xs-10">
                                                                        <h4 className="cust-6"><strong>High-end
                                                                            Security</strong></h4>
                                                                        <p>Unconstrained themes &amp; plugins are packaged
                                                                            with unwanted code. Our WordPress web
                                                                            improvement team thoroughly scan all the code,
                                                                            themes &amp; plugins to ensure your website
                                                                            safety.</p>
                                                                    </div>
                                                                </div>
                                                                <p>&nbsp;</p>
                                                                <div className="Wpsite-box">
                                                                    <div className="col-md-2 col-xs-2"><img
                                                                        src={ig6}
                                                                        alt="mobile optimised UI"/></div>
                                                                    <div className="col-md-10 col-xs-10">
                                                                        <h4 className="cust-6"><strong>Mobile Optimised
                                                                            UI</strong></h4>
                                                                        <p>90% of the website traffic is mobile
                                                                            now &amp; pre-built themes don’t deliver good
                                                                            user experience on all devices. You need a
                                                                            custom WordPress understanding theme to boost
                                                                            engagement.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="vertical-space3"/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="wpb_row   w-animate full-row">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                                <div
                                    className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                <span className="vc_sep_holder vc_sep_holder_l"><span
                                    className="vc_sep_line"/></span><span
                                    className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container">
                    <div className="row-wrapper-x">
                        <section className="wpb_row   w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <hr className="vertical-space2"/>
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                                <h2 className="cust-7" style={{textAlign: 'center'}}><strong>Why Hire
                                                    WordPress Developers &amp; Designers from BVM-Infotech</strong>
                                                </h2>
                                            </div>
                                        </div>
                                        <hr className="vertical-space1"/>
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-8">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <ul className="font_size_section"
                                                                    style={{listStyleType: 'none', fontSize: '20px'}}>
                                                                    <li>
                                                                        <figure><img
                                                                            src={i1}
                                                                            alt="tis-india"/><p/>
                                                                            <p className="contents">With each service you
                                                                                get trust and expertise of a brand which has
                                                                                developed 1000s of WordPress website.</p>
                                                                        </figure>
                                                                    </li>
                                                                    <li/>
                                                                    <li>
                                                                        <figure><img
                                                                            src={i2}
                                                                            alt="tis-india"/><p/>
                                                                            <p className="contents">100% clean W3C Compliant
                                                                                Code</p>
                                                                        </figure>
                                                                    </li>
                                                                    <li/>
                                                                    <li>
                                                                        <figure><img
                                                                            src={i3}
                                                                            alt="tis-india"/><p/>
                                                                            <p className="contents">Track process via Easy
                                                                                Communication – Skype, Gmail, Chat, and
                                                                                Phone.</p>
                                                                        </figure>
                                                                    </li>
                                                                    <li/>
                                                                    <li>
                                                                        <figure><img
                                                                            src={i4}
                                                                            alt="tis-india"/><p/>
                                                                            <p className="contents">Flexible Hiring Options
                                                                                Hourly, Part-time &amp; Full-time</p>
                                                                        </figure>
                                                                    </li>
                                                                    <li/>
                                                                    <li>
                                                                        <figure><img
                                                                            src={i5}
                                                                            alt="tis-india"/><p/>
                                                                            <p className="contents">24 X 7 Technical
                                                                                Support</p>
                                                                        </figure>
                                                                    </li>
                                                                    <li/>
                                                                    <li>
                                                                        <figure><img
                                                                            src={i6}
                                                                            alt="tis-india"/><p/>
                                                                            <p className="contents">100% Guaranteed
                                                                                Satisfaction from expert WordPress web
                                                                                development team</p>
                                                                        </figure>
                                                                    </li>
                                                                    <li/>
                                                                    <li>
                                                                        <figure><img
                                                                            src={i7}
                                                                            alt="tis-india"/><p/>
                                                                            <p className="contents">Unconfined replacement
                                                                                (in first 3 days, if you are not
                                                                                satisfied)</p>
                                                                        </figure>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-4">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div
                                                            className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_sep_color_grey space1">
                                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                                            className="vc_sep_line"/></span><span
                                                            className="vc_sep_holder vc_sep_holder_r"><span
                                                            className="vc_sep_line"/></span>
                                                        </div>
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <p><span className="col-md-4 col-xs-3 digit" style={{
                                                                    color: '#113685',
                                                                    fontSize: '40px',
                                                                    textAlign: 'right'
                                                                }}>35+</span></p>
                                                                <p className="col-md-8 col-xs-9 digit-desc"
                                                                   style={{fontSize: '18px'}}>expert
                                                                    designers &amp; developers</p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey  space1">
                                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                                            className="vc_sep_line"/></span><span
                                                            className="vc_sep_holder vc_sep_holder_r"><span
                                                            className="vc_sep_line"/></span>
                                                        </div>
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <p><span className="col-md-4 col-xs-3 digit" style={{
                                                                    color: '#113685',
                                                                    textAlign: 'right',
                                                                    fontSize: '40px'
                                                                }}>90+</span></p>
                                                                <p className="col-md-8 col-xs-9 digit-desc"
                                                                   style={{fontSize: '18px'}}>websites delivered
                                                                    successfully</p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey  space1">
                                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                                            className="vc_sep_line"/></span><span
                                                            className="vc_sep_holder vc_sep_holder_r"><span
                                                            className="vc_sep_line"/></span>
                                                        </div>
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <p><span className="col-md-4 col-xs-3 digit" style={{
                                                                    color: '#113685',
                                                                    textAlign: 'right',
                                                                    fontSize: '40px'
                                                                }}>99+</span></p>
                                                                <p className="col-md-8 col-xs-9 digit-desc"
                                                                   style={{fontSize: '18px'}}>Small tasks, quick
                                                                    fixes &amp; upgrades done so far</p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey  space1">
                                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                                            className="vc_sep_line"/></span><span
                                                            className="vc_sep_holder vc_sep_holder_r"><span
                                                            className="vc_sep_line"/></span>
                                                        </div>
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <p><span className="col-md-4 col-xs-3 digit" style={{
                                                                    color: '#113685',
                                                                    fontSize: '40px',
                                                                    textAlign: 'right'
                                                                }}>55+</span></p>
                                                                <p className="col-md-8 col-xs-9 digit-desc"
                                                                   style={{fontSize: '18px'}}>Custom Themes,
                                                                    Plugins &amp; Apps created so far</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="vertical-space3"/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="blox      " style={{
                    paddingTop: 'px',
                    paddingBottom: 'px',
                    backgroundSize: 'cover',
                    minHeight: 'px',
                    backgroundColor: '#113685'
                }}>
                    <div className="max-overlay" style={{backgroundColor: ''}}/>
                    <div className="wpb_row vc_row-fluid full-row">
                        <div className="container">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <hr className="vertical-space2"/>
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                                <h2 className="cust-8" style={{textAlign: 'center', color: 'white'}}>
                                                    <strong>WordPress&nbsp;Design &amp; Development Samples</strong></h2>
                                                <p style={{textAlign: 'center', color: 'white', fontSize: '16px'}}>Our
                                                    clients love our work. Browse through some of our latest sample of
                                                    WordPress website design &amp; development projects.</p>
                                            </div>
                                        </div>
                                        <hr className="vertical-space1"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container">
                    <div className="row-wrapper-x"/>
                </section>

                <section className="container cardfix">
                    <div className="row-wrapper-x">
                        <section className="wpb_row   w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid content_images">
                                            <div className="box_shadow_content wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper" >
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper" >
                                                                <h4 className="cust-9" style={{textAlign: 'center'}}>
                                                                    <strong>Why most WordPress websites fail ?</strong></h4>
                                                                <ul style={{listStyleType: 'none'}}>
                                                                    <li style={{listStyleType: 'none'}}>
                                                                        <ul style={{listStyleType: 'none'}}>
                                                                            <li>
                                                                                <div className="col-md-2 col-xs-2">
                                                                                    <figure><img className="aligncenter"
                                                                                                 src={ic1} style={{width: "25px"}}
                                                                                                 alt="Hire WordPress developers"/>
                                                                                    </figure>
                                                                                </div>
                                                                                <div className="col-md-10 col-xs-10">
                                                                                    <h5 className="cust-10">Choice of Wrong
                                                                                        Platform and Wrong Vendor</h5>
                                                                                    <p className="line-height">Selecting the
                                                                                        wrong open-source framework and not
                                                                                        availing the services from a right
                                                                                        vendor can risk your business. For a
                                                                                        strong online presence, you need a
                                                                                        robust platform &amp; customization
                                                                                        services of a WordPress-focused
                                                                                        development agency.</p>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                <p>&nbsp;</p>
                                                                <ul style={{listStyleType: 'none'}}>
                                                                    <li style={{listStyleType: 'none'}}>
                                                                        <ul style={{listStyleType: 'none'}}>
                                                                            <li>
                                                                                <div className="col-md-2 col-xs-2">
                                                                                    <figure><img className="aligncenter"
                                                                                                 src={ic1} style={{width: "25px"}}
                                                                                                 alt="W3c compliant code"/>
                                                                                    </figure>
                                                                                </div>
                                                                                <div className="col-md-10 col-xs-10">
                                                                                    <h5 className="cust-10"><strong>Ignoring
                                                                                        WordPress Mobile Optimization and
                                                                                        Theme-customisation</strong></h5>
                                                                                    <p className="line-height">90% of people
                                                                                        are accessing the web via mobile
                                                                                        instead of PC and this is why it is
                                                                                        extremely important to avail of a
                                                                                        fully customized responsive
                                                                                        WordPress website from expert WP
                                                                                        coders.</p>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                <p>&nbsp;</p>
                                                                <ul style={{listStyleType: 'none'}}>
                                                                    <li style={{listStyleType: 'none'}}>
                                                                        <ul style={{listStyleType: 'none'}}>
                                                                            <li>
                                                                                <div className="col-md-2 col-xs-2">
                                                                                    <figure><img className="aligncenter"
                                                                                                 src={ic1} style={{width: "25px"}}
                                                                                                 alt="W3c compliant code"/>
                                                                                    </figure>
                                                                                </div>
                                                                                <div className="col-md-10 col-xs-10">
                                                                                    <h5 className="cust-10"><strong>Slow
                                                                                        Load Time</strong></h5>
                                                                                    <p className="line-height">You have just
                                                                                        6 seconds to either gain attention
                                                                                        or lose it to your contestants. For
                                                                                        better click-through rates and
                                                                                        conversions, your site needs a
                                                                                        performance boost.</p>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box_shadow_content wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <h4 className="cust-9" style={{textAlign: 'center'}}>
                                                                    <strong>How we make them succeed with
                                                                        WordPress!!</strong></h4>
                                                                <ul style={{listStyleType: 'none'}}>
                                                                    <li style={{listStyleType: 'none'}}>
                                                                        <ul style={{listStyleType: 'none'}}>
                                                                            <li>
                                                                                <div className="col-md-2 col-xs-2">
                                                                                    <figure><img className="aligncenter"
                                                                                                 src={ic2} style={{width: "25px"}}
                                                                                                 alt="comprehensive analysis"/>
                                                                                    </figure>
                                                                                </div>
                                                                                <div className="col-md-10 col-xs-10">
                                                                                    <h5 className="cust-10"><strong>Comprehensive
                                                                                        Analysis of project
                                                                                        requirements</strong></h5>
                                                                                    <p className="line-height">The right
                                                                                        examination reaps the right
                                                                                        benefits. When we discuss your
                                                                                        requirements, we make sure we advise
                                                                                        the right solution that can meet
                                                                                        your business targets as well as
                                                                                        revenue goals.</p>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                <p>&nbsp;</p>
                                                                <ul style={{listStyleType: 'none'}}>
                                                                    <li style={{listStyleType: 'none'}}>
                                                                        <ul style={{listStyleType: 'none'}}>
                                                                            <li>
                                                                                <div className="col-md-2 col-xs-2">
                                                                                    <figure><img className="aligncenter"
                                                                                                 src={ic2} style={{width: "25px"}}
                                                                                                 alt="responsive design"/>
                                                                                    </figure>
                                                                                </div>
                                                                                <div className="col-md-10 col-xs-10">
                                                                                    <h5 className="cust-10"><strong>Fully
                                                                                        Customized Responsive
                                                                                        Website</strong></h5>
                                                                                    <p className="line-height">We give you a
                                                                                        fully useful WP website with a
                                                                                        beautiful custom theme, responsive
                                                                                        UI, super functional plugins, W3C
                                                                                        compliant clean code &amp; complete
                                                                                        search engine optimization with all
                                                                                        social media courses integrated to
                                                                                        let you start selling your products
                                                                                        and services right from day one.</p>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                <p>&nbsp;</p>
                                                                <ul style={{listStyleType: 'none'}}>
                                                                    <li style={{listStyleType: 'none'}}>
                                                                        <ul style={{listStyleType: 'none'}}>
                                                                            <li>
                                                                                <div className="col-md-2 col-xs-2">
                                                                                    <figure><img className="aligncenter"
                                                                                                 src={ic2} style={{width: "25px"}}
                                                                                                 alt="responsive design"/>
                                                                                    </figure>
                                                                                </div>
                                                                                <div className="col-md-10 col-xs-10">
                                                                                    <h5 className="cust-10"><strong>Enhanced
                                                                                        Performance Optimization</strong>
                                                                                    </h5>
                                                                                    <p className="line-height">We target ROE
                                                                                        Return on Knowledge by delivering
                                                                                        amazingly fast user experiences. Our
                                                                                        customized websites store within 6
                                                                                        seconds to boost your
                                                                                        conversions</p>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="vertical-space3"/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </div>
        </div>
        </div>
    )
};

export default WordPressDevelopment;