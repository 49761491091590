import axios from "axios"
import {API_URL} from "./const"

export const fetchRecentPosts = async (body) => {
    try {
        const res = await axios.get(`${API_URL}blog-post/?page=${body.page}&pageSize=${body.pageSize}`)
        if (res && res.data && res.data.length) {
            return res.data[0]
        }
    } catch (err) {
        console.log("error in getEntityDetails : ", err)
    }
}