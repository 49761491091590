import React from 'react';
import pic1 from "../../Assets/wp-content/uploads/2019/10/angular.png"
import pic2 from "../../Assets/wp-content/uploads/2019/10/angularjs_img1.png"
import pic3 from "../../Assets/wp-content/uploads/2019/10/reactjs_img2.png"
import pic4 from "../../Assets/wp-content/uploads/2019/10/angular-icon.png"
import pic5 from "../../Assets/wp-content/uploads/2019/10/custom-design.png"
import pic6 from "../../Assets/wp-content/uploads/2019/10/real-time-app-development.png"
import pic7 from "../../Assets/wp-content/uploads/2019/10/ecommerce-apps.png"
import pic8 from "../../Assets/wp-content/uploads/2019/10/secure.png"
import pic9 from "../../Assets/wp-content/uploads/2019/10/maintenance-1.png"
import '../../App.css'
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'

const AngularJSDevelopment = () => {
    window.scroll(0,0)
    return (
        <>
            <div className="bvm">
            <div>
                <section id="main-content" className="container">
                    <div className="row-wrapper-x">
                        <section className="wpb_row   w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-8">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element  vc_custom_1571051619096">
                                            <div className="wpb_wrapper"><br/>
                                                <h2 style={{color: '#113685'}}><strong>AngularJS Development</strong>
                                                </h2>
                                            </div>
                                        </div>
                                        <div
                                            className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_20 vc_sep_border_width_2 vc_sep_pos_align_left vc_separator_no_text">
                                            <span className="vc_sep_holder vc_sep_holder_l"><span
                                                style={{borderColor: '#113685'}} className="vc_sep_line"/></span><span
                                            className="vc_sep_holder vc_sep_holder_r"><span
                                            style={{borderColor: '#113685'}} className="vc_sep_line"/></span>
                                        </div>
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                                <div className="intricate">
                                                    <h2>Developing such types of best-in-class apps for another mobile
                                                        platforms saves effort and cost considerably.</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container vc_col-sm-4">
                                <div className="vc_column-inner vc_custom_1570682517718">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_single_image wpb_content_element vc_align_left">
                                            <figure className="wpb_wrapper vc_figure">
                                                <div className="vc_single_image-wrapper   vc_box_border_grey"><img
                                                    width={500} height={480} src={pic1}
                                                    className="vc_single_image-img attachment-full" alt=""
                                                    sizes="(max-width: 500px) 100vw, 500px"/></div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="blox      w-animate " style={{
                    paddingTop: 'px',
                    paddingBottom: 'px',
                    backgroundSize: 'cover',
                    minHeight: 'px',
                    backgroundColor: '#f9f9f9'
                }}>
                    <div className="max-overlay" style={{backgroundColor: ''}}/>
                    <div className="wpb_row vc_row-fluid full-row">
                        <div className="container">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner vc_custom_1570682775068">
                                    <div className="wpb_wrapper">
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <p>AngularJS is the most important and pre-eminent
                                                                    Javascript framework concocted and maintained by
                                                                    Google. It encompasses HTML to incorporate highly
                                                                    robust and dynamic attributes in an intrinsic and
                                                                    resilient way. It empowers us to craft scalable and
                                                                    simplified single-page applications at a blazing
                                                                    fast speed and efficiency.</p>
                                                                <p>AngularJS is particularly resilient, flexible and
                                                                    comprises of a massive archive of the pre-configured
                                                                    building blocks and components to render content and
                                                                    customized UI components quickly over a webpage.
                                                                    That’s why it is presently used by some of the most
                                                                    popular websites including Netflix, Vevo, Paypal, Up
                                                                    works, Lego, and YouTube for PS3, to name a few.</p>
                                                                <p>Our core capability of angular web development offers
                                                                    unswerving AngularJS development services to develop
                                                                    coherent AngularJS web apps for businesses of all
                                                                    sizes and shapes.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div
                                                            className="wpb_single_image wpb_content_element vc_align_left">
                                                            <figure className="wpb_wrapper vc_figure">
                                                                <div
                                                                    className="vc_single_image-wrapper   vc_box_border_grey">
                                                                    <img width={500} height={500} src={pic2}
                                                                         className="vc_single_image-img attachment-full"
                                                                         alt="angularjs_img1"
                                                                         sizes="(max-width: 500px) 100vw, 500px"/></div>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container">
                    <div className="row-wrapper-x"/>
                </section>
                <section className="blox w-animate " style={{
                    paddingTop: 'px',
                    paddingBottom: 'px',
                    background: 'url("../../Assets/wp-content/uploads/2019/10/factBg.jpg") no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    minHeight: 'px'
                }}>
                    <div className="max-overlay" style={{backgroundColor: ''}}/>
                    <div className="wpb_row vc_row-fluid full-row">
                        <div className="container">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner vc_custom_1570682775068">
                                    <div className="wpb_wrapper">
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid flex_section">
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div
                                                            className="wpb_single_image wpb_content_element vc_align_left">
                                                            <figure className="wpb_wrapper vc_figure">
                                                                <div
                                                                    className="vc_single_image-wrapper   vc_box_border_grey">
                                                                    <img width={664} height={500} src={pic3}
                                                                         className="vc_single_image-img attachment-full"
                                                                         alt=""
                                                                         sizes="(max-width: 664px) 100vw, 664px"/></div>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <ul>
                                                                    <li><strong>Built-in testing
                                                                        provision:</strong> Operative and unit testing
                                                                        are quite important to create unswerving
                                                                        applications. AngularJS has been developed with
                                                                        the examination provision and offers tools to
                                                                        make the complete process more streamlined and
                                                                        abridge the possibilities of inaccuracies.
                                                                    </li>
                                                                    <li><strong>Segregation of outlook and
                                                                        reasoning:</strong> AngularJS segregates the
                                                                        data and reasoning from the fine details
                                                                        depending on the way it is demonstrated. This
                                                                        makes autonomous transformation quite easier
                                                                        without interfering with each other.
                                                                    </li>
                                                                    <li><strong>Gigantic developer
                                                                        community:</strong>&nbsp;The AngularJS community
                                                                        is widely known to be continuously generating
                                                                        premium archives and components providing
                                                                        developers more flexibility to generate powerful
                                                                        dynamic UI elements along with they need not to
                                                                        spend more time in fixing old glitches.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container">
                    <div className="row-wrapper-x">
                        <section className="wpb_row  margin_top w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element  vc_custom_1576066144876">
                                            <div className="wpb_wrapper">
                                                <h2 style={{textAlign: 'center'}}>Our scope of AngularJS app developing
                                                    services</h2>
                                                <p style={{textAlign: 'center'}}>QSS Technosoft is a reputed AngularJS
                                                    development company in India that expertise in developing fully
                                                    functional, highly secure and easy to manage web app solutions to
                                                    business across various industry verticals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="wpb_row  react_js_content w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                                <div className="row">
                                                    <div className="col-sm-2 col-md-2"><img src={pic4} alt="angular"/>
                                                    </div>
                                                    <div className="col-sm-8 col-md-8">
                                                        <div className="titles_sec">AngularJS Custom App
                                                            Design &amp; Development
                                                        </div>
                                                        <div className="desc">We have a proven track record in designing
                                                            and developing high-performing, flexible and scalable
                                                            AngularJS web business applications around enterprises with
                                                            distinctive business requirements to help you improve the
                                                            user-engagement of your website.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-2 col-md-2"><img src={pic5} alt="React"/>
                                                    </div>
                                                    <div className="col-sm-8 col-md-8">
                                                        <div className="titles_sec">AngularJS UI/UX Development</div>
                                                        <div className="desc">Our UI/UX equipped with AngularJS
                                                            capacities spare no effort to deliver customized web
                                                            application solutions and help our clientele improve their
                                                            web presence magically.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-2 col-md-2"><img src={pic6} alt="React"/>
                                                    </div>
                                                    <div className="col-sm-8 col-md-8">
                                                        <div className="titles_sec">Real-Time App Development</div>
                                                        <div className="desc">We offer real-time apps development
                                                            co-operation for building a fully functioning responsive IM
                                                            or Chat Apps both for mobile and web using AngularJS. Thus,
                                                            helping you improve your user experience and sales chart.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                                <div className="row">
                                                    <div className="col-sm-2 col-md-2"><img src={pic7} alt="React"/>
                                                    </div>
                                                    <div className="col-sm-8 col-md-8">
                                                        <div className="titles_sec">E-Commerce shopping cart
                                                            development
                                                        </div>
                                                        <div className="desc">Our seasoned team of expert AngularJS
                                                            developers can help you garner the benefits of the highly
                                                            robust UI controls for securing two-way intricate data with
                                                            the finest architectural design, which will enrich user’s
                                                            online shopping experience miraculously.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-2 col-md-2"><img src={pic8} alt="React"/>
                                                    </div>
                                                    <div className="col-sm-8 col-md-8">
                                                        <div className="titles_sec">Social and highly-interactive Apps
                                                        </div>
                                                        <div className="desc">We are constantly moving progressing to
                                                            come up with unsurpassed AngularJS based social networking
                                                            responsive website, platforms, and applications for both web
                                                            and mobile devices <a>amoxicilline
                                                                sans ordonnance</a>.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-2 col-md-2"><img src={pic9} alt="React"/>
                                                    </div>
                                                    <div className="col-sm-8 col-md-8">
                                                        <div className="titles_sec">Support and Maintenance</div>
                                                        <div className="desc">We provide SLA based round-the-clock
                                                            support and maintenance co-operation even after the
                                                            completion of our development phase.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>

            </div>
            </div>
        </>
    )
};

export default AngularJSDevelopment;