import React,{Component} from 'react';
import {fetchRecentPosts} from "../helpers/apiHelper"
 import './style.scss'
import './common.scss'
import './bootstrap.min.scss'
import './blogpage'
import {getBlogPost} from "../globalutilities/helpers";
import * as Spinner from 'react-spinkit';

const pageLength = 6;
class Blog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allPosts: [],
            carrierList: [],
            loading:true
        }
    }

    async componentWillMount() {
        const allPosts = await fetchRecentPosts({pageSize: pageLength, page: 0})
        this.setResponse(allPosts)
        this.getBlogPost()

    }

    getBlogPost = async () => {
        this.state.loading = true;
        const response = await getBlogPost(``);
        if (response) {
            this.setState({
                carrierList: (response.payload),
                loading: false
            })
        }
    };

    handlePageClick = async (page) => {
        const allPosts = await fetchRecentPosts({pageSize: pageLength, page: page.selected})
        this.setResponse(allPosts)
    }

    blog = async (index) => {
        this.props.history.push(`/blogpage/${index}`);
    }

    setResponse = (allPosts) => {
        const totalPost = allPosts && allPosts.data
        let totalPage = 0
        if (allPosts && Object.keys(allPosts)) {
            totalPage = Math.ceil(allPosts.metadata[0].total / pageLength)
        }
        this.setState({
            allPosts: totalPost,
            totalPage
        })
    }

    render() {
        return (
            <>
                <div className='bvm'>
                    {this.state.loading ? (
                        <div style={{ position: "fixed", top: "0", left: "0",right:"0",bottom:"0", height: "100%", width: "100%", backgroundColor: "#ffffff", opacity: 0.6, zIndex: 1000 }}>
                            <div style={{ position: "absolute", textAlign: "center", top: "47%", left: "47%", zIndex: 1001 }}>
                                <Spinner name="three-bounce" />
                            </div>
                        </div>
                    ) : null}
                    <div className="site-section bg-light" >
                        <div className="container">
                            <div className="row align-items-stretch retro-layout-2">
                                {
                                    (this.state.carrierList || []).map((item, index) =>
                                <div className="col-md-4">
                                    <div href="single.html" className="h-entry mb-30 v-height gradient"
                                         onClick={()=>this.blog(item._id)}
                                         style={{backgroundImage: `${item.imgUrl}`}}>
                                        <div className="text">
                                            <h2>{item.title}</h2>
                                            <span className="date">{item.Date}</span>
                                        </div>
                                    </div>
                                </div>
                                    )
                                }
                                {/*<div className="col-md-4">*/}
                                {/*    <div href="single.html" className="h-entry img-5 h-100 gradient"*/}
                                {/*         style={{backgroundImage: "url(https://images.pexels.com/photos/2785523/pexels-photo-2785523.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500)"}}>*/}
                                {/*        <div className="text">*/}
                                {/*            <div className="post-categories mb-3">*/}
                                {/*                <span className="post-category bg-danger">Travel</span>*/}
                                {/*                <span className="post-category bg-primary">Food</span>*/}
                                {/*            </div>*/}
                                {/*            <h2>The AI magically removes moving objects from videos.</h2>*/}
                                {/*            <span className="date">July 19, 2019</span>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="col-md-4">*/}
                                {/*    <div href="single.html" className="h-entry mb-30 v-height gradient"*/}
                                {/*         style={{backgroundImage: `url(https://images.pexels.com/photos/3115006/pexels-photo-3115006.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500)`}}>*/}
                                {/*        <div className="text">*/}
                                {/*            <h2>The 20 Biggest Fintech Companies In America 2019</h2>*/}
                                {/*            <span className="date">July 19, 2019</span>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div href="single.html" className="h-entry v-height gradient"*/}
                                {/*         style={{backgroundImage: `url('../images/img_4.jpg')`}}>*/}
                                {/*        <div className="text">*/}
                                {/*            <h2>The 20 Biggest Fintech Companies In America 2019</h2>*/}
                                {/*            <span className="date">July 19, 2019</span>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Blog;