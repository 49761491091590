import React from 'react';
import pic1 from "../../Assets/wp-content/uploads/2019/10/banner.png"
import pic2 from "../../Assets/wp-content/uploads/2019/10/cloud-vendor12.png"
import bg from "../../Assets/wp-content/uploads/2019/10/Microsoft-SharePoint-Development-1.jpg"
import '../../App.css'
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'

const langBlock = [
    {
        title: "Sails.js",
        description: 'Sails is the  popular MVC framework for Node.js, designed to follow the familiar MVC pattern of frameworks like Ruby on Rails, Sails makes it easy to build custom, enterprise-grade apps.'
    },
    {
        title: "Laravel",
        description: 'Laravel is a free, open-source PHP web framework, intended for the progress of web applications following the model–view–controller (MVC) architectural pattern\n'
    },
    {
        title: "Django",
        description: 'Django is a high-level Python Web framework that inspires rapid development, clean, pragmatic design and the model-view-template (MVT) architectural pattern\n'
    },
]

const FullStackDevelopment = () => {
    window.scroll(0,0)
    return (
        <>
            <div className="bvm">
            <div>
                <section id="main-content" className="container" style={{marginTop: 34}}>
                    <div className="row-wrapper-x">
                        <section className="wpb_row   w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                                <h1 className="mar-bot-0 mar-top-20 black-col banner-title-font banner-big-title-font"
                                                    style={{
                                                        fontWeight: 600,
                                                        fontSize: '38px',
                                                        lineHeight: '50px'
                                                    }}>Kickstart your&nbsp;<span className="blue-col">full-stack developing </span>&nbsp;applications
                                                </h1>
                                                <p>We have grown an expert in the whole lot that goes into developing
                                                    front-end and back-end, working on both sides to accelerate the
                                                    development cycles. Our full-stack developers have a wide knowledge
                                                    switching roles between frontend and backend development delivering
                                                    end-to-end solution to our customers.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div
                                            className="wpb_single_image wpb_content_element vc_align_center  vc_custom_1571655702459">
                                            <figure className="wpb_wrapper vc_figure">
                                                <div className="vc_single_image-wrapper   vc_box_border_grey"><img
                                                    width={1000} height={570}
                                                    src={pic1}
                                                    className="vc_single_image-img attachment-full" alt=""
                                                    sizes="(max-width: 1000px) 100vw, 1000px"/></div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="blox      w-animate " style={{
                    paddingTop: 'px',
                    paddingBottom: 'px',
                    backgroundSize: 'cover',
                    minHeight: 'px',
                    backgroundColor: '#f9fafc'
                }}>
                    <div className="max-overlay" style={{backgroundColor: ''}}/>
                    <div className="wpb_row vc_row-fluid full-row">
                        <div className="container">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner vc_custom_1571662342015">
                                                    <div className="wpb_wrapper">
                                                        <div
                                                            className="wpb_text_column wpb_content_element  vc_custom_1576067098421 ">
                                                            <div className="wpb_wrapper">
                                                                <h2 style={{textAlign: 'center'}}>Eventually, everything
                                                                    comes down to knowledge</h2>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_10 vc_sep_border_width_5 vc_sep_pos_align_center vc_separator_no_text">
                                                            <span className="vc_sep_holder vc_sep_holder_l"><span
                                                                style={{borderColor: '#113685'}}
                                                                className="vc_sep_line"/></span><span
                                                            className="vc_sep_holder vc_sep_holder_r"><span
                                                            style={{borderColor: '#113685'}}
                                                            className="vc_sep_line"/></span>
                                                        </div>
                                                        <div
                                                            className="wpb_text_column wpb_content_element  vc_custom_1576068786928">
                                                            <div className="wpb_wrapper">
                                                                <p className="text-center"
                                                                   style={{textAlign: 'center'}}>An application, mobile
                                                                    or web, delivers knowledge before anything else. If
                                                                    it fails to deliver the right UX to its users, it is
                                                                    doomed already.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                            <div className="fullstack_sec wpb_column vc_column_container vc_col-sm-3">
                                                <div className="vc_column-inner vc_custom_1571661732301">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <h4 style={{fontWeight: 500}}>Simplicity</h4>
                                                                <p>When it comes to imitating real world knowledge in
                                                                    your design, simplicity is not very simple to
                                                                    achieve.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fullstack_sec wpb_column vc_column_container vc_col-sm-3">
                                                <div className="vc_column-inner vc_custom_1571661738980">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <h4 style={{fontWeight: 500}}>Responsiveness</h4>
                                                                <p className="mar-bot-10">Users expect the applications
                                                                    they use to instantly reply to their input. Any stay
                                                                    and they might be already peeking at your
                                                                    competitor</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fullstack_sec wpb_column vc_column_container vc_col-sm-3">
                                                <div className="vc_column-inner vc_custom_1571661745835">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <h4 style={{fontWeight: 500}}>Philosophy</h4>
                                                                <p className="mar-bot-10">Every business has their own
                                                                    opinion when it comes to design. You must have a
                                                                    strong design opinion before you enter the
                                                                    market</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fullstack_sec wpb_column vc_column_container vc_col-sm-3">
                                                <div className="vc_column-inner vc_custom_1571661752643">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <h4 style={{fontWeight: 500}}>Insightful</h4>
                                                                <p>An insightful application can increase conversions
                                                                    and is more beneficial to a business than an
                                                                    everyday application.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container">
                    <div className="row-wrapper-x">
                        <section className="wpb_row   w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner vc_custom_1571662385038">
                                                    <div className="wpb_wrapper">
                                                        <div
                                                            className="wpb_text_column wpb_content_element  vc_custom_1576067256379">
                                                            <div className="wpb_wrapper">
                                                                <h2 style={{textAlign: 'center'}}>Robust development
                                                                    frameworks at your order</h2>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_10 vc_sep_border_width_5 vc_sep_pos_align_center vc_separator_no_text">
                                                            <span className="vc_sep_holder vc_sep_holder_l"><span
                                                                style={{borderColor: '#113685'}}
                                                                className="vc_sep_line"/></span><span
                                                            className="vc_sep_holder vc_sep_holder_r"><span
                                                            style={{borderColor: '#113685'}}
                                                            className="vc_sep_line"/></span>
                                                        </div>
                                                        <div
                                                            className="wpb_text_column wpb_content_element  vc_custom_1576067295573">
                                                            <div className="wpb_wrapper">
                                                                <p className="text-center"
                                                                   style={{textAlign: 'center'}}>Whether you’re an
                                                                    open-source buff or someone who believes proprietary
                                                                    software is more reliable than open source or
                                                                    prefers PHP to Python but don’t care if a couple of
                                                                    codes are in Ruby.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                            {
                                                langBlock.map(v => {
                                                    return (
                                                         <div className="fullstack_sec wpb_column vc_column_container info-box__shadow vc_col-sm-4">
                                                            <div className="vc_column-inner vc_custom_1571661732301">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_text_column wpb_content_element ">
                                                                    <div className="wpb_wrapper"
                                                                         style={{width: "298px", margin: "auto",paddingRight:"60px"}}>
                                                                        <h4 style={{
                                                                            fontWeight: 500,
                                                                            textAlign: 'center'
                                                                        }}>{v.title}</h4>
                                                                        <p style={{textAlign: 'center'}}>{v.description}</p>
                                                                    </div>
                                                                </div>
                                                             </div>
                                                        </div>
                                                    </div>)
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="blox   full-container   w-animate " style={{
                    paddingTop: '100px',
                    paddingBottom: '100px',
                    height: '480px',
                    background: `url(${bg}) no-repeat`,
                    backgroundPosition: 'center center',
                    minHeight: 'px'
                }}>
                    <div className="max-overlay" style={{backgroundColor: ''}}/>
                    <div className="wpb_row vc_row-fluid full-row">
                        <div className="container">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner vc_custom_1572005105469">
                                    <div className="wpb_wrapper"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container">
                    <div className="row-wrapper-x">
                        <section className="wpb_row   w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner vc_custom_1572002884146">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element  vc_custom_1576067487372">
                                            <div className="wpb_wrapper">
                                                <h3>Choose the cloud huckster of your choice</h3>
                                                <p>With so many cloud platforms available and offering a greater degree
                                                    of interoperability to enable high-performance, cross-compatible web
                                                    and mobile apps. Apparently, the cloud computing exchange is
                                                    controlled by the three of the biggest technology firms: Microsoft,
                                                    Amazon and Google</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_single_image wpb_content_element vc_align_center">
                                            <figure className="wpb_wrapper vc_figure">
                                                <div className="vc_single_image-wrapper   vc_box_border_grey"><img
                                                    width={566} height={359}
                                                    src={pic2}
                                                    className="vc_single_image-img attachment-full" alt=""
                                                    sizes="(max-width: 566px) 100vw, 566px"/></div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </div>
            </div>
        </>
    )
};

export default FullStackDevelopment;