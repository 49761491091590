import React from 'react';
import bg from "../../Assets/wp-content/uploads/2017/07/Color-Fill-1-1.jpg"
import ob1 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object-1.jpg"
import ob2 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object1.jpg"
import ob3 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object2.jpg"
import ob4 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object3.jpg"
import gp1 from "../../Assets/wp-content/uploads/2017/07/Group-59-1.jpg"
import gp2 from "../../Assets/wp-content/uploads/2017/07/Group-60-1.jpg"
import gp3 from "../../Assets/wp-content/uploads/2017/07/Forma-1-2.png"
import gp4 from "../../Assets/wp-content/uploads/2017/07/Forma-12-2.png"
import gp5 from "../../Assets/wp-content/uploads/2017/07/Forma-11-2.png"
import gp6 from "../../Assets/wp-content/uploads/2017/07/Forma-13-1.png"
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'

const IphoneAppDevelopment = () => {
    window.scroll(0,0)
    return (
        <div className="bvm">
        <div id="wrap" className="">
            <section id="main-content" className="container">
                <div className="row-wrapper-x">
                    <p/></div>
            </section>
            <section className="blox full-container w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                background:` url(${bg}) no-repeat`,
                backgroundPosition: 'center top',
                backgroundSize: 'cover',
                minHeight: 'px',
                textAlign: 'center'
            }}>
                <div className="max-overlay" style={{backgroundColor: ''}}/>
                <div className="wpb_row vc_row-fluid full-row">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space2"/>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h1 className="cust-title-h2" style={{color: 'white'}}><strong>iPhone App
                                                Development</strong></h1>
                                            <p className="cust-sub-p" style={{color: 'white'}}>Engaging iPhone apps that
                                                help you achieve your business objectives</p>
                                        </div>
                                    </div>
                                    <hr className="vertical-space1"/>

                                    <hr className="vertical-space2"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space2"/>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h2 className="cust-1" style={{textAlign: 'center'}}><strong>Custom
                                                designed, high-performance iPhone apps</strong></h2>
                                            <p style={{fontSize: '16px'}}>iOS is the second common popular mobile
                                                operating system worldwide &amp; we are the global pioneers in creating
                                                profitable mobile apps for iPhones &amp; iPads. Our mobile application
                                                development team renders mostly robust &amp; scalable iPhone/iPad
                                                applications with stunning features, elegant UI and efficient code. By
                                                implementing Apple-specific features like Retina Display Optimization,
                                                Map-kit, Pass-Kit etc &amp; integrating custom features like In-App
                                                purchasing &amp; Social integration, we deploy amazing mobile apps that
                                                not only gain urgent hits but earn top rankings in Apple’s App store and
                                                fetch glorious reviews along with 5-star ratings from users across the
                                                globe. From iOS4, iOS5 to iOS6, iOS7 &amp; the recently released iOS8,
                                                our custom-built apps are compatible with all previous as well as newly
                                                launched iPhones &amp; iPads.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="wpb_row  space w-animate full-row">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                            <div
                                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                <span className="vc_sep_holder vc_sep_holder_l"><span
                                    className="vc_sep_line"/></span><span
                                className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h2 className="web cust-2" style={{textAlign: 'center', color: '#24242a'}}>
                                                <strong>iPhone/iPad Mobile Application Development Services</strong>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1566188060465">
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center   img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={428} height={438}
                                                                     src={ob1}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 428px) 100vw, 428px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="web cust-3">Custom iPhone/iPad Apps
                                                                Development</h4>
                                                            <p>We are a team of extremely skilled iPhone application
                                                                developers who have an in-depth understanding of the
                                                                Apple hardware &amp; application development process on
                                                                the iOS platform. Our technical competence involves
                                                                hands-on experience in various
                                                                components &amp; technologies like – iPhone SDK,
                                                                Phonegap, Cocoa Touch, Objective C, OpenGL, XCode IDE,
                                                                Interface builder, Sencha, Core Audio, Core Animation,
                                                                Core Graphics, Webkit Programming, GPS &amp; Core
                                                                Location Frameworks. <strong>Besides this, we also offer
                                                                    Application Maintenance services to meet your
                                                                    ever-growing requirements as your business
                                                                    expands &amp; to assist with future technology
                                                                    upgrades.</strong></p>
                                                            <p>So far, we have successfully served 100+ overseas clients
                                                                across diversified business verticals through our
                                                                custom-built apps.</p>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow"><p/>
                                                        <ul style={{fontSize: '15px'}}>
                                                            <li>Travel &amp; Hotel iPhone Apps</li>
                                                            <li>News &amp; Business iPhone Apps</li>
                                                            <li>Lifestyle, Health, Sports, Fitness and Medical iPhone
                                                                Apps
                                                            </li>
                                                            <li>Social Networking iPhone Apps</li>
                                                            <li>Music and video iPhone Apps</li>
                                                            <li>Education Apps(Tutorials)</li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="wpb_row  space w-animate full-row">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                            <div
                                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                <span className="vc_sep_holder vc_sep_holder_l"><span
                                    className="vc_sep_line"/></span><span
                                className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="flex_section wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1566188077489">
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="web cust-4"><strong>iPhone/iPad Game
                                                                Development</strong></h4>
                                                            <p>Being informed of all the significant
                                                                features &amp; gestures game enthusiasts mostly
                                                                prefer &amp; keeping in mind the criticality of
                                                                high-speed and performance, we create highly innovative
                                                                game apps (both 2D &amp; 3D) with unbelievable graphics,
                                                                unique characters and amazing sounds. From action sports
                                                                to fun games, shooting games to card games and racing
                                                                games to entertainment, our mobile app developers deploy
                                                                apps that offer smooth &amp; incredible gaming
                                                                experience.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center   img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={161} height={337}
                                                                     src={ob2}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 161px) 100vw, 161px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1566188087016">
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center   img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={174} height={365}
                                                                     src={ob3}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 174px) 100vw, 174px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="web cust-4"><strong>Enterprise iPhone
                                                                Applications</strong></h4>
                                                            <p>Composing an iPhone app for SMBs &amp; large corporations
                                                                that is smart enough to increase productivity isn’t just
                                                                about finding a mobile application developer but an
                                                                experienced team of programming experts &amp; UI/UX
                                                                architects who can collaboratively work together to
                                                                understand the business needs &amp; nail the perfect
                                                                solution. At TIS, we intelligently integrate the
                                                                user-facing interface with highly sophisticated backend
                                                                business logic while taking care of security risks,
                                                                managing safe distribution &amp; efficient maintenance
                                                                to meet client-specific business requirements.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row  space w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="flex_section wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1566188097816">
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="web cust-4"><strong>iPhone M-commerce
                                                                Applications</strong></h4>
                                                            <p>We put your online stocks on the palm of your customers
                                                                via beautifully designed feature-rich iPhone &amp; iPad
                                                                apps allowing your customers to shop while-on-the go.
                                                                Besides performing the mobile shopping solutions that
                                                                increase sales, we can also create other m-commerce
                                                                iPhone apps that allow one to carry out other commercial
                                                                activities through iPhone – like business management,
                                                                mobile banking, organizing product
                                                                catalogs &amp; featuring statements of account,
                                                                sales &amp; payment.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center   img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={162} height={339}
                                                                     src={ob4}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 162px) 100vw, 162px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row  space w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1566188106552">
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center   img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={173} height={363}
                                                                     src={gp1}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 173px) 100vw, 173px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="web cust-4"><strong>iPhone Widget
                                                                Development</strong></h4>
                                                            <p>We have a team of UI/UX designers along with the skilled
                                                                mobile application developers to design &amp; develop
                                                                high quality widgets for iPhones &amp; iPads as per your
                                                                specific business needs or app requirements. Before
                                                                deployment, our quality assurance team tests every
                                                                widget vigorously for smooth functionality, bugs or
                                                                user-experience errors &amp; fix them if they find
                                                                any.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row  space w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="flex_section wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1566188116512">
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="web cust-4"><strong>iPhone Application
                                                                Testing, Support &amp; Maintenance</strong></h4>
                                                            <p>Before going active with the developed app, our in-house
                                                                mobile QA team conducts a complete quality audit to
                                                                ensure they render a 100% bug-free application. Not just
                                                                this, our team of iOS specialists provide 24 X 7
                                                                technical assistance in case you discover any issues. We
                                                                also offer Application preservation services concerning
                                                                compatibility issues, technology upgrades, code
                                                                maintenance, future enhancements, data
                                                                back-up &amp; recovery, etc.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center   img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={162} height={339}
                                                                     src={gp2}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 162px) 100vw, 162px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="wpb_row  space full-row">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                            <div
                                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                <span className="vc_sep_holder vc_sep_holder_l"><span
                                    className="vc_sep_line"/></span><span
                                className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h2 className="cust-5" style={{textAlign: 'center', color: '#24242a'}}>
                                                <strong>Lancing an iPhone app is just the first step. You want App Store
                                                    Optimization services to let your app found amongst thousands of
                                                    other apps on iTunes (Apple app store). At TIS, we execute
                                                    intelligent Mobile SEO to ensure your app is more discoverable for
                                                    important search queries &amp; generates maximum no. of
                                                    downloads.</strong></h2>
                                        </div>
                                    </div>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1566189136999">
                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2 col-xs-2"><img
                                                                    src={gp3} alt=""/>
                                                                </div>
                                                                <div className="col-md-10 col-xs-10">
                                                                    <h4 className="cust-5"><strong>App Keyword
                                                                        Optimization</strong></h4>
                                                                    <p>We strategically develop your app rankings by
                                                                        researching new keywords that have the
                                                                        conversion potential &amp; that are frequently
                                                                        searched by people. Next, we optimize them as
                                                                        through iOS 100 character limit.</p>
                                                                </div>
                                                            </div>
                                                            <p>&nbsp;</p>
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2 col-xs-2"><img
                                                                    src={gp4}
                                                                    alt="media manager"/></div>
                                                                <div className="col-md-10 col-xs-10">
                                                                    <h4 className="cust-5"><strong>App Keyword
                                                                        Localization</strong></h4>
                                                                    <p>Besides English, we can also do keyword
                                                                        optimization in other communications by
                                                                        suggesting keywords in Spanish, Portuguese,
                                                                        German, and French etc as per your localized
                                                                        market &amp; target audience.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2 col-xs-2"><img
                                                                    src={gp5}
                                                                    alt="powerful content management"/></div>
                                                                <div className="col-md-10 col-xs-10">
                                                                    <h4 className="cust-5"><strong>App Name/Title
                                                                        Optimization</strong></h4>
                                                                    <p>You can also get an SEO optimized name of your
                                                                        app from us for better rankings &amp; more extra
                                                                        traffic.</p>
                                                                </div>
                                                            </div>
                                                            <p>&nbsp;</p>
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2 col-xs-2"><img
                                                                    src={gp6}
                                                                    alt="international support"/></div>
                                                                <div className="col-md-10 col-xs-10">
                                                                    <h4 className="cust-5"><strong>App
                                                                        Icon &amp; Screenshots Optimization</strong>
                                                                    </h4>
                                                                    <p>Marvelous icons &amp; engaging screenshots shine
                                                                        in the crowded app store catching quick
                                                                        attention, setting you apart from your
                                                                        competitors and prompting users to download the
                                                                        app.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="wpb_row   full-row">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                            <div
                                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                <span className="vc_sep_holder vc_sep_holder_l"><span
                                    className="vc_sep_line"/></span><span
                                className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  vc_custom_1566189168270">
                                        <div className="wpb_wrapper">
                                            <h2 className="web-text cust-6"
                                                style={{textAlign: 'center', color: '#171c24'}}><strong>We
                                                design &amp; deliver awesome iPhone Apps</strong></h2>
                                        </div>
                                    </div>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <ul className="arrow" style={{listStyleType: 'none'}}>
                                                                <li>
                                                                    <figure>&nbsp; &nbsp;<strong>Highly scalable
                                                                        apps</strong>&nbsp;from most
                                                                        talented &amp; experienced team of 40+ iPhone
                                                                        app developers who’ve worked on 100+ apps so
                                                                        far.
                                                                    </figure>
                                                                </li>
                                                                <li>
                                                                    <figure>&nbsp; &nbsp;<strong>Power prepared with
                                                                        outstanding features</strong>&nbsp;– visually
                                                                        appealing business-centric UI, high resolution
                                                                        crystal clear display, smooth interactivity,
                                                                        enhanced performance and amazing user
                                                                        experience.
                                                                    </figure>
                                                                </li>
                                                                <li>
                                                                    <figure>&nbsp; &nbsp;Assigning the work to&nbsp;
                                                                        <strong>right team of experts dedicated to your
                                                                            project</strong>to reduce
                                                                        complexity &amp; enhance productivity
                                                                    </figure>
                                                                </li>
                                                                <li>
                                                                    <figure>&nbsp; &nbsp;Deployment of&nbsp;<strong>innovative
                                                                        mobile resolutions</strong>&nbsp;that
                                                                        ensure&nbsp;<strong>high returns on
                                                                            investment</strong></figure>
                                                                </li>
                                                                <li>
                                                                    <figure>&nbsp; &nbsp;World class user interface
                                                                        designers offering wide range of&nbsp;<strong>custom
                                                                            iPhone App development services</strong>
                                                                    </figure>
                                                                </li>
                                                                <li>
                                                                    <figure>&nbsp; &nbsp;<strong>Source code protection
                                                                        with NDA</strong>&nbsp;so that your app idea is
                                                                        safe with us
                                                                    </figure>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <ul className="arrow" style={{listStyleType: 'none'}}>
                                                                <li>
                                                                    <figure>&nbsp; &nbsp;Rigorous application testing to
                                                                        deploy&nbsp;<strong>bug-free
                                                                            applications</strong></figure>
                                                                </li>
                                                                <li>
                                                                    <figure>&nbsp; &nbsp;<strong>Cross-device
                                                                        functionality</strong>&nbsp;crossed all Apple
                                                                        devices – compatible with all versions of
                                                                        iPhone &amp; iPad
                                                                    </figure>
                                                                </li>
                                                                <li>
                                                                    <figure>&nbsp; &nbsp;<strong>Cost effective
                                                                        deployment</strong>&nbsp;&amp; effective coding
                                                                        as per latest guidelines
                                                                    </figure>
                                                                </li>
                                                                <li>
                                                                    <figure>&nbsp; &nbsp;<strong>Guaranteed approval on
                                                                        iTunes (Apple App store).</strong>&nbsp;So far,
                                                                        we have 100+ approved apps in iTunes
                                                                    </figure>
                                                                </li>
                                                                <li>
                                                                    <figure>&nbsp; &nbsp;<strong>Great optimization
                                                                        (Mobile SEO)</strong>&nbsp;&amp; integration of
                                                                        social media signals &amp; advertisements – all
                                                                        in one package to give you complete mobile
                                                                        solutions for your business.
                                                                    </figure>
                                                                </li>
                                                                <li>
                                                                    <figure>&nbsp; &nbsp;<strong>Best Methods, Proven
                                                                        Methodologies,</strong>&nbsp;Experience of
                                                                        working with diverse client base &amp; know-how
                                                                        of latest development tools &amp; platforms to
                                                                        deliver business-centric mobile app solutions
                                                                    </figure>
                                                                </li>
                                                                <li>
                                                                    <figure>&nbsp; &nbsp;<strong>Seamless
                                                                        communication</strong>&nbsp;via 24 x 7 technical
                                                                        support
                                                                    </figure>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </div>
        </div>
    )
};

export default IphoneAppDevelopment;