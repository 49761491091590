import React from 'react';
import ColorFill11 from "../../Assets/wp-content/uploads/2017/07/Color-Fill-11.png";
import pic1 from "../../Assets/wp-content/uploads/2017/07/think-it-400x200.jpg"
import pic2 from "../../Assets/wp-content/uploads/2017/07/web-portfolio-img-400x200.jpg"
import pic3 from "../../Assets/wp-content/uploads/2017/07/for-sale-400x200.jpg"
import pic4 from "../../Assets/wp-content/uploads/2017/07/buzzy-400x200.png"
import log1 from "../../Assets/wp-content/uploads/2017/07/seo-px2v2-1.jpg"
import log2 from "../../Assets/wp-content/uploads/2017/07/L0001.jpg"
import log3 from "../../Assets/wp-content/uploads/2017/07/Forma-2.jpg"
import log4 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object.jpg"
import log5 from "../../Assets/wp-content/uploads/2017/07/Forma-11.jpg"
import log6 from "../../Assets/wp-content/uploads/2017/07/Forma-12.jpg"
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'
import'../../App.css'
import PortFolio from "../../PortFolio";

const WebDesign = () => {
    window.scroll(0,0)
    const list=[
        {title:"Beautiful",tag:"Beautiful websites give better user experience",pic:pic1,description:"Different web design with interactive UI that have better user experience,  stand out to give you an edge over competitors &amp; have that wow factor can compel your visitors to convert. Our original website designing team spends time to understand your business objectives &amp; target before starting the designing process."},
        {title:"Responsive",tag:"User friendly websites for multi device world",pic:pic2,description:"More than 70% of your website visitants are now using mobile. Our world-class web design company introduces fully responsive websites with an intelligent user interface that adapts to varied screen resolutions and delivers an amazing user experience regardless of the device and browsing environment."},
        {title:"Effective",tag:"Make your website your best sales person",pic:pic3,description:"Your website is the high spot of all your marketing efforts &amp; we strive to make it active so that it can talk directly to your potential customers &amp; generate new leads. Our web design team imagines the site with sales perspective to build a website that not just looks great but can convert visitors into customers."},
        {title:"Customized",tag:"Customized Websites that connect well, perform well",pic:pic4,description:"When it comes to your marketing buck, you need a solid digital strategy customized to your particular business requirements &amp; a well-structured web design in line with your marketing plan. Our affordable web design service is carried out by an expert team who spends time getting your industry and target market before starting the web design in process."}
        ];
    return (
        <div className="bvm">
        <div id="wrap" className="">
            <section className="blox full-container w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                background: `url(${ColorFill11})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                minHeight: 'px',
                textAlign: 'center'
            }}>
                <div className="max-overlay" style={{backgroundColor: ''}}/>
                <div className="wpb_row vc_row-fluid full-row">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space2"/>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h1 className="banner-heading" style={{color: 'white'}}><strong>Website
                                                Design
                                                Services</strong></h1>
                                            <h3 className="banner-subhead"
                                                style={{textAlign: 'center', color: 'white', fontSize: "25px", lineHeight: '32px',marginTop: '30px'}}>Give your
                                                website an amazing UI &amp; conversion potential with our customer
                                                centric
                                                website design that works on all devices.</h3>
                                        </div>
                                    </div>
                                    <hr className="vertical-space1"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner vc_custom_1569651610577">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        {
                                            list.map((value,i) => (
                                                <div key={i} className="responsive_dec wpb_column vc_column_container vc_col-sm-6">
                                                    <div className="vc_column-inner ">
                                                        <div className="wpb_wrapper">
                                                            <div className="vc_empty_space  vc_custom_1500621931749"
                                                                 style={{height: '32px'}}>
                                                                <span className="vc_empty_space_inner"/></div>
                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className=" ">
                                                                    <h2 style={{textAlign: 'center'}}><strong>{value.title}</strong>
                                                                    </h2>
                                                                    <p style={{textAlign: 'center', fontSize: '16px'}}>{value.tag}</p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_1 wpb_bounceInLeft bounceInLeft vc_custom_1568459295531">
                                                                <figure className="wpb_wrapper vc_figure">
                                                                    <div
                                                                        className="vc_single_image-wrapper vc_box_border_grey">
                                                                        <img className="vc_single_image-img "
                                                                             src={value.pic}
                                                                             width={400} height={200} alt="think-it"
                                                                             title="think-it"/>
                                                                    </div>
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="wpb_text_column wpb_content_element  vc_custom_1570253498212 website_dec">
                                                                <div className="wpb_wrapper">
                                                                    <p className="data-information">{value.description}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="parallax-sec blox full-container w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                backgroundColor: '#113685',
                backgroundSize: 'cover',
                minHeight: '85px',
                textAlign: "center"
            }} data-stellar-background-ratio="0.6">
                <div className="max-overlay" style={{backgroundColor: ''}}/>
                <div data-stellar-ratio={1} className="wpb_row vc_row-fluid ">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-8" style={{marginLeft:"0px"}}>
                            <div className="wpb_wrapper">
                                <h1 style={{fontSize: '38px', color: '#ffffff', textAlign: 'center'}}
                                    className="vc_custom_heading"><p/>
                                    <p>
                                    </p>
                                    <center style={{textAlign:"center"}}>Get Your Website A Digital Makeover</center>
                                </h1>
                                <hr className="vertical-space1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12" style={{marginTop: '40px'}}>
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1562389199081">
                                        <div className="wpb_column vc_column_container vc_col-sm-3">
                                            <div className="vc_column-inner vc_custom_1562389179305">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_text_column wpb_content_element  vc_custom_1568114202998 dedicated_title">
                                                        <div className="wpb_wrapper">
                                                            <h2><span style={{fontFamily: ' cursive', fontSize:'smaller', color: '#4975dd'}}>Hire Dedicated Designer</span>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div className="wpb_single_image wpb_content_element vc_align_left">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={332} height={325}
                                                                     src={log1}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 332px) 100vw, 332px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-9">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper"><p
                                                    style={{fontSize: '16px', textAlign: 'left'}}
                                                    className="vc_custom_heading">If you have multiple
                                                    projects or one large on-going project you are planning to launch or
                                                    want to
                                                    have a design makeover, then opting for our web design company can
                                                    save you
                                                    time and overhead costs.</p>
                                                    <p style={{fontSize: '16px', textAlign: 'left'}}
                                                       className="vc_custom_heading">Our
                                                        creative design team have 120+ web design experts having years
                                                        of hands
                                                        on experience with user interface web designing for
                                                        desktop &amp; mobile
                                                        users. Like our 100+ happy clients, hiring full time web
                                                        designers over
                                                        an in-house team can benefit you for many reasons:</p>
                                                    <ul className="arrow2"><p/>
                                                        <ul style={{fontSize: '15px',marginLeft:'15px'}}>
                                                            <li>&nbsp; &nbsp; &nbsp;Zero Spend on Infrastructure</li>
                                                            <li>&nbsp; &nbsp; &nbsp;Long term cost saving</li>
                                                            <li>&nbsp; &nbsp; &nbsp;Hand-picked experienced team</li>
                                                            <li>&nbsp; &nbsp; &nbsp;Full control &amp; transparency</li>
                                                            <li>&nbsp; &nbsp; &nbsp;Direct monitoring on IM,
                                                                email &amp; calls
                                                            </li>
                                                            <li>&nbsp; &nbsp; &nbsp;Transparent billing system</li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_empty_space  vc_custom_1569649299118" style={{height: '0px'}}>
                                        <span className="vc_empty_space_inner"/></div>
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_70 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey  space-creator">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row  five-colume">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_row wpb_row vc_inner vc_row-fluid text-center vc_row-o-equal-height vc_row-flex">
                                        <div className="wpb_column vc_column_container vc_col-sm-1">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper"/>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-2">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <article className="icon-box5"><img
                                                        src={log2} alt=""/><h4>
                                                        <strong>Quality
                                                            Guaranteed</strong><br/>
                                                    </h4></article>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-2">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <article className="icon-box5"><img
                                                        src={log3} alt=""/><h4>
                                                        Proficient Designer</h4></article>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-2">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <article className="icon-box5"><img
                                                        src={log4}
                                                        alt=""/>
                                                        <h4>Assured Replacement</h4></article>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-2">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <article className="icon-box5"><img
                                                        src={log5} alt=""/><h4>
                                                        Frequent<br/>
                                                        Update</h4></article>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-2">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <article className="icon-box5"><img
                                                        src={log6} alt=""/><h4>
                                                        Great<br/>
                                                        Support</h4></article>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-1">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_70 vc_sep_pos_align_center vc_separator_no_text  space-creator">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                            style={{borderColor: 'rgba(255,255,255,0.01)'}}
                                            className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span
                                        style={{borderColor: 'rgba(255,255,255,0.01)'}} className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <PortFolio/>
        </div>
        </div>
    )
};

export default WebDesign;