import React from 'react';
import pic1 from '../../Assets/images/ReactJS-images/neticon.png'
import pic2 from '../../Assets/images/ReactJS-images/net_img.png'
import pic3 from '../../Assets/images/ReactJS-images/angularjs_img2.png'
 import pic5 from '../../Assets/images/ReactJS-images/custom-plugins.png'
import pic6 from '../../Assets/images/ReactJS-images/seamless-migration.png'
import pic7 from '../../Assets/images/ReactJS-images/maintenance.png'
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'

const ReactJSDevelopment = () => {
    return (
        <>
            <div className="bvm">
                <div>
                    <section id="main-content" className="container">
                        <div className="row-wrapper-x">
                            <section className="wpb_row   w-animate">
                                <div className="wpb_column vc_column_container vc_col-sm-8">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_text_column wpb_content_element  vc_custom_1573187804981">
                                                <div className="wpb_wrapper"><br/>
                                                    <h2 style={{color: '#113685'}}><strong>.NET
                                                        &nbsp;Development</strong></h2>
                                                </div>
                                            </div>
                                            <div
                                                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_20 vc_sep_border_width_2 vc_sep_pos_align_left vc_separator_no_text">
                                            <span className="vc_sep_holder vc_sep_holder_l"><span
                                                style={{borderColor: '#113685'}} className="vc_sep_line"/></span><span
                                                className="vc_sep_holder vc_sep_holder_r"><span
                                                style={{borderColor: '#113685'}} className="vc_sep_line"/></span>
                                            </div>
                                            <div className="wpb_text_column wpb_content_element ">
                                                <div className="wpb_wrapper">
                                                    <div className="intricate">
                                                        <h2>The success or failure of any project or product in the market is decided by its return on investment. Both the quantum of profits and time taken are important aspects of calculating ROI. The following features of .NET facilitate reduction of developmental and operational cost for an efficient IT organization.
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-4">
                                    <div className="vc_column-inner vc_custom_1570682517718">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_single_image wpb_content_element vc_align_left">
                                                <figure className="wpb_wrapper vc_figure">
                                                    <div className="vc_single_image-wrapper   vc_box_border_grey"><img
                                                        width={225} height={200} src={pic1}
                                                        className="vc_single_image-img attachment-full" alt=""/></div>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                    <section className="blox      w-animate " style={{
                        paddingTop: 'px',
                        paddingBottom: 'px',
                        backgroundSize: 'cover',
                        minHeight: 'px',
                        backgroundColor: '#f9f9f9'
                    }}>
                        <div className="max-overlay" style={{backgroundColor: ''}}/>
                        <div className="wpb_row vc_row-fluid full-row">
                            <div className="container">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                    <div className="vc_column-inner vc_custom_1570682775068">
                                        <div className="wpb_wrapper">
                                            <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                <div className="wpb_column vc_column_container vc_col-sm-6">
                                                    <div className="vc_column-inner ">
                                                        <div className="wpb_wrapper">
                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <p>This framework works on object-oriented programming which eliminates unnecessary codes and involves less coding for the developers. .NET consists of re-useable code and many re-useable components.  This translates into less time and consequently less cost to develop applications.</p>
                                                                    <p>With features such as no-impact applications, private components, controlled code sharing, side-by-side versioning and partially trusted code, the .NET framework makes deployment easier post development.  The code execution environment supports safe code execution for reduced conflicts in software deployment and versioning, and minimized performance problems of scripted or interpreted environments.With features such as no-impact applications, private components, controlled code sharing, side-by-side versioning and partially trusted code, the .NET framework makes deployment easier post development.  The code execution environment supports safe code execution for reduced conflicts in software deployment and versioning, and minimized performance problems of scripted or interpreted environments.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="wpb_column vc_column_container vc_col-sm-6">
                                                    <div className="vc_column-inner ">
                                                        <div className="wpb_wrapper">
                                                            <div
                                                                className="wpb_single_image wpb_content_element vc_align_left">
                                                                <figure className="wpb_wrapper vc_figure">
                                                                    <div
                                                                        className="vc_single_image-wrapper   vc_box_border_grey">
                                                                        <img width={500} height={500} src={pic2}
                                                                             className="vc_single_image-img attachment-full"
                                                                             alt=""
                                                                             sizes="(max-width: 500px) 100vw, 500px"/></div>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="container">
                        <div className="row-wrapper-x"/>
                    </section>
                    <section className="blox padding_bottom w-animate " style={{
                        paddingTop: 'px',
                        paddingBottom: 'px',
                        background: 'url("../wp-content/uploads/2019/10/factBg.jpg") no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        minHeight: 'px'
                    }}>
                        <div className="max-overlay" style={{backgroundColor: ''}}/>
                        <div className="wpb_row vc_row-fluid full-row">
                            <div className="container">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                    <div className="vc_column-inner vc_custom_1570682775068">
                                        <div className="wpb_wrapper">
                                            <div className="vc_row wpb_row vc_inner vc_row-fluid flex_section">
                                                <div className="wpb_column vc_column_container vc_col-sm-6">
                                                    <div className="vc_column-inner ">
                                                        <div className="wpb_wrapper">
                                                            <div
                                                                className="wpb_single_image wpb_content_element vc_align_left">
                                                                <figure className="wpb_wrapper vc_figure">
                                                                    <div
                                                                        className="vc_single_image-wrapper   vc_box_border_grey">
                                                                        <img width={500} height={500} src={pic3}
                                                                             className="vc_single_image-img attachment-full"
                                                                             alt=""
                                                                             sizes="(max-width: 500px) 100vw, 500px"/></div>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="wpb_column vc_column_container vc_col-sm-6">
                                                    <div className="vc_column-inner ">
                                                        <div className="wpb_wrapper">
                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <ul>
                                                                        <li><strong>Reliability::</strong> Since its release in 2002, .NET has been used to develop thousands of applications.  Its performance on Microsoft® Windows Server™ 2003 and Windows 2000 Server is also very stable and reliable.
                                                                        </li>
                                                                        <li><strong>Security:</strong> .NET offers enhanced application security as web applications developed using ASP. NET have Windows confirmation and configuration. Managed code and CLR offer safeguard features such as role-based security and code access security.
                                                                        </li>
                                                                        <li><strong>Use for Service-Oriented Architecture:</strong> .NET is often used for Web Services, which are a solution for executing an SOA strategy. Through Web Services, applications which are designed in different programming languages or platforms, are able to communicate and transmit data utilizing standard Internet protocols.
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="container">
                        <div className="row-wrapper-x">
                            <section className="wpb_row   w-animate">
                                <div className="margin_top wpb_column vc_column_container vc_col-sm-12">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_text_column wpb_content_element  vc_custom_1581328209457">
                                                <div className="wpb_wrapper">
                                                    <h2 style={{textAlign: 'center'}}>Our scope of .Net development
                                                        services</h2>
                                                    <p style={{textAlign: 'center'}}>BVM-Infotech is a leading .NET
                                                        development company that endeavours to capitalize on the
                                                        capabilities of the trendy open source library, such as
                                                        seamless scalability and imperfection forbearance to develop web
                                                        projects of varying intricacy levels. Our scope of .NET
                                                        development services mainly comprises of:</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="wpb_row  react_js_content w-animate">
                                <div className="wpb_column vc_column_container vc_col-sm-6">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_text_column wpb_content_element ">
                                                <div className="wpb_wrapper">
                                                    <div className="row">
                                                        <div className="col-sm-2 col-md-2"><img
                                                            src={pic1} alt=".Net"/>
                                                        </div>
                                                        <div className="col-sm-8 col-md-8">
                                                            <div className="titles">Personalized .NET Development</div>
                                                            <div className="desc">Our experienced .NET developers can
                                                                help you build stunning and interactive UIs for applications
                                                                and potent front-ends that you and your potential customers
                                                                can appreciate.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-2 col-md-2"><img
                                                            src={pic6}
                                                            alt="React"/></div>
                                                        <div className="col-sm-8 col-md-8">
                                                            <div className="titles">Seamless Migration</div>
                                                            <div className="desc">With our business-centric approach, we
                                                                welcome to understand your unique business requirements and
                                                                existing setup to simplify and expedite the migration
                                                                process to the new .NET framework for web application
                                                                built on older technologies.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-6">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_text_column wpb_content_element ">
                                                <div className="wpb_wrapper">
                                                    <div className="row">
                                                        <div className="col-sm-2 col-md-2"><img
                                                            src={pic5}
                                                            alt="React"/></div>
                                                        <div className="col-sm-8 col-md-8">
                                                            <div className="titles">Integration with Legacy Systems:</div>
                                                            <div className="desc">BVM-Infotech has The capability of .NET to process all types of XML documents and write any format of file with swiftness and ease, provides multiple routes for integration.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-2 col-md-2"><img
                                                            src={pic7}
                                                            alt="React"/></div>
                                                        <div className="col-sm-8 col-md-8">
                                                            <div className="titles">Support and Maintenance</div>
                                                            <div className="desc">Our seasoned team of highly experienced
                                                                .NET developers is just a call away to provide you
                                                                dedicated and round-the-clock SLA based support and
                                                                maintenance services, even after the completion of the
                                                                development process.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
};

export default ReactJSDevelopment;