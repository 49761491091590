import React from 'react';
 import grp from "../../Assets/wp-content/uploads/2017/07/Group-86.png"
import l1 from "../../Assets/wp-content/uploads/2019/07/L0001.png"
import l2 from "../../Assets/wp-content/uploads/2019/07/Forma-1.png"
import l3 from "../../Assets/wp-content/uploads/2019/07/Shape-1.png"
import L1 from "../../Assets/wp-content/uploads/2019/07/Layer-62.png"
import L2 from "../../Assets/wp-content/uploads/2019/07/Layer-63.png"
import L3 from "../../Assets/wp-content/uploads/2019/07/Layer-64.png"
import m1 from "../../Assets/wp-content/uploads/2017/07/magento-main.png"
import m2 from "../../Assets/wp-content/uploads/2017/07/OsCommerce.png"
import m3 from "../../Assets/wp-content/uploads/2017/07/drupal.png"
import m4 from "../../Assets/wp-content/uploads/2017/07/Woocommerce.png"
import m5 from "../../Assets/wp-content/uploads/2017/07/joomla.png"
import img1 from "../../Assets/wp-content/uploads/2019/08/Layer-47-copy.png"
import img2 from "../../Assets/wp-content/uploads/2019/08/Layer-47-copy.png"
import img3 from "../../Assets/wp-content/uploads/2019/08/ecommerce-platform-development.jpg"
import img4 from "../../Assets/wp-content/uploads/2019/08/Layer-48.png"
import img5 from "../../Assets/wp-content/uploads/2019/08/Layer-49.png"
import img6 from "../../Assets/wp-content/uploads/2019/08/Layer-50.png"
import img7 from "../../Assets/wp-content/uploads/2019/08/Forma-1.png"
import img8 from "../../Assets/wp-content/uploads/2019/08/L1.jpg"
import img9 from "../../Assets/wp-content/uploads/2019/08/L11.png"
import img10 from "../../Assets/wp-content/uploads/2019/08/L12.png"
import img11 from "../../Assets/wp-content/uploads/2019/08/Shape-1.png"
import img12 from "../../Assets/wp-content/uploads/2019/08/Forma-11.png"
import img13 from "../../Assets/wp-content/uploads/2019/08/L0001.png"
import img14 from "../../Assets/wp-content/uploads/2019/08/Layer-46.png"
import img15 from "../../Assets/wp-content/uploads/2019/08/L13.png"
import img16 from "../../Assets/wp-content/uploads/2019/08/L14.png"
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'
import '../../Assets/wp-content/plugins/js_composer/assets/css/js_composer.min.scss'
import '../../Assets/wp-content/plugins/js_composer/assets/css/js_composer_tta.min.scss'
import PortFolio from "../../PortFolio";

const ECommerceDevelopment = () => {
    window.scroll(0,0)
    return (
        <div className="bvm">
        <div id="wrap" className="">
            <section id="main-content" className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">

                                        <div className="wpb_wrapper"><br/>
                                            <h1 style={{textAlign: 'center'}}><span
                                                style={{color: '#113685'}}>eCommerce</span> Web Development</h1>
                                            <p style={{textAlign: 'center', fontSize: '20px', padding: '0 20%'}}><span
                                                style={{color: '#113685'}}>We turn complex </span>selling ideas of
                                                merchants worldwide into simple eCommerce models that make shopping fun
                                                for customers!!</p>
                                        </div>
                                     <div className="wpb_single_image wpb_content_element vc_align_center">
                                        <figure className="wpb_wrapper vc_figure">
                                            <div className="vc_single_image-wrapper   vc_box_border_grey"><img
                                                width={583} height={458}
                                                src={grp}
                                                className="vc_single_image-img attachment-full" alt=""
                                                sizes="(max-width: 583px) 100vw, 583px"/></div>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate w-start_animation">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1563800539110">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <p>If you are looking for an eCommerce web development
                                                                company capable of delivering customized eCommerce
                                                                website design &amp; development services on popular
                                                                eCommerce platforms like Prestashop, Magento,
                                                                Woocommerce, Shopify, etc., BVM-Infotech is the
                                                                most desirable stop for you. Our highly skilled team of
                                                                PHP programmers has extensive experience in creating
                                                                custom extensions, specific modules, and user-friendly
                                                                shopping cart software by integration of premium
                                                                eCommerce themes and 3rd party solutions.</p>
                                                            <div className="container"style={{
                                                                display: "flex",
                                                                justifyContent: "spaceBetween",
                                                                alignItems: "initial"
                                                            }}>
                                                                <div className="ecommerce-new-development-left">
                                                                    <ul style={{listStyleType: 'none'}}>
                                                                        <li style={{listStyleType: 'none',display: "flex", justifyContent: "spaceBetween", alignItems: "initial", }}>
                                                                            <div className=""><img
                                                                                src={l1}
                                                                                style={{marginTop: "12px", marginRight: "44px"}}
                                                                                alt="e-new1"/></div>
                                                                            <div className=" ">
                                                                                <h5 style={{color: '#113685'}}><strong>E-COMMERCE
                                                                                    DEVELOPMENT</strong></h5>
                                                                                <div
                                                                                    className="ecommerce-new-development-left-content">
                                                                                    <p>Online storefronts, B2B &amp; B2C
                                                                                        portals, online
                                                                                        marketplaces &amp; auction
                                                                                        websites, custom development</p>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                    <p>&nbsp;</p>
                                                                    <ul style={{listStyleType: 'none'}}>
                                                                        <li style={{listStyleType: 'none',display: "flex", justifyContent: "spaceBetween", alignItems: "initial", }}>
                                                                            <div className=""><img
                                                                                src={l2}
                                                                                style={{marginTop: "12px", marginRight: "44px"}}
                                                                                alt="e-new3"/></div>
                                                                            <div className="">
                                                                                <h5 style={{color: '#113685'}}><strong>MAINTENANCE
                                                                                    MANAGEMENT</strong></h5>
                                                                                <div
                                                                                    className="ecommerce-new-development-left-content">
                                                                                    <p>Retainer based maintenance,
                                                                                        pro-active
                                                                                        maintenance &amp; management,
                                                                                        catalog management</p>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                    <p>&nbsp;</p>
                                                                    <ul style={{listStyleType: 'none'}}>
                                                                        <li style={{listStyleType: 'none',display: "flex", justifyContent: "spaceBetween", alignItems: "initial", }}>
                                                                            <div className=""><img
                                                                                src={l3}
                                                                                style={{marginTop: "12px", marginRight: "44px"}}
                                                                                alt="e-new4"/></div>
                                                                            <div className="">
                                                                                <h5 style={{color: '#113685'}}><strong>DIGITAL
                                                                                    MARKETING</strong></h5>
                                                                                <div
                                                                                    className="ecommerce-new-development-left-content">
                                                                                    <p>SEO, SMO, PPC, CRO, ongoing
                                                                                        optimization &amp; evaluation</p>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <ul style={{listStyleType: 'none'}}>
                                                                <li style={{display: 'inline', marginLeft: '20px'}}><img
                                                                    src={L1}
                                                                    alt=""/></li>
                                                                <li style={{display: 'inline', marginLeft: '20px'}}><img
                                                                    src={L2}
                                                                    alt=""/></li>
                                                                <li style={{display: 'inline', marginLeft: '20px'}}><img
                                                                    src={L3}
                                                                    alt=""/></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="wpb_row   w-animate full-row w-start_animation">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                            <div
                                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                <span className="vc_sep_holder vc_sep_holder_l"><span
                                    className="vc_sep_line"/></span><span
                                className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wpb_row   w-animate full-row w-start_animation">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                            <div
                                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_custom_1569649884731  vc_custom_1569649884731">
                                <span className="vc_sep_holder vc_sep_holder_l"><span style={{borderColor: '#ffffff'}}
                                                                                      className="vc_sep_line"/></span><span
                                className="vc_sep_holder vc_sep_holder_r"><span style={{borderColor: '#ffffff'}}
                                                                                className="vc_sep_line"/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x"/>
            </section>
            <PortFolio/>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space3"/>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 style={{textAlign: 'center'}}>OPEN SOURCE&nbsp;<strong>ECOMMERCE
                                                APPLICATION DEVELOPMENT&nbsp;&amp;&nbsp;CUSTOMIZATION</strong></h3>
                                            <p style={{textAlign: 'center'}}>With our expert team of dedicated eCommerce
                                                Web Developers, we can identify the right open source model suiting to
                                                your selling requirements and then implement it with ease after
                                                customizing everything- from theme developing to premium template
                                                integration &amp; shopping cart customization to module integration as
                                                per your needs.</p>
                                        </div>
                                    </div>
                                    <hr className="vertical-space1"/>
                                    <div className="vc_tta-container" data-vc-action="collapse">
                                        <div
                                            className="vc_general vc_tta vc_tta-tabs vc_tta-color-white vc_tta-style-flat vc_tta-shape-square vc_tta-spacing-1 Languages vc_tta-o-no-fill vc_tta-tabs-position-top vc_tta-controls-align-center vc_custom_1565351140950 Languages">
                                            <div className="vc_tta-tabs-container">
                                                <ul className="vc_tta-tabs-list">
                                                    <li className="vc_tta-tab vc_active" data-vc-tab><a href="#magento"
                                                                                                        data-vc-tabs
                                                                                                        data-vc-container=".vc_tta"><span
                                                        className="vc_tta-title-text">Magento</span></a></li>
                                                    <li className="vc_tta-tab" data-vc-tab ><a href="#wordpress"
                                                                                              data-vc-tabs
                                                                                              data-vc-container=".vc_tta"><span
                                                        className="vc_tta-title-text" style={{color:'#113685'}}>Wordpress</span></a></li>
                                                    <li className="vc_tta-tab" data-vc-tab ><a href="#node_js"
                                                                                              data-vc-tabs
                                                                                              data-vc-container=".vc_tta"><span
                                                        className="vc_tta-title-text" style={{color:'#113685'}}>Node js</span></a></li>
                                                    <li className="vc_tta-tab" data-vc-tab><a href="#woocommerce"
                                                                                              data-vc-tabs
                                                                                              data-vc-container=".vc_tta"><span
                                                        className="vc_tta-title-text" style={{color:'#113685'}}>Woocommerce</span></a></li>
                                                    <li className="vc_tta-tab" data-vc-tab><a href="#react_js"
                                                                                              data-vc-tabs
                                                                                              data-vc-container=".vc_tta"><span
                                                        className="vc_tta-title-text" style={{color:'#113685'}}>React js</span></a></li>
                                                </ul>
                                            </div>
                                            <div className="vc_tta-panels-container">
                                                <div className="vc_tta-panels">
                                                    <div className="vc_tta-panel vc_active tabselection" id="magento"
                                                         data-vc-content=".vc_tta-panel-body">
                                                        <div className="vc_tta-panel-heading"><h4
                                                            className="vc_tta-panel-title"><a href="#magento"
                                                                                              data-vc-accordion
                                                                                              data-vc-container=".vc_tta-container"><span
                                                            className="vc_tta-title-text">Magento</span></a></h4></div>
                                                        <div className="vc_tta-panel-body">
                                                            <div id="magento"
                                                                 className="vc_row wpb_row vc_inner vc_row-fluid">
                                                                <div
                                                                    className="wpb_column vc_column_container vc_col-sm-7">
                                                                    <div className="vc_column-inner ">
                                                                        <div className="wpb_wrapper">
                                                                            <hr className="vertical-space1"/>
                                                                            <div
                                                                                className="wpb_text_column wpb_content_element ">
                                                                                <div className="wpb_wrapper">
                                                                                    <h3><strong>Magento CE</strong>(Community
                                                                                        Edition) Ecommerce
                                                                                        Development &amp; Customization
                                                                                    </h3>
                                                                                    <p>We combine the versatility of
                                                                                        Magento open-source technology
                                                                                        (CE) with our deep technical
                                                                                        expertise to provide merchants
                                                                                        full control over their online
                                                                                        store operations. Our Magento
                                                                                        Ecommerce Development expertise
                                                                                        includes –</p>
                                                                                    <div className="sign-box">
                                                                                        <ul>
                                                                                            <li>Custom Theme
                                                                                                Designing &amp; Premium
                                                                                                Template Integration
                                                                                            </li>
                                                                                            <li>PSD/HTML to Magento
                                                                                                theme conversion
                                                                                            </li>
                                                                                            <li>Skinning &amp; XHTML/CSS
                                                                                                enhancements
                                                                                            </li>
                                                                                            <li>Extensions Development
                                                                                            </li>
                                                                                            <li>Magento SEO</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="wpb_column vc_column_container vc_col-sm-5">
                                                                    <div className="vc_column-inner ">
                                                                        <div className="wpb_wrapper">
                                                                            <hr className="vertical-space1"/>
                                                                            <div
                                                                                className="wpb_single_image wpb_content_element vc_align_left">
                                                                                <figure
                                                                                    className="wpb_wrapper vc_figure">
                                                                                    <div
                                                                                        className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                        <img width={333} height={391}
                                                                                             src={m1}
                                                                                             className="vc_single_image-img attachment-full"
                                                                                             alt=""
                                                                                             sizes="(max-width: 333px) 100vw, 333px"/>
                                                                                    </div>
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_tta-panel" id="wordpress"
                                                         data-vc-content=".vc_tta-panel-body">
                                                        <div className="vc_tta-panel-heading"><h4
                                                            className="vc_tta-panel-title"><a href="#wordpress"
                                                                                              data-vc-accordion
                                                                                              data-vc-container=".vc_tta-container"><span
                                                            className="vc_tta-title-text">Wordpress</span></a></h4>
                                                        </div>
                                                        <div className="vc_tta-panel-body">
                                                            <div id="wordpress"
                                                                 className="vc_row wpb_row vc_inner vc_row-fluid">
                                                                <div
                                                                    className="wpb_column vc_column_container vc_col-sm-7">
                                                                    <div className="vc_column-inner ">
                                                                        <div className="wpb_wrapper">
                                                                            <hr className="vertical-space1"/>
                                                                            <div
                                                                                className="wpb_text_column wpb_content_element ">
                                                                                <div className="wpb_wrapper">
                                                                                    <h3>OsCommerce
                                                                                        Development &amp; Customization</h3>
                                                                                    <p>Our expert osCommerce developers
                                                                                        carry years of hands-on
                                                                                        experience in putting together a
                                                                                        feature-rich online store at
                                                                                        competitive pricing. We
                                                                                        specialize in –</p>
                                                                                    <div className="sign-box">
                                                                                        <ul>
                                                                                            <li>Custom Template
                                                                                                Designing
                                                                                            </li>
                                                                                            <li>Module integration</li>
                                                                                            <li>Site maintenance</li>
                                                                                            <li>Product Catalogue
                                                                                                Design &amp; admin panel
                                                                                                customization
                                                                                            </li>
                                                                                            <li>Shipping &amp; Payment
                                                                                                Gateway configuration
                                                                                            </li>
                                                                                            <li>Tracking order status
                                                                                                and managing the whole
                                                                                                inventory.
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="wpb_column vc_column_container vc_col-sm-5">
                                                                    <div className="vc_column-inner ">
                                                                        <div className="wpb_wrapper">
                                                                            <hr className="vertical-space1"/>
                                                                            <div
                                                                                className="wpb_single_image wpb_content_element vc_align_left">
                                                                                <figure
                                                                                    className="wpb_wrapper vc_figure">
                                                                                    <div
                                                                                        className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                        <img width={333} height={391}
                                                                                             src={m2}
                                                                                             className="vc_single_image-img attachment-full"
                                                                                             alt=""
                                                                                             sizes="(max-width: 333px) 100vw, 333px"/>
                                                                                    </div>
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_tta-panel" id="node_js"
                                                         data-vc-content=".vc_tta-panel-body">
                                                        <div className="vc_tta-panel-heading"><h4
                                                            className="vc_tta-panel-title"><a href="#node_js"
                                                                                              data-vc-accordion
                                                                                              data-vc-container=".vc_tta-container"><span
                                                            className="vc_tta-title-text">Node js</span></a></h4></div>
                                                        <div className="vc_tta-panel-body">
                                                            <div id="node_js"
                                                                 className="vc_row wpb_row vc_inner vc_row-fluid">
                                                                <div
                                                                    className="wpb_column vc_column_container vc_col-sm-7">
                                                                    <div className="vc_column-inner ">
                                                                        <div className="wpb_wrapper">
                                                                            <hr className="vertical-space1"/>
                                                                            <div
                                                                                className="wpb_text_column wpb_content_element ">
                                                                                <div className="wpb_wrapper">
                                                                                    <h3>Drupal Commerce Development –
                                                                                        Ubercart</h3>
                                                                                    <p>If you need an online shop that
                                                                                        succeeds both
                                                                                        products &amp; site content with
                                                                                        utmost ease, Drupal commerce is
                                                                                        perfect for you. Over the years,
                                                                                        we have delivered some of the
                                                                                        most stable, flexible &amp; fast
                                                                                        web stores using Ubercart
                                                                                        platform that offer
                                                                                        enterprise-level
                                                                                        scalability.</p>
                                                                                    <div className="sign-box">
                                                                                        <ul>
                                                                                            <li>Drupal commerce theme
                                                                                                design &amp; development
                                                                                            </li>
                                                                                            <li>Module Integration</li>
                                                                                            <li>Advanced customer
                                                                                                management
                                                                                            </li>
                                                                                            <li>Integration of payment
                                                                                                process &amp; 3rd party
                                                                                                external systems
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="wpb_column vc_column_container vc_col-sm-5">
                                                                    <div className="vc_column-inner ">
                                                                        <div className="wpb_wrapper">
                                                                            <hr className="vertical-space1"/>
                                                                            <div
                                                                                className="wpb_single_image wpb_content_element vc_align_left">
                                                                                <figure
                                                                                    className="wpb_wrapper vc_figure">
                                                                                    <div
                                                                                        className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                        <img width={333} height={391}
                                                                                             src={m3}
                                                                                             className="vc_single_image-img attachment-full"
                                                                                             alt=""
                                                                                             sizes="(max-width: 333px) 100vw, 333px"/>
                                                                                    </div>
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_tta-panel" id="woocommerce"
                                                         data-vc-content=".vc_tta-panel-body">
                                                        <div className="vc_tta-panel-heading"><h4
                                                            className="vc_tta-panel-title"><a href="#woocommerce"
                                                                                              data-vc-accordion
                                                                                              data-vc-container=".vc_tta-container"><span
                                                            className="vc_tta-title-text">Woocommerce</span></a></h4>
                                                        </div>
                                                        <div className="vc_tta-panel-body">
                                                            <div id="woocommerce"
                                                                 className="vc_row wpb_row vc_inner vc_row-fluid">
                                                                <div
                                                                    className="wpb_column vc_column_container vc_col-sm-7">
                                                                    <div className="vc_column-inner ">
                                                                        <div className="wpb_wrapper">
                                                                            <hr className="vertical-space1"/>
                                                                            <div
                                                                                className="wpb_text_column wpb_content_element ">
                                                                                <div className="wpb_wrapper">
                                                                                    <h3>Woocommerce Development</h3>
                                                                                    <p>We are dedicated to implement and
                                                                                        customize the Woocommerce
                                                                                        platform to build easy-to-use
                                                                                        online stores for you.</p>
                                                                                    <div className="sign-box">
                                                                                        <ul>
                                                                                            <li>Custom Woocommerce theme
                                                                                                design
                                                                                            </li>
                                                                                            <li>Convert PSDs to
                                                                                                Woocommerce templates
                                                                                            </li>
                                                                                            <li>Extensions
                                                                                                Development &amp; Pre-built
                                                                                                module integration
                                                                                            </li>
                                                                                            <li>Migration of online shop
                                                                                                from any other ecommerce
                                                                                                platform
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="wpb_column vc_column_container vc_col-sm-5">
                                                                    <div className="vc_column-inner ">
                                                                        <div className="wpb_wrapper">
                                                                            <hr className="vertical-space1"/>
                                                                            <div
                                                                                className="wpb_single_image wpb_content_element vc_align_left">
                                                                                <figure
                                                                                    className="wpb_wrapper vc_figure">
                                                                                    <div
                                                                                        className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                        <img width={372} height={221}
                                                                                             src={m4}
                                                                                             className="vc_single_image-img attachment-full"
                                                                                             alt=""
                                                                                             sizes="(max-width: 372px) 100vw, 372px"/>
                                                                                    </div>
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_tta-panel" id="react_js"
                                                         data-vc-content=".vc_tta-panel-body">
                                                        <div className="vc_tta-panel-heading"><h4
                                                            className="vc_tta-panel-title"><a href="#react_js"
                                                                                              data-vc-accordion
                                                                                              data-vc-container=".vc_tta-container"><span
                                                            className="vc_tta-title-text">React js</span></a></h4></div>
                                                        <div className="vc_tta-panel-body">
                                                            <div id="react_js"
                                                                 className="vc_row wpb_row vc_inner vc_row-fluid">
                                                                <div
                                                                    className="wpb_column vc_column_container vc_col-sm-7">
                                                                    <div className="vc_column-inner ">
                                                                        <div className="wpb_wrapper">
                                                                            <hr className="vertical-space1"/>
                                                                            <div
                                                                                className="wpb_text_column wpb_content_element ">
                                                                                <div className="wpb_wrapper">
                                                                                    <h3>Joomla VirtueMart
                                                                                        Development</h3>
                                                                                    <p>VirtueMart is an expansion of
                                                                                        Joomla CMS which is best suited
                                                                                        for eCommerce websites targeting
                                                                                        low to medium level traffic. Our
                                                                                        team specializes in</p>
                                                                                    <div className="sign-box">
                                                                                        <ul>
                                                                                            <li>Custom Joomla
                                                                                                programming,
                                                                                                front &amp; back end
                                                                                                implementation
                                                                                            </li>
                                                                                            <li>Site
                                                                                                management &amp; maintenance
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="wpb_column vc_column_container vc_col-sm-5">
                                                                    <div className="vc_column-inner ">
                                                                        <div className="wpb_wrapper">
                                                                            <hr className="vertical-space1"/>
                                                                            <div
                                                                                className="wpb_single_image wpb_content_element vc_align_left">
                                                                                <figure
                                                                                    className="wpb_wrapper vc_figure">
                                                                                    <div
                                                                                        className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                        <img width={333} height={391}
                                                                                             src={m5}
                                                                                             className="vc_single_image-img attachment-full"
                                                                                             alt=""
                                                                                             sizes="(max-width: 333px) 100vw, 333px"/>
                                                                                    </div>
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="vertical-space2"/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="blox   full-container   w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                backgroundSize: 'cover',
                minHeight: 'px',
                backgroundColor: '#113685'
            }}>
                <div className="max-overlay" style={{backgroundColor: ''}}/>
                <div className="wpb_row vc_row-fluid full-row">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space2"/>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-8">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h3 className="part1"
                                                                style={{textAlign: 'right', color: 'white'}}>For
                                                                Dedicated Ecommerce Development Services</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text_centers wpb_column vc_column_container vc_col-sm-4">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_btn3-container  button_11 vc_btn3-inline">
                                                        <a style={{backgroundColor: '#4975dd', color: '#ffffff'}}
                                                           className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-custom"
                                                           href="/service/dedicatedResource/hireDedicatedResource" title>Hire
                                                            Developer</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="vertical-space1"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x"/>
            </section>
            <section className="blox      w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                backgroundSize: 'cover',
                minHeight: 'px',
                backgroundColor: '#fafafb'
            }}>
                <div className="max-overlay" style={{backgroundColor: ''}}/>
                <div className="wpb_row vc_row-fluid full-row">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space1"/>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div
                                            className="image_spacing_section wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <p><img className="aligncenter"
                                                                    src={img1} alt=""/>
                                                            </p>
                                                            <h4 style={{color: '#424b59'}}><strong>Magento Enterprise
                                                                eCommerce Development Solution</strong></h4>
                                                            <p>We are proud to enjoy the status of a leading Magento
                                                                Enterprise Consultancy of India with a clear vision of
                                                                delivering scalable &amp; flexible eCommerce website
                                                                designs to overseas retailers and brands. Our Magento
                                                                team consists of skilled PHP developers, theme
                                                                integration experts &amp; cloud specialists having
                                                                profound knowledge of in tweaking online stores for
                                                                speed, performance, load balances, &amp; security.<br/>
                                                                <img className="aligncenter"
                                                                     src={img2} alt=""/>
                                                            </p>
                                                            <h4 style={{color: '#424b59'}}><strong>Our Magento
                                                                Expertise</strong></h4>
                                                            <ul className="col-left">
                                                                <li>Performance Tuning</li>
                                                                <li>Theme Design for eCommerce websites and
                                                                    Customization
                                                                </li>
                                                                <li>Premium Template Integration</li>
                                                                <li>CMS Extension Development</li>
                                                                <li>ERP &amp; 3rd party API Integration</li>
                                                            </ul>
                                                            <p><img className="aligncenter"
                                                                    src={img3} alt=""/>
                                                            </p>
                                                            <h4 style={{color: '#424b59'}}><strong>Custom Ecommerce
                                                                Development</strong></h4>
                                                            <p>Do you have a unique idea and worried that open source
                                                                eCommerce applications may not justify your requirement.
                                                                BVM-Infotech has a great team of custom e-commerce
                                                                developers who can build a fully custom e-commerce
                                                                website from Scratch as unique as your idea. Contact us
                                                                for high end, scalable and flexible custom eCommerce
                                                                website.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="image_spacing_section wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <p><img className="aligncenter"
                                                                    src={img4} alt=""/>
                                                            </p>
                                                            <h4 style={{color: '#424b59'}}><strong>Software-as-a-Service
                                                                (SAAS) eCommerce – Solutions – Hosted Shopping
                                                                Carts</strong></h4>
                                                            <p>If you plan to run a small online shop within an
                                                                affordable low-cost investment by opting for a
                                                                dependable eCommerce web development company, you can go
                                                                with our SAAS – a cloud-based transfer model where your
                                                                online store is managed &amp; hosted by your service
                                                                provider and you need to pay them on subscription basis.<br/>
                                                                <img className="aligncenter"
                                                                     src={img5} alt=""/>
                                                            </p>
                                                            <h4 style={{color: '#424b59'}}><strong>Shopify eCommerce
                                                                Development</strong></h4>
                                                            <p>Being a reputed eCommerce website development agency of
                                                                India, we have a team of extremely talented eCommerce
                                                                programmers in India who have experience in doing custom
                                                                Shopify development, implementation &amp; customization
                                                                work. We can also take your PSD designs &amp; convert it
                                                                into Shopify templates or skins using our in-depth
                                                                expertise in LIQUID programming (a language specific to
                                                                Shopify platform). Our eCommerce consultants also offer
                                                                store-redesigning &amp; migration services for
                                                                Shopify.<br/>
                                                                <img className="aligncenter"
                                                                     src={img6} alt=""/>
                                                            </p>
                                                            <h4 style={{color: '#424b59'}}><strong>Bigcommerce</strong>
                                                            </h4>
                                                            <p>We design a fully customized eCommerce website on
                                                                Bigcommerce to meet your exclusive needs. Our
                                                                Bigcommerce API addition experts follow streamlined
                                                                processes to create custom store designs, integrate
                                                                plugins, drive traffic, and improve code
                                                                structure &amp; site performance. If you already run an
                                                                online shop &amp; want to migrate it to Bigcommerce via
                                                                a reputed eCommerce development agency India, hire our
                                                                team of skilled eCommerce developers to get a profitable
                                                                mobile-ready website.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="vertical-space3"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space3"/>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h2 style={{textAlign: 'center'}}>10 BUSINESS BENEFITS OF OUR&nbsp;<strong>ECOMMERCE
                                                APPLICATION DEVELOPMENT SERVICES</strong></h2>
                                        </div>
                                    </div>
                                    <hr className="vertical-space1"/>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2"><img
                                                                    src={img7}
                                                                    alt="superior quality design"/></div>
                                                                <div className="col-md-10">
                                                                    <h5 style={{color: '#424b59'}}><strong>OUR ECOMMERCE
                                                                        CONSULTANTS PROVIDE PROFESSIONAL
                                                                        ASSISTANCE</strong></h5>
                                                                    <p>Our eCommerce consultants analyze every technical
                                                                        aspect to suggest perfect eCommerce web
                                                                        development solution suiting to your needs, help
                                                                        launch your online store &amp; guide how to
                                                                        promote it &amp; increase sales – everything
                                                                        within your defined budget.</p>
                                                                </div>
                                                            </div>
                                                            <p>&nbsp;</p>
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2"><img
                                                                    src={img8}
                                                                    alt="technical support"/></div>
                                                                <div className="col-md-10">
                                                                    <h5 style={{color: '#424b59'}}><strong>WE INTEGRATE
                                                                        GORGEOUS ECOMMERCE THEMES OF YOUR
                                                                        CHOICE</strong></h5>
                                                                    <p>Looks matter. If you choose our eCommerce website
                                                                        development services, you can either get
                                                                        unique &amp; beautiful eCommerce templates
                                                                        specifically designed for your business or
                                                                        choose from premium templates as per your
                                                                        requirements.</p>
                                                                </div>
                                                            </div>
                                                            <p>&nbsp;</p>
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2"><img
                                                                    src={img9}
                                                                    alt="version updates"/></div>
                                                                <div className="col-md-10">
                                                                    <h5 style={{color: '#424b59'}}><strong>YOU CAN
                                                                        MANAGE YOUR ONLINE STORE WITH EASE</strong></h5>
                                                                    <p>If you choose to take eCommerce website designing
                                                                        services from us, you can manage everything on
                                                                        your own from images &amp; videos to product
                                                                        descriptions, pricing, inventory, content,
                                                                        marketing &amp; more with a user-friendly CMS
                                                                        that we deliver with every eCommerce store.</p>
                                                                </div>
                                                            </div>
                                                            <p>&nbsp;</p>
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2"><img
                                                                    src={img10}
                                                                    alt="version updates"/></div>
                                                                <div className="col-md-10">
                                                                    <h5 style={{color: '#424b59'}}><strong>START
                                                                        ACCEPTING PAYMENTS ONLINE VIA SECURE PAYMENT
                                                                        GATEWAY</strong></h5>
                                                                    <p>We integrate payment gateways of all major
                                                                        service providers like EBS, CCAvenue, ICICI and
                                                                        HDFC so that your online store can accept credit
                                                                        cards, debit cards &amp; netbanking payments
                                                                        without any hassle.</p>
                                                                </div>
                                                            </div>
                                                            <p>&nbsp;</p>
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2"><img
                                                                    src={img11}
                                                                    alt="version updates"/></div>
                                                                <div className="col-md-10">
                                                                    <h5 style={{color: '#424b59'}}><strong>WE CREATE
                                                                        EASY POINTS OF CONTACT</strong></h5>
                                                                    <p>To boost engagement, we ensure your customers
                                                                        stick around by integrating strong
                                                                        calls-to-action; perfect social media signals
                                                                        and conversion optimized storefront designs for
                                                                        eCommerce website.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2"><img
                                                                    src={img12}
                                                                    alt="unique functionality"/></div>
                                                                <div className="col-md-10">
                                                                    <h5 style={{color: '#424b59'}}><strong>WE SIMPLIFY
                                                                        THE CHECKOUT PROCESS TO IMPROVE SALES</strong>
                                                                    </h5>
                                                                    <p>We make checkout a breeze for your customers with
                                                                        just a 3-4 step process, easy-to-fill-out fields
                                                                        and removing all the unnecessary clutter.</p>
                                                                </div>
                                                            </div>
                                                            <p>&nbsp;</p>
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2"><img
                                                                    src={img13}
                                                                    alt="updates"/></div>
                                                                <div className="col-md-10">
                                                                    <h5 style={{color: '#424b59'}}><strong>WE MAKE YOUR
                                                                        STORE VISIBLE ON SEARCH ENGINES TO BRING
                                                                        TRAFFIC</strong></h5>
                                                                    <p>With on-site optimization and SEO-friendly
                                                                        architecture, our online stores are
                                                                        easy-to-be-found on search engines &amp; organic
                                                                        traffic starts pouring in as soon as you go
                                                                        live.</p>
                                                                </div>
                                                            </div>
                                                            <p>&nbsp;</p>
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2"><img
                                                                    src={img14}
                                                                    alt="mobile optimised UI"/></div>
                                                                <div className="col-md-10">
                                                                    <h5 style={{color: '#424b59'}}><strong>WE KEEP YOUR
                                                                        STORE UP 100% OF THE TIME WITH 24 X 7 TECHNICAL
                                                                        SUPPORT</strong></h5>
                                                                    <p>We know that your online store needs to sell
                                                                        round the clock &amp; so being a trusted
                                                                        eCommerce web design company of India; we
                                                                        provide technical support to all our clients
                                                                        even in the oddest hours.</p>
                                                                </div>
                                                            </div>
                                                            <p>&nbsp;</p>
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2"><img
                                                                    src={img15}
                                                                    alt="mobile optimised UI"/></div>
                                                                <div className="col-md-10">
                                                                    <h5 style={{color: '#424b59'}}><strong>SELL
                                                                        ON-THE-GO : ANYTIME &amp; ANYWHERE VIA YOUR
                                                                        MOBILE-OPTIMIZED STORE</strong></h5>
                                                                    <p>We give you a mobile-ready shop with a responsive
                                                                        UI so that you don’t lose sales from your mobile
                                                                        traffic.</p>
                                                                </div>
                                                            </div>
                                                            <p>&nbsp;</p>
                                                            <div className="Wpsite-box">
                                                                <div className="col-md-2"><img
                                                                    src={img16}
                                                                    alt="mobile optimised UI"/></div>
                                                                <div className="col-md-10">
                                                                    <h5 style={{color: '#424b59'}}><strong>NOT JUST AN
                                                                        ECOMMERCE WEBSITE, YOU GET A PERSONALIZED ONLINE
                                                                        PRESENCE</strong></h5>
                                                                    <p>When compared with other reputed eCommerce web
                                                                        development companies over the internet, our
                                                                        eCommerce web design services are focused to
                                                                        help you sell more. You get the most effective
                                                                        online selling tool fully loaded with advanced
                                                                        features, rich shopping cart design, awesome
                                                                        functionality &amp; integrated social media
                                                                        signals.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="vertical-space1"/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </div>
        </div>
    )
};

export default ECommerceDevelopment;