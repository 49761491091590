import axios from "axios"
import {apiURL} from "../config/index";

/* ============================
    News Server Api Helper
 ============================ */

export const  getBlogPost = async () => {
  let result = {}
  try {
    const res = await axios.get(`${apiURL}/blogPost`)
    result = res.data || {}
  } catch (err) {
    console.log("error in getting entities : ", err)
  }
  return result
};

export const  getBlogPostById = async (id) => {
  let result = {}
  try {
    const res = await axios.get(`${apiURL}/blogPost/${id}`)
    result = res.data || {}
  } catch (err) {
    console.log("error in getting entities : ", err)
  }
  return result
};

