import React from 'react';
import img1 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object-2.jpg"
import img2 from "../../Assets/wp-content/uploads/2017/07/responsive-website01.png"
import img3 from "../../Assets/wp-content/uploads/2017/07/responsive01.png"
import img4 from "../../Assets/wp-content/uploads/2017/07/how-can-responsive-web-design.png"
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'

const Responsive_Mobile = () => {
    window.scroll(0,0)
    return (
        <div className="bvm">
        <div id="wrap" className="">
            <section id="main-content" className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-2">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper"/>
                            </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-8">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper"><br/>
                                            <h1 className="banner-heading"
                                                style={{textAlign: 'center', color: '#113685'}}><strong>Responsive
                                                Website Design</strong></h1>
                                            <p style={{
                                                textAlign: 'center',
                                                color: '#55636c',
                                                fontSize: '17px',
                                                lineHeight: '1.7'
                                            }}>Make your website perform its best on all devices</p>
                                        </div>
                                    </div>
                                    <div className="wpb_single_image wpb_content_element vc_align_center">
                                        <figure className="wpb_wrapper vc_figure">
                                            <div className="vc_single_image-wrapper   vc_box_border_grey"><img
                                                width={751} height={308}
                                                src={img1}
                                                className="vc_single_image-img attachment-full" alt=""
                                                sizes="(max-width: 751px) 100vw, 751px"/></div>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-2">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper"/>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="blox      w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                backgroundSize: 'cover',
                minHeight: 'px',
                backgroundColor: '#113685'
            }}>
                <div className="max-overlay" style={{backgroundColor: ''}}/>
                <div className="wpb_row vc_row-fluid full-row">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space1"/>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h2 className="headeing-sub" style={{color: 'white'}}>83% Of Mobile Users
                                                Say That A Seamless Experience Across All Devices Is Very Important. Get
                                                Ready For A Multi Device World.</h2>
                                        </div>
                                    </div>
                                    <hr className="vertical-space1"/>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-3">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper"/>
                                            </div>
                                        </div>
                                        <div className="button_alignment wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_btn3-container  button11  vc_btn3-inline">
                                                        <a style={{backgroundColor: '#4975dd', color: '#ffffff'}}
                                                           className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-custom"
                                                           href="/service/dedicatedResource/hireDedicatedResource" title>Hire
                                                            Dedicated Designer </a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-3">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner vc_custom_1503128105192">
                                                <div className="wpb_wrapper">
                                                    <hr className="vertical-space1"/>
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center   image-hide">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={443} height={254}
                                                                     src={img2}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 443px) 100vw, 443px"/></div>
                                                        </figure>
                                                    </div>
                                                    <hr className="vertical-space1"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <hr className="vertical-space2"/>
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <p>The explosion of electronic gadget releases and its use
                                                                has highly affected the website design. Now a day more
                                                                people used to use Mobile or Tablet to browse the
                                                                internet than their desktop. To address this shift of
                                                                internet usage, you should upgrade your traditional
                                                                website with fluid responsive website design.</p>
                                                        </div>
                                                    </div>
                                                    <hr className="vertical-space2"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row  outline">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_row wpb_row vc_inner vc_row-fluid important vc_custom_1572066018287">
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <p className="p-content">A responsive website is enough
                                                                capable to adapt to the device being used to browse the
                                                                site. Most of the websites were structured to be viewed
                                                                on a full-screen desktop or laptop and if it is being
                                                                accessed through smaller screens like smartphones, some
                                                                parts of the page are cut off or forcing the viewers to
                                                                scroll. This can be annoying, especially to the viewers
                                                                who have very little time to spare. The solution to this
                                                                setback is by designing a website with fluid responsive
                                                                so that can be conveniently displayed on smaller screens
                                                                same like a widescreen monitor.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_left   image-hide">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={443} height={304}
                                                                     src={img3}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 443px) 100vw, 443px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey  space-creator">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h4 className="custom-h1">How Can Responsive Web Design Enhance Your
                                                Business</h4>
                                            <p style={{fontSize: '15px'}}>In traditional marketing, for significant
                                                impact, businesses would launch advertising campaigns that will reach
                                                the maximum number of people. The principle stays true to the internet
                                                marketing. Building a website is fundamentally aimed at making an online
                                                presence. As more people are locating the net using a variety of
                                                gadgets, it is only sensible that you adjust to the trend of internet
                                                usage.</p>
                                        </div>
                                    </div>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h5 style={{color: '#171c24'}}><strong>Here’s how a
                                                                responsive website design can help your
                                                                business:</strong></h5>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow"><p/>
                                                        <ul className="col-left arrow_content"
                                                            style={{fontSize: '17px'}}>
                                                            <li><strong style={{color: '#171c24'}}>Concentration on one
                                                                website</strong>&nbsp;–&nbsp;Initially, mobile websites
                                                                were suggested to capture Smartphone users. That means
                                                                that you build a website especially for small screen
                                                                viewers. This is a modified version of the mainstream
                                                                website. The creative Responsive website designers
                                                                can give you with a viewer friendly, easy to navigate
                                                                website designed.
                                                            </li>
                                                            <li><strong style={{color: '#171c24'}}>Extensive Captured
                                                                Audience</strong>&nbsp;– Mobile online users do not have
                                                                much bearing for hard to use websites, they will just
                                                                simply find another site that is easier to use and
                                                                viewer friendly.
                                                            </li>
                                                            <li><strong style={{color: '#171c24'}}>Maximize Online
                                                                Presence</strong>&nbsp;– A well-designed and
                                                                well-marketed website will not operate with mobile users
                                                                without a responsive design. Traffic will be high, so as
                                                                your bounce charge.
                                                            </li>
                                                            <li><strong style={{color: '#171c24'}}>No Duplicate
                                                                Content</strong>&nbsp;– Building various websites to
                                                                accommodate various devices could also mean duplicated
                                                                content. To conclude this, you will need to come up with
                                                                different sets of content which can confuse your
                                                                viewers.
                                                            </li>
                                                            <li><strong style={{color: '#171c24'}}>Lower
                                                                Costs</strong>&nbsp;– You only require to market,
                                                                maintain and support one website that will work for all
                                                                your customers, effectively cutting down on your
                                                                operating costs.
                                                            </li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_single_image wpb_content_element vc_align_left">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={539} height={323}
                                                                     src={img4}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 539px) 100vw, 539px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <div id="wpcont13-outer" className="clearfix flex-bdr">
                                                <div className="container clearfix">
                                                    <p>Stop wasting your time and money building a website that can’t be
                                                        properly viewed by most of your customers that are on mobile.
                                                        Get ahead in the competition with a responsive web design;&nbsp;
                                                        <a href="/Contact">contact us</a>&nbsp;to know
                                                        how we can help you with this.</p>
                                                </div>
                                            </div>
                                            <div className="clear"/>
                                            <div id="letstalk-wrap" className="clearfix"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </div>
        </div>
    )
};

export default Responsive_Mobile;