import React, {useEffect, useState} from "react"
import {getBlogPostById,getBlogPost} from "../globalutilities/helpers";
import {isBrowser, isMobile} from "react-device-detect";
import { useHistory } from "react-router-dom";
import * as Spinner from "react-spinkit";

const BlogPage = (props) =>{

    let history = useHistory();

    const[blogData,setBlogData] = useState({})
    const[relatedData,setRelatedData] = useState([])
    const[loading,setLoading] = useState(true)

    useEffect(() => {
        getBlogPosts()
        getRelatedPosts()
    }, [props.match.params.id])

    const getBlogPosts = async () => {
        setLoading(true)
        const response = await getBlogPostById(props.match.params.id);
        if (response && response.payload) {
            response.payload.imgUrl = response.payload.imgUrl.split("(")[1].split(")")[0]
            setBlogData(response.payload)
            setLoading(false)
        }
    };

    const getRelatedPosts = async () => {
        setLoading(true)
        const response = await getBlogPost(``);
        if (response && response.payload) {
           const data = response.payload.filter(item =>(
                item._id !== props.match.params.id
           ))
            setRelatedData(data)
            setLoading(false)
        }
    };

    const blog = async (index) => {
        history.push(`/blogpage/${index}`);
    }

    return(
        <>
            <div>
                {loading ? (
                    <div style={{ position: "fixed", top: "0", left: "0",right:"0",bottom:"0", height: "100%", width: "100%", backgroundColor: "#ffffff", opacity: 0.6, zIndex: 1000 }}>
                        <div style={{ position: "absolute", textAlign: "center", top: "47%", left: "47%", zIndex: 1001 }}>
                            <Spinner name="three-bounce" />
                        </div>
                    </div>
                ) : null}
            <h3 className="BlogTitle" style={{textAlign:"center",marginTop:20,color:"white"}}>{blogData.title}</h3>
            {isMobile &&
                <div className="responsive">
                    <img  src={blogData.imgUrl}  style={{height: 500,width:1903,position:"relative"}} className="headerImage1" alt="" />
                </div>}
            {isBrowser &&
                <div className="responsive">
                    <img  src={blogData.imgUrl}  style={{height: 500,width:1903,position:"relative"}} className="headerImage1" alt="" />
                </div>}
            <div className="container">
            <span dangerouslySetInnerHTML={{__html: blogData.description || ""}} />
            </div>
            </div>

            <div className='bvm'>
                <div className="site-section bg-light" >
                    <div className="container">
                        <div>
                            <h2 style={{textAlign:"center"}}>Articles</h2>
                        </div>
                        <div className="row align-items-stretch retro-layout-2">
                            {
                                relatedData.map((item, index) =>
                                    <div className="col-md-4">
                                        <div href="single.html" className="h-entry mb-30 v-height gradient"
                                             onClick={()=>blog(item._id)}
                                             style={{backgroundImage: `${item.imgUrl}`}}>
                                            <div className="text">
                                                <h2>{item.title}</h2>
                                                <span className="date">{item.Date}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
}

export default BlogPage