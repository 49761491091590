import React from 'react';
import client1 from "../Assets/images/client/skuchain.png";
import client2 from "../Assets/images/client/shopelect_logo.png";
import client3 from "../Assets/images/client/Screenshot (2).png";
import client4 from "../Assets/images/client/kudoo-min.png";
import client5 from "../Assets/images/client/love intently.png";
import client6 from "../Assets/images/client/abuchat.png";
import client7 from "../Assets/images/client/politiq-min.png";
import client8 from "../Assets/images/client/option-min.png";
import client9 from "../Assets/images/client/muni.png";
import client10 from "../Assets/images/client/message.jpg";
import client11 from "../Assets/images/client/luci.png";
import client12 from "../Assets/images/client/kendall.png";
import client13 from "../Assets/images/client/innroad-min.jpg";
import client14 from "../Assets/images/client/averpoint.png";
import client15 from "../Assets/images/client/debuity.gif";
import client16 from "../Assets/images/client/south gas.png";

const Logo = () => {
    return (
        <div className="flat-row clients pad-top20px pad-bottom50px">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="flat-clients  pad-top20px owl-carousel owl-theme owl-loaded" data-item={6}
                             data-nav="false" data-dots="false" data-auto="true">
                            <div className="owl-stage-outer">
                                <div className="owl-stage" style={{
                                    transform: 'translate3d(-2600px, 0px, 0px)',
                                    transition: 'all 0.25s ease 0s',
                                    width: '6800px'
                                }}>
                                    <div className="owl-item cloned" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client1} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item cloned" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client2} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item cloned" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client3} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item cloned" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client4} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item cloned" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client5} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item cloned" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client6} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client7} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client8} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client9} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client10} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client11} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client12} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client13} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item active" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client14} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item active" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client15} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item active" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client16} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item active" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client1} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item active" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client2} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item active" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client3} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item active" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client4} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client5} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client6} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client7} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client8} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client9} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client10} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client11} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client12} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client13} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item cloned" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client14} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item cloned" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client15} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item cloned" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client16} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item cloned" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client1} alt="images"/>
                                        </div>
                                    </div>
                                    <div className="owl-item cloned" style={{width: '170px', marginRight: '30px'}}>
                                        <div className="client-featured">
                                            <img src={client2} alt="images"/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="owl-controls">
                                <div className="owl-nav">
                                    <div className="owl-prev" style={{display: 'none'}}>prev</div>
                                    <div className="owl-next" style={{display: 'none'}}>next</div>
                                </div>
                                <div className="owl-dots" style={{display: 'none'}}/>
                            </div>
                        </div>
                        {/* /.flat-clients */}
                    </div>
                    {/* /.col-md-12 */}
                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </div>
    )
};

export default Logo;