import React from 'react';
import ColorFill11 from '../../Assets/wp-content/uploads/2017/07/Color-Fill-11.png'
import pic1 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object-1.png"
import pic2 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object1-1.png"
import pic3 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object-copy.png"
import pic4 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object3-1.png"
import pic5 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object4.png"
import pic6 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object2-1.png"
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'

const WebDevelopment = () => {
    window.scroll(0,0)
    return (
        <div className="bvm">
        <div id="wrap" >
            <section id="main-content" className="container">
                <div className="row-wrapper-x">
                    <p/></div>
            </section>
            <section className="blox full-container   w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                background: `url(${ColorFill11})`,
                backgroundPosition: 'center top',
                backgroundSize: 'cover',
                minHeight: '320px',
                textAlign: 'center'
            }}>
                <div className="max-overlay" style={{backgroundColor: ''}}/>
                <div className="wpb_row vc_row-fluid full-row">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space2"/>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h1 className="cust-title-h2" style={{color: 'white'}}><strong>Customer
                                                focused solutions built on effective programming concepts</strong></h1>
                                            <p className="cust-sub-p" style={{color: 'white'}}>The perfect blend of
                                                imagination &amp; functionality</p>
                                        </div>
                                    </div>
                                    <hr className="vertical-space1"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space2"/>
                                    <div
                                        className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-equal-height vc_row-flex">
                                        <div className="image_center wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center wpb_animate_1 wpb_fadeInLeft fadeInLeft  img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={428} height={280} src={pic1}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 428px) 100vw, 428px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="web cust-1"><strong>Front-End
                                                                Development</strong></h4>
                                                            <h5 className="cust-1-1">Accessibility, Agreement,
                                                                Navigability &amp; Usefulness delivered to leave a
                                                                strong impression</h5>
                                                            <p>Great web behavior needs highly competent front-end
                                                                implementation. Our team of master
                                                                    developers can transpose your cutting-edge
                                                                graphic designs to clean W3C validated mark-up that
                                                                turns out to be the best possible development solution
                                                                for our clients.</p>
                                                            <h5 className="cust-1-1">Front-end Development areas we are
                                                                expert in:</h5>
                                                            <div className="sign-box">
                                                                <ul>
                                                                    <li>Deeply functional interactive websites with
                                                                        user-friendly interface &amp; smooth navigation
                                                                    </li>
                                                                    <li>Mobile optimized responsive web development</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                            style={{borderColor: '#a2a2a2'}} className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span style={{borderColor: '#a2a2a2'}}
                                                                                        className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row  flex_section w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-equal-height vc_row-flex">
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="web cust-1"><strong>Custom Web
                                                                Development</strong></h4>
                                                            <h5 className="cust-1-1">Comprehensively customised web
                                                                solutions for your organization’s different web
                                                                requirements</h5>
                                                            <p>We train in executing custom web developing projects with
                                                                100% accuracy &amp; perfectly matching to your business
                                                                requirements. Our team is capable at using cutting-edge
                                                                technologies to customize everything from the
                                                                look &amp; feel of front-end to expert back-end
                                                                programming that results in a highly dynamic, fully
                                                                functional &amp; interactive web solution.</p>
                                                            <h5 className="cust-1-1">Custom Web Development areas we are
                                                                expert in:</h5>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow"><p/>
                                                        <ul style={{fontSize: '15px'}}>
                                                            <li>Community driven sites</li>
                                                            <li>Complex e-commerce</li>
                                                            <li>Complex informational sites</li>
                                                            <li>Multi-functional web portals</li>
                                                            <li>Membership/Subscription sites</li>
                                                            <li>Complex web applications</li>
                                                            <li>Social Networking Applications</li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="image_center wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_1 wpb_fadeInRight fadeInRight  img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={440} height={422} src={pic2}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 440px) 100vw, 440px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                            style={{borderColor: '#a2a2a2'}} className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span style={{borderColor: '#a2a2a2'}}
                                                                                        className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1563602521627 vc_row-o-equal-height vc_row-flex">
                                        <div className="image_center wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center   img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={445} height={343} src={pic3}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 445px) 100vw, 445px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="web cust-1"><strong>Open Source Platform
                                                                Development</strong></h4>
                                                            <h5 className="cust-1-1">Deploying off-the-shelf development
                                                                solutions to save time &amp; money</h5>
                                                            <p>We offer custom open source development services
                                                                including content management solutions to ensure
                                                                inherent flexibility &amp; fast turnaround at an
                                                                extremely affordable cost.</p>
                                                            <h5 className="cust-1-1">Open Source Platforms we are expert
                                                                in:</h5>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow"><p/>
                                                        <ul style={{fontSize: '15px'}}>
                                                            <li><a>WordPress</a>&nbsp;—
                                                                Highly customizable CMS &amp; blogging engine
                                                            </li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                            style={{borderColor: '#a2a2a2'}} className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span style={{borderColor: '#a2a2a2'}}
                                                                                        className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row  flex_section w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-equal-height vc_row-flex">
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="web cust-1"><strong>E-commerce
                                                                development</strong></h4>
                                                            <h5 className="cust-1-1">Excellent e-commerce solutions to
                                                                give your customers the best shopping experience
                                                                <br/>
                                                                We build websites that monetize your products in an
                                                                efficient way by giving the biggest market exposure to
                                                                your business. From a beautiful user interface to easily
                                                                manageable admin panel; integration of best payment
                                                                gateway to ensuring highly secure transactions; we
                                                                promise a 100% effective,
                                                                fully-featured &amp; flexible<a>ecommerce
                                                                    solution</a>&nbsp;to expand your customer base.</h5>
                                                            <h5 className="cust-1-1">E-commerce Development Areas we are
                                                                expert in:</h5>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow"><p/>
                                                        <ul style={{fontSize: '15px'}}>
                                                            <li>Magento – Magento Go, Magento Community, Magento
                                                                Enterprise
                                                            </li>
                                                            <li>Shopify</li>
                                                            <li>Woocommerce</li>
                                                            <li>WordPress</li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="image_center wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center   img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={466} height={307} src={pic4}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 466px) 100vw, 466px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                            style={{borderColor: '#a2a2a2'}} className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span style={{borderColor: '#a2a2a2'}}
                                                                                        className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1563602552363 vc_row-o-equal-height vc_row-flex">
                                        <div className="image_center wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center   img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={402} height={362} src={pic5}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 402px) 100vw, 402px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="web cust-1"><strong>Mobile
                                                                Development</strong></h4>
                                                            <p>With more &amp; more web traffic arriving via mobile
                                                                devices, we offer multiple solutions ranging from&nbsp;
                                                                 responsive web design &nbsp;to mobile only
                                                                templates &amp; native mobile applications.</p>
                                                            <h5 className="cust-1-1">Mobile Development Areas we are
                                                                expert in:</h5>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow"><p/>
                                                        <ul style={{fontSize: '15px'}}>
                                                            <li>Responsive Website Development</li>
                                                            <li>Mobile Specific Website Development</li>
                                                            <li>Windows Mobile App Development</li>
                                                            <li>iOS Mobile App Development</li>
                                                            <li>Blackberry Mobile App Development</li>
                                                            <li>Android Mobile App Development</li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                            style={{borderColor: '#a2a2a2'}} className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span style={{borderColor: '#a2a2a2'}}
                                                                                        className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row  flex_section w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-equal-height vc_row-flex">
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="web cust-1"><strong>Web Application
                                                                Frameworks</strong></h4>
                                                            <p>We select an appropriate programming structure based on
                                                                client requirements &amp; develop everything keeping in
                                                                mind a successful web presence comprising robust
                                                                architecture, maximum versatility &amp; best scope for
                                                                future growth. Using object-oriented best practices,
                                                                comprehensive code base &amp; coding guidelines, our
                                                                team offers highly customizable solutions to meet the
                                                                requirements of our clients.</p>
                                                            <h5 className="cust-1-1">Web Application Frameworks we are
                                                                expert in:</h5>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow"><p/>
                                                        <ul style={{fontSize: '15px'}}>
                                                            <li>Laravel PHP Framework</li>
                                                            <li>CodeIgniter PHP Framework</li>
                                                            <li>Zend PHP Framework</li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="image_center wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center   img-hidden">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={453} height={448} src={pic6}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 453px) 100vw, 453px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span
                                            style={{borderColor: '#a2a2a2'}} className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span style={{borderColor: '#a2a2a2'}}
                                                                                        className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h3><strong>Why to opt for our Web Development
                                                                Services</strong></h3>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow2"><p/>
                                                        <ul className="col-left" style={{fontSize: '17px'}}>
                                                            <li>Technically strong team of 100+ domain experts carrying
                                                                10+ yrs of experience
                                                            </li>
                                                            <li>Adherence to best coding guidelines &amp; quality
                                                                standards
                                                            </li>
                                                            <li>Clean W3C validated code cooperative with multiple
                                                                platforms &amp; browsers
                                                            </li>
                                                            <li>Highly maintainable website structure</li>
                                                            <li>Responsive user-friendly interfaces</li>
                                                            <li>Performance, load &amp; stress testing before delivery
                                                            </li>
                                                            <li>Up-to-date techniques implemented using latest
                                                                technologies
                                                            </li>
                                                            <li>Completely SEO optimized designs to boost rankings</li>
                                                            <li>Considerably less maintenance work</li>
                                                            <li>High-end security against malicious practices</li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h3><strong>What would you get</strong></h3>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow2"><p/>
                                                        <ul className="col-left" style={{fontSize: '17px'}}>
                                                            <li>Complete Functional &amp; highly usable web applications
                                                                matching to your business needs,
                                                                requirements &amp; expectations
                                                            </li>
                                                            <li>Full control &amp; full transparency of the development
                                                                process backed with seamless communication
                                                            </li>
                                                            <li>Smart &amp; Open application structure allowing for high
                                                                solution productivity &amp; scalability
                                                            </li>
                                                            <li>Great application maintainability enabling to reduce the
                                                                total cost of ownership
                                                            </li>
                                                            <li>Mature QA systems throughout the entire software
                                                                development life cycle
                                                            </li>
                                                            <li>Deployment, Stabilization &amp; Open-ended
                                                                support &amp; maintenance of the delivered application
                                                            </li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </div>
        </div>
    )
};

export default WebDevelopment;