import React from 'react';
import img1 from "../../Assets/wp-content/uploads/2017/07/Layer-43.png"
import img2 from "../../Assets/wp-content/uploads/2017/07/user-centric-websites-customized.png"
import img3 from "../../Assets/wp-content/uploads/2017/07/corporate-web-design-services-offer.jpg"
import img4 from "../../Assets/wp-content/uploads/2017/07/outsource-web-design-services.png"
import img5 from "../../Assets/wp-content/uploads/2017/07/why-choose-tis-india.png"
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'

const CustomWebDesign = () => {
    window.scroll(0,0)
    return (
        <div className="bvm">
        <div id="wrap" className="">
            <section id="main-content" className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper"><br/>
                                            <h1 className="heading-h1" style={{textAlign: 'center', color: '#113685'}}>
                                                <strong>Custom Web Designing Services</strong></h1>
                                            <p className="sub-heading"
                                               style={{color: '#55636c', textAlign: 'center'}}>Every website design
                                                should be unique and no one size fits all.</p>
                                        </div>
                                    </div>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-2">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper"/>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-8">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={810} height={212}
                                                                     src={img1}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 810px) 100vw, 810px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-2">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="blox   full-container   w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                backgroundSize: 'cover',
                minHeight: 'px',
                backgroundColor: '#113685'
            }}>
                <div className="max-overlay"/>
                <div className="wpb_row vc_row-fluid full-row">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <hr className="vertical-space1"/>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h2 className="inner-heading"
                                                style={{textAlign: 'center', color: 'white'}}>Get Your Website
                                                Customized To Your Business</h2>
                                        </div>
                                    </div>
                                    <hr className="vertical-space1"/>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-3">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper"/>
                                            </div>
                                        </div>
                                        <div className="Buttone_aligment wpb_column vc_column_container vc_col-sm-6">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_btn3-container vc_btn3-inline">
                                                        <a style={{backgroundColor: '#4975dd', color: '#ffffff'}}
                                                           className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-custom"
                                                           href="/service/dedicatedResource/hireDedicatedResource" title>Hire
                                                            Dedicated Designer </a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-3">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row  top w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_left   img-hiddan">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={539} height={482}
                                                                     src={img2}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 539px) 100vw, 539px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <hr className="vertical-space1"/>
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="heading-h4"><strong>User Centric Websites
                                                                Customized to Your Business</strong></h4>
                                                            <p>We are trained in designing creative user centric
                                                                websites customized to your business
                                                                goals &amp; targeted niche.</p>
                                                            <p>Our productive team of web
                                                                designing experts to combine technical proficiency
                                                                with creativity to create something extraordinary. We
                                                                understand websites make businesses, so should be
                                                                planned as per your business objectives and customer’s
                                                                psyche. So, we create websites that ‘attracts’ and
                                                                ‘sells’ too.</p>
                                                            <p>At BVM-Infotech, we do great Requirement analysis
                                                                before initiating any web design project, so that you
                                                                get as and what has been desired. We not just produce a
                                                                web design service but deliver 100% satisfied customized
                                                                web design that will be an industry example and outdo
                                                                your competitors.</p>
                                                            <p>As an approved Website Design
                                                                India Center, we have more than 6 years of extensive
                                                                experience in designing custom websites for every
                                                                industry sector including Corporate, Retail, E-commerce,
                                                                Health, Travel, IT, Entertainment, Squeeze pages,
                                                                Education, etc. With more than 300 satisfied clients
                                                                worldwide and 550 + websites produced, we are proud to
                                                                be the no.1 choice of offshore clients globally for
                                                                outsourcing web design services.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row  hr-row w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row  midal-row w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-equal-height vc_row-flex">
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="heading-h4"><strong>Custom website designs:
                                                                what we offer at BVM-Infotech</strong></h4>
                                                            <p>Websites besides having a great look and feel should also
                                                                be Usable, user friendly, business-centric and seek
                                                                engine friendly too. We concentrate on designing
                                                                interactive &amp; retailing websites at best prices in
                                                                the industry. Its more further just a design, at
                                                                BVM-Infotech creativity and usability go hand in
                                                                hand.</p>
                                                            <div className="sign-box">
                                                                <h5>Our custom web designs include:</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow"><p/>
                                                        <ul>
                                                            <li>Business focused presentation</li>
                                                            <li>Higher usability with a good Screen Real Estate</li>
                                                            <li>Best color designs as per your business
                                                                theme &amp; targeted niche
                                                            </li>
                                                            <li>Industry based theme</li>
                                                            <li>Ongoing support, updates &amp; revisions</li>
                                                            <li>W3C validation</li>
                                                            <li>Fast loading for the best user experience</li>
                                                            <li>Search engine friendly</li>
                                                            <li>Clean &amp; user friendly navigation</li>
                                                            <li>Web 2.0 standard</li>
                                                            <li>Imely delivery</li>
                                                            <li>Cost effective solutions – we deliver best quote</li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="image_center wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_left   img-hiddan">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={539} height={426}
                                                                     src={img3}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 539px) 100vw, 539px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <p>We love what we do !! Outsource your custom web design or website
                                                redesign services to us and see the difference in the contact your
                                                website makes on your clients, partners &amp; competitors.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row  hr-row w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div
                                        className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                        <span className="vc_sep_holder vc_sep_holder_l"><span className="vc_sep_line"/></span><span
                                        className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center   img-hiddan">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={535} height={353}
                                                                     src={img4}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 535px) 100vw, 535px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h4 className="heading-h4"><strong>Outsource web design
                                                                services: Turn visitors into customers</strong></h4>
                                                            <p>Not many website layout company offer the level of custom
                                                                website plan service that we guarantee to offer. We have
                                                                a team of trained web designers who work with you to
                                                                initially analyze your business needs, give you
                                                                practical advice and guidance, and then deliver the best
                                                                solutions according to your needs. Perhaps during our
                                                                analyzing method, we run through a set of questionnaires
                                                                to fully understand your company and target customers as
                                                                well. We then put together a website redesign plan which
                                                                usually includes-</p>
                                                            <div className="sign-box col_new1 clm1">
                                                                <ul className="arrow">
                                                                    <li>Wireframe layout of the new look.</li>
                                                                    <li>Outline of the user journey route that you want
                                                                        your online visitants to take while navigating
                                                                        your website
                                                                    </li>
                                                                    <li>A list of services that you never thought you
                                                                        would ever need, but is important for the
                                                                        success of your website.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <p>Our designing is the branding process for your company and can be
                                                considered as the most important step in being successful online.
                                                Generally, custom web design resolutions differ a lot in terms of
                                                complexity and functionality and so we offer a standard solution which
                                                is very flexible yet competitive depending on your individual
                                                requirements.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="wpb_row   w-animate full-row">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                            <div
                                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                <span className="vc_sep_holder vc_sep_holder_l"><span
                                    className="vc_sep_line"/></span><span
                                className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h2 className="heading-h111" style={{textAlign: 'center'}}><strong>Why
                                                Choose BVM-Infotech</strong></h2>
                                        </div>
                                    </div>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <ul>
                                                                <li>Manpower- Our team of website creators are the most
                                                                    creative and talented people in the industry. Each
                                                                    of our website designs is guaranteed 100% unique for
                                                                    each website we create. At BVM-Infotech every
                                                                    custom website creating is based around structured
                                                                    layouts that are independently designed to make sure
                                                                    that no two website designs look similar.
                                                                </li>
                                                                <li>Extensive Expertise- With our extensive experience
                                                                    in analyzing the client needs and offering brilliant
                                                                    web designs, we as one of the leading professional
                                                                    web design companies even ensure that every single
                                                                    capital each client invest in custom website design
                                                                    service is spent properly. Today we even enjoy a
                                                                    powerful design &amp; marketing background.
                                                                </li>
                                                                <li>Comfort Guaranteed- In the last few years we have
                                                                    set new benchmarks and our web design portfolio is
                                                                    the best paradigm to prove our excellence. We are a
                                                                    customer-driven web layout company and ensure every
                                                                    website is custom made to fit your business. We
                                                                    never stop until our clients are 100% satisfied and
                                                                    are always available for any questions and updating
                                                                    required even after the site is completed.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_single_image wpb_content_element vc_align_left">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={535} height={447}
                                                                     src={img5}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 535px) 100vw, 535px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <p>We offer web design service that fundamentally aims to turn website
                                                visitors into customers. We are one of the few leading web creating
                                                companies that enjoys years of experience in this field and know the
                                                true potential of having a website that features a one of its kind of
                                                look.</p>
                                            <p>&nbsp;</p>
                                            <p>So whether you need help getting started with a new website or you need
                                                to enhance or customize your existing website, BVM-Infotech could
                                                be the best web layout company you can approach.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </div>
        </div>
    )
};

export default CustomWebDesign;