import React from 'react';
import pic1 from '../../Assets/images/ReactJS-images/react-native-logo.png'
import pic2 from '../../Assets/images/ReactJS-images/reactjs_img1.png'
import pic3 from '../../Assets/images/ReactJS-images/angularjs_img2.png'
import pic4 from '../../Assets/images/ReactJS-images/React.png'
import pic5 from '../../Assets/images/ReactJS-images/custom-plugins.png'
import pic6 from '../../Assets/images/ReactJS-images/seamless-migration.png'
import pic7 from '../../Assets/images/ReactJS-images/maintenance.png'
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'

const ReactJSDevelopment = () => {
    window.scroll(0,0)
    return (
        <>
            <div className="bvm">
            <div>
                <section id="main-content" className="container">
                    <div className="row-wrapper-x">
                        <section className="wpb_row   w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-8">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element  vc_custom_1573187804981">
                                            <div className="wpb_wrapper"><br/>
                                                <h2 style={{color: '#113685'}}><strong>React
                                                    Js&nbsp;Development</strong></h2>
                                            </div>
                                        </div>
                                        <div
                                            className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_20 vc_sep_border_width_2 vc_sep_pos_align_left vc_separator_no_text">
                                            <span className="vc_sep_holder vc_sep_holder_l"><span
                                                style={{borderColor: '#113685'}} className="vc_sep_line"/></span><span
                                            className="vc_sep_holder vc_sep_holder_r"><span
                                            style={{borderColor: '#113685'}} className="vc_sep_line"/></span>
                                        </div>
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                                <div className="intricate">
                                                    <h2>Build intricate interfaces with reusable elements of ReactJS for
                                                        speedy app development</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container vc_col-sm-4">
                                <div className="vc_column-inner vc_custom_1570682517718">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_single_image wpb_content_element vc_align_left">
                                            <figure className="wpb_wrapper vc_figure">
                                                <div className="vc_single_image-wrapper   vc_box_border_grey"><img
                                                    width={225} height={200} src={pic1}
                                                    className="vc_single_image-img attachment-full" alt=""/></div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="blox      w-animate " style={{
                    paddingTop: 'px',
                    paddingBottom: 'px',
                    backgroundSize: 'cover',
                    minHeight: 'px',
                    backgroundColor: '#f9f9f9'
                }}>
                    <div className="max-overlay" style={{backgroundColor: ''}}/>
                    <div className="wpb_row vc_row-fluid full-row">
                        <div className="container">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner vc_custom_1570682775068">
                                    <div className="wpb_wrapper">
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <p>ReactJS is a popular front-end Javascript framework
                                                                    which helps in developing considerably massive
                                                                    applications with data that deviates over a period
                                                                    of time. It was developed by Facebook to advocate
                                                                    the best practices around the creation of
                                                                    application blueprint. With the optimal use of the
                                                                    functional and reactive programming, it offers
                                                                    coherence, ease, and modularity for the developer,
                                                                    as well as, hassle-free and dynamic experience for
                                                                    the users.</p>
                                                                <p>ReactJS enables the development of isomorphic web
                                                                    applications, which confirms high speed and also
                                                                    surges the overall efficiency of the application.
                                                                    Being counted as a trusted name in ReactJS
                                                                    application development company in India, we cater
                                                                    to the business requirements of the organizations
                                                                    across different industry verticals including retail
                                                                    and e-commerce, media and entertainment, logistics
                                                                    and transport, travel and tourism, healthcare,
                                                                    banking and finance, and automotive, to name a
                                                                    few.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div
                                                            className="wpb_single_image wpb_content_element vc_align_left">
                                                            <figure className="wpb_wrapper vc_figure">
                                                                <div
                                                                    className="vc_single_image-wrapper   vc_box_border_grey">
                                                                    <img width={500} height={500} src={pic2}
                                                                         className="vc_single_image-img attachment-full"
                                                                         alt=""
                                                                         sizes="(max-width: 500px) 100vw, 500px"/></div>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container">
                    <div className="row-wrapper-x"/>
                </section>
                <section className="blox padding_bottom w-animate " style={{
                    paddingTop: 'px',
                    paddingBottom: 'px',
                    background: 'url("../wp-content/uploads/2019/10/factBg.jpg") no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    minHeight: 'px'
                }}>
                    <div className="max-overlay" style={{backgroundColor: ''}}/>
                    <div className="wpb_row vc_row-fluid full-row">
                        <div className="container">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner vc_custom_1570682775068">
                                    <div className="wpb_wrapper">
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid flex_section">
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div
                                                            className="wpb_single_image wpb_content_element vc_align_left">
                                                            <figure className="wpb_wrapper vc_figure">
                                                                <div
                                                                    className="vc_single_image-wrapper   vc_box_border_grey">
                                                                    <img width={500} height={500} src={pic3}
                                                                         className="vc_single_image-img attachment-full"
                                                                         alt=""
                                                                         sizes="(max-width: 500px) 100vw, 500px"/></div>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div className="wpb_text_column wpb_content_element ">
                                                            <div className="wpb_wrapper">
                                                                <ul>
                                                                    <li><strong>Effective and workable
                                                                        code:</strong> ReactJS uses the one-way flow of
                                                                        data which allows the developers to interchange
                                                                        and reutilize the components already written,
                                                                        thereby decreasing the probabilities of rework
                                                                        and leading to faster development and fewer
                                                                        defects.
                                                                    </li>
                                                                    <li><strong>Prompt retorts to the end-users
                                                                        input:</strong> ReactJS ensures better
                                                                        performance and improves the user experience for
                                                                        your website. This is achieved by eliminating
                                                                        redundant work from the browser and carrying out
                                                                        updates only when actual modifications are
                                                                        executed.
                                                                    </li>
                                                                    <li><strong>SEO friendly:</strong> Unlike other
                                                                        Javascript frameworks, ReactJS has built-in
                                                                        support for dispensing complete dynamic pages to
                                                                        search engines, which means improvement in
                                                                        overall page rankings and navigates potential
                                                                        visitors to the page they wish to go through.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container">
                    <div className="row-wrapper-x">
                        <section className="wpb_row   w-animate">
                            <div className="margin_top wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element  vc_custom_1581328209457">
                                            <div className="wpb_wrapper">
                                                <h2 style={{textAlign: 'center'}}>Our scope of ReactJS development
                                                    services</h2>
                                                <p style={{textAlign: 'center'}}>BVM-Infotech is a leading ReactJS
                                                    development company that endeavours to capitalize on the
                                                    capabilities of the trendy Javascript open source library, such as
                                                    seamless scalability and imperfection forbearance to develop web
                                                    projects of varying intricacy levels. Our scope of ReactJS
                                                    development services mainly comprises of:</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="wpb_row  react_js_content w-animate">
                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                                <div className="row">
                                                    <div className="col-sm-2 col-md-2"><img
                                                        src={pic4} alt="React"/>
                                                    </div>
                                                    <div className="col-sm-8 col-md-8">
                                                        <div className="titles">Personalized ReactJS Development</div>
                                                        <div className="desc">Our experienced ReactJS developers can
                                                            help you build stunning and interactive UIs for applications
                                                            and potent front-ends that you and your potential customers
                                                            can appreciate.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-2 col-md-2"><img
                                                        src={pic5}
                                                        alt="React"/></div>
                                                    <div className="col-sm-8 col-md-8">
                                                        <div className="titles">ReactJS Plugin Development</div>
                                                        <div className="desc">BVM Infotech has got an expertise in
                                                            delivering inimitable features and functionality using
                                                            custom plugins created in ReactJS. This, in turn, can be
                                                            reused across application helping your business maximize
                                                            your return on investment by cutting short development time.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                                <div className="row">
                                                    <div className="col-sm-2 col-md-2"><img
                                                        src={pic6}
                                                        alt="React"/></div>
                                                    <div className="col-sm-8 col-md-8">
                                                        <div className="titles">Seamless Migration</div>
                                                        <div className="desc">With our business-centric approach, we
                                                            welcome to understand your unique business requirements and
                                                            existing setup to simplify and expedite the migration
                                                            process to the new ReactJS framework for web application
                                                            built on older technologies.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-2 col-md-2"><img
                                                        src={pic7}
                                                        alt="React"/></div>
                                                    <div className="col-sm-8 col-md-8">
                                                        <div className="titles">Support and Maintenance</div>
                                                        <div className="desc">Our seasoned team of highly experienced
                                                            ReactJS developers is just a call away to provide you
                                                            dedicated and round-the-clock SLA based support and
                                                            maintenance services, even after the completion of the
                                                            development process.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </div>
            </div>
        </>
    )
};

export default ReactJSDevelopment;