import React,{useState,useEffect} from 'react';
import {useHistory } from "react-router-dom";
import company from "../Assets/images/download.png";
import logo1 from '../Assets/images/logo/android.png'
import logo2 from '../Assets/images/logo/wp.png'
import logo3 from '../Assets/images/logo/angular.png'
import logo4 from '../Assets/images/logo/firebase.png'
import logo5 from '../Assets/images/logo/javascript.png'
import logo7 from '../Assets/images/logo/node.png'
import logo8 from '../Assets/images/logo/vue.png'
import logo9 from '../Assets/images/logo/react.png'
import logo10 from '../Assets/images/logo/net.png'
import '../App.css'

const Nav = () => {
    let history = useHistory();
    const [open, setOpen] = useState(true);
    const path=history.location.pathname;

    useEffect(() => {
        console.log("after:-", history.location.pathname);
        setOpen(() => false);
    }, [history.location.pathname]);

    useEffect(() => {
        setTimeout(() => { setOpen(true)}, 1500)
    }, [open]);

    const HoverClose = (path) =>{
        console.log("before:-", history.location.pathname);
        setOpen(false);
        //history.push(path);
        window.location.pathname = path
    };
     return(
        <div id="site-header">
            <header id="header" className="header clearfix">
                <div className="header-wrap clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="flat-wrapper">
                                <div className="sm-nav-header">
                                    <div id="logo" className="">
                                        <a href="/home">
                                            <img src={company} className="company-logo" alt="images"/>
                                        </a>
                                    </div>
                                    <div className="btn-menu">
                                        <span/>
                                    </div>
                                </div>
                                <div className="nav-wrap">
                                    <nav id="mainnav" className="mainnav">
                                        <ul className="menu">
                                            <li className={`home ${path==="/home"&&"active"}`}><a href="/home">Home</a></li>
                                            <li className={`${open ? "has-mega-menu" : ""} ${path.split("/").includes("service")&&"active"}` }>
                                                <a href="#" className="has-mega">Services</a>
                                                <div className="submenu mega-menu menu2">
                                                    <div className="row">
                                                        <div className="col-md-3 col-sm-3">
                                                            <div className="entry-cover">
                                                                <a href="#" className='Hover'>
                                                                    <span><b className="serviceHeaderFont">FRONT END</b></span>
                                                                    <hr style={{marginRight: 100}}/>
                                                                </a>
                                                            </div>
                                                            <div className="text-left">
                                                            <div className={`pointer ${path==="/service/frontEnd/webDesign"&&"pointer-active"}`} onClick={()=>HoverClose("/service/frontEnd/webDesign")}> Web Design</div>
                                                            <div className={`pointer ${path==="/service/frontEnd/responsive_mobile_website"&&"pointer-active"}`} onClick={()=>HoverClose("/service/frontEnd/responsive_mobile_website")} >Responsive + Mobile Websites</div>
                                                            <div className={`pointer ${path==="/service/frontEnd/graphicDesign"&&"pointer-active"}`} onClick={()=>HoverClose("/service/frontEnd/graphicDesign")} >Graphic Design</div>
                                                                <div className={`pointer ${path==="/service/frontEnd/customWebDesign"&&"pointer-active"}`} onClick={()=>HoverClose("/service/frontEnd/customWebDesign")} >Custom web design</div></div><br/>
                                                         </div>
                                                        <div className="col-md-4 col-sm-4">
                                                            <div className="entry-cover">
                                                                <a href="#" className='Hover'>
                                                                    <span><b className="serviceHeaderFont">WEB & APP DEVELOPMENT</b></span>
                                                                    <hr style={{marginRight: 100}}/>
                                                                </a>
                                                            </div>
                                                            <div className="text-left">
                                                             <div><span className={`pointer ${path==="/service/web&app_Development/webDevelopment"&&"pointer-active"}`} onClick={()=>HoverClose("/service/web&app_Development/webDevelopment")} >Web Development</span></div>
                                                             <div><span className={`pointer ${path==="/service/web&app_Development/phpDevelopment"&&"pointer-active"}`} onClick={()=>HoverClose("/service/web&app_Development/phpDevelopment")} >PHP Development</span></div>
                                                             <div><span className={`pointer ${path==="/service/web&app_Development/wordPressDevelopment"&&"pointer-active"}`} onClick={()=>HoverClose("/service/web&app_Development/wordPressDevelopment")} >WordPress Development</span></div>
                                                             <div><span className={`pointer ${path==="/service/web&app_Development/eCommerceDevelopment"&&"pointer-active"}`} onClick={()=>HoverClose("/service/web&app_Development/eCommerceDevelopment")} >eCommerce Development</span></div>
                                                             <div><span className={`pointer ${path==="/service/web&app_Development/iPhoneAppDevelopment"&&"pointer-active"}`} onClick={()=>HoverClose("/service/web&app_Development/iPhoneAppDevelopment")} >Iphone App Development</span></div>
                                                             <div><span className={`pointer ${path==="/service/web&app_Development/angularJSDevelopment"&&"pointer-active"}`} onClick={()=>HoverClose("/service/web&app_Development/angularJSDevelopment")} >AngularJS Development</span></div>
                                                             <div><span className={`pointer ${path==="/service/web&app_Development/fullStackDevelopment"&&"pointer-active"}`} onClick={()=>HoverClose("/service/web&app_Development/fullStackDevelopment")} >Full Stack development</span></div>
                                                             <div><span className={`pointer ${path==="/service/web&app_Development/reactJSDevelopment"&&"pointer-active"}`} onClick={()=>HoverClose("/service/web&app_Development/reactJSDevelopment")} >React Js Development</span></div>
                                                             <div><span className={`pointer ${path==="/service/web&app_Development/vueJSDevelopment"&&"pointer-active"}`} onClick={()=>HoverClose("/service/web&app_Development/vueJSDevelopment")} >Vue Js Development</span></div>
                                                             <div><span className={`pointer ${path==="/service/web&app_Development/androidDevelopment"&&"pointer-active"}`} onClick={()=>HoverClose("/service/web&app_Development/androidDevelopment")} >Android Development</span></div>
                                                             <div><span className={`pointer ${path==="/service/web&app_Development/.netDevelopment"&&"pointer-active"}`} onClick={()=>HoverClose("/service/web&app_Development/.netDevelopment")} >.net Development</span></div>
                                                                <div><span className={`pointer ${path==="/service/web&app_Development/reactNativeDevelopment"&&"pointer-active"}`} onClick={()=>HoverClose("/service/web&app_Development/reactNativeDevelopment")} >React Native Development</span></div></div><br/>
                                                        </div>
                                                        <div className="col-md-3 col-sm-3">
                                                            <div className="entry-cover">
                                                                <a className='Hover' href="#">
                                                                    <span><b className="serviceHeaderFont">DEDICATED RESOURCE</b></span>
                                                                    <hr style={{marginRight: 15}}/>
                                                                </a>
                                                            </div>
                                                            <div className="text-left">
                                                              <div><span className={`pointer ${path==="/service/dedicatedResource/hireDedicatedResource"&&"pointer-active"}`} onClick={()=>HoverClose("/service/dedicatedResource/hireDedicatedResource")} >Hire Dedicated Resources</span></div>
                                                            </div>
                                                         </div>
                                                    </div>

                                                    <hr style={{marginRight: 35 ,marginTop: 30}}/>

                                                    <div className="col-md-12 col-sm-12" style={{marginBottom: 15, marginLeft: -64}}>
                                                        <div>
                                                            <ul style={{display: 'inline', marginLeft: '15px'}}><a href="#"><img width="65px" src={logo1} className="servicesLogo" /></a></ul>
                                                            <ul style={{display: 'inline', marginLeft: '15px'}}><a href="#"><img width="65px" src={logo2} className="servicesLogo"/></a></ul>
                                                            <ul style={{display: 'inline', marginLeft: '15px'}}><a href="#"><img width="65px" src={logo3} className="servicesLogo"/></a></ul>
                                                            <ul style={{display: 'inline', marginLeft: '15px'}}><a href="#"><img width="65px" src={logo4} className="servicesLogo"/></a></ul>
                                                            <ul style={{display: 'inline', marginLeft: '15px'}}><a href="#"><img width="65px" src={logo5} className="servicesLogo"/></a></ul>
                                                            <ul style={{display: 'inline', marginLeft: '15px'}}><a href="#"><img width="65px" src={logo7} className="servicesLogo"/></a></ul>
                                                            <ul style={{display: 'inline', marginLeft: '15px'}}><a href="#"><img width="65px" src={logo8} className="servicesLogo"/></a></ul>
                                                            <ul style={{display: 'inline', marginLeft: '15px'}}><a href="#"><img width="65px" src={logo9} className="servicesLogo"/></a></ul>
                                                            <ul style={{display: 'inline', marginLeft: '15px'}}><a href="#"><img width="65px" src={logo10} className="servicesLogo"/></a></ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className={`${path==="/portFolio"&&"active"}`}><a href="/portFolio">Portfolio</a></li>
                                            {/*<li className={`${path==="/blog"&&"active"}`}><a href="/blog">Blog</a></li>*/}
                                            <li className={`${path==="/about"&&"active"}`}><a href="/about">About</a></li>
                                            {/*<li className={`${path==="/career"&&"active"}`}><a href="/career">Careers</a></li>*/}
                                            <li className={`${path==="/contact"&&"active"}`}><a href="/contact">Contact</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
};

export default Nav;