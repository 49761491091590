import React from "react";

const Footer = () => {
  const date = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="footer-widgets">
        <div className="container" style={{ marginTop: "1%" }}>
          <div className="row">
            <div className="col-md-5 col-sm-4 col-md-offset-2">
              <div className="widget widget_text information style_1">
                <h3 className="widget-title">
                  <span className="style_1">C</span>ontact Us
                </h3>
                <div className="textwidget">
                  <p></p>
                  <p className="family">
                    <i className="fa fa-phone ft-widget-margin-right-12" />
                    +91-8758607151
                  </p>
                  <i className="fa fa-envelope-o ft-widget-margin-right-10" />
                  info@bvminfotech.com
                  <p />
                </div>
              </div>
            </div>
            <div className="col-md-5 col-sm-4">
              <div className="widget widget_text style_1">
                <div className="textwidget">
                  <div id="myModal" className="modal fade " role="dialog">
                    <div className="modal-dialog pad-mar20px">
                      <div className="modal-content ">
                        <div className="modal-header"></div>
                        <div className="modal-body">
                          <form
                            id="contactform"
                            method="post"
                            action="https://bvminfotech.co.in/contact/contact-process.php"
                          >
                            <p>
                              <input
                                name="name"
                                type="text"
                                id="cname"
                                defaultValue
                                placeholder="Name"
                                required="required"
                              />
                            </p>
                            <p>
                              <input
                                name="email"
                                id="cmail"
                                type="email"
                                defaultValue
                                placeholder="Email"
                                required="required"
                              />
                            </p>
                            <p>
                              <textarea
                                name="message"
                                placeholder="Comment"
                                required="required"
                                id="cmsg"
                                defaultValue={""}
                              />
                            </p>
                            <button className="btn btn-primary">Submit</button>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary close-btn rounded submit"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-content">
        <div className="container">
          <div className="row">
            <div className="flat-wrapper">
              <div className="ft-wrap clearfix">
                <div className="copyright">
                  <div className="copyright-content">
                    Copyright © {date} BVM Infotech
                    <a href="/home" target="_blank" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
