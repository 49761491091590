import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {isBrowser, isMobile} from "react-device-detect";
import pic2 from "../Assets/images/slides/2-min.jpg";
import pic3 from "../Assets/images/slides/3-min.jpg";
import pic1 from "../Assets/images/slides/1-min.jpg";
import pic4 from "../Assets/images/slides/4-min.jpg";
import pic5 from "../Assets/images/slides/5-min.jpg";
import pic6 from "../Assets/images/slides/6-min.jpg";
import '../App.css'

const Header = () => {
    const settings = {
        draggable: true,
        autoplay: true, /* this is the new line */
        autoplaySpeed: 3000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 1000,
    };
    return(
        <div className="container1">
        <header>
            {isMobile &&
            <Slider {...settings} className="slider">
                <div className="responsive">
                    <img  src={pic4}  style={{height: 500,width:1903}} className="headerImage1" alt="" />
                </div>
                <div className="responsive">
                    <img src={pic5}  style={{height: 500,width:1903}} className="headerImage2" alt=""/>
                </div>
                <div className="responsive headerImage3">
                    <img src={pic6}  style={{height: 500,width:1903}} className="headerImage3" alt=""/>
                </div>
            </Slider>}
            {isBrowser &&
            <Slider {...settings} className="slider">
                <div className="responsive">
                    <img  src={pic1}  style={{height: 500,width:1903}} className="headerImage1" alt="" />
                </div>
                <div className="responsive">
                    <img src={pic2}  style={{height: 500,width:1903}} className="headerImage2" alt=""/>
                </div>
                <div className="responsive headerImage3">
                    <img src={pic3}  style={{height: 500,width:1903}} className="headerImage3" alt=""/>
                </div>
            </Slider>}

        </header>
        </div>
    )
};

export default Header;