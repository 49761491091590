import React from 'react';

const About = () => {
    return (
        <div className="flat-row pad-top90px">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h3> We&nbsp;are BVM Infotech.<br/>Evolving since 2017.</h3>
                    </div>
                    <div className="col-md-6">
                        <h4>About Us</h4>
                        <p className="text-justify">BVM-Infotech is a leading Website Development company in Surat,India. We are providing Website Design & Development Services, ERP Solution,
                            HRM Solution, CRM Solution, Desktop Application Development, Web Portal Development, E-commerce Website
                            Development and Mobile Application
                            Development. A tour Surat office in India, we are having a team of passionate website designer and developers who have innovative skills and great understanding
                            of how to make world class websites. They are always up to date of the latest digital trends and keep understanding of basic web development concepts which
                            produce a successful website.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default About;