import React,{useEffect,useState} from 'react';
import axios from "axios";
import moment from "moment";
import {apiURL} from "../config"
import * as Spinner from 'react-spinkit';

const Career = () => {

    const [list , setList] = useState([]);
    const [loading,setLoading] = useState(true)

    useEffect (() => {
            getCategory()
    },[]);

     const  getCategory = async (query) => {
         setLoading(true);
        let result = {};
        try {
            const res = await axios.get(`${apiURL}/carrier${query || ""}`)
            if(res && res.data && res.data.payload){
                setList(res.data.payload)
                setLoading(false)
            }
        } catch (err) {
            console.log("error in getting entities : ", err)
            setLoading(false)
        }
        return result
    };

    return(
        <div className="boxed">
            {loading ? (
                <div style={{ position: "fixed", top: "0", left: "0",right:"0",bottom:"0", height: "100%", width: "100%", backgroundColor: "#ffffff", opacity: 0.6, zIndex: 1000 }}>
                    <div style={{ position: "absolute", textAlign: "center", top: "47%", left: "47%", zIndex: 1001 }}>
                        <Spinner name="three-bounce" />
                    </div>
                </div>
            ) : null}
            <div id="site-content">
                <div id="page-body">
                    <div className="flat-row pad-top60px pad-bottom60px parallax">
                        <div className="container">
                            <div className="row">
                                <h1 className="values text-center">Open Positions</h1>
                            </div>
                            {
                                list.map(v => (
                                    <>
                                        <div className="row">
                                            <div className="card carrierHeight" style={{overflowY: "scroll",overflowX: "hidden"}}>
                                                <h5>Job Role: {v.jobRole}</h5>
                                                <div className="column col-lg-6">
                                                    <div className="container">
                                                        <strong>Job Description:</strong>
                                                        <ol className="c">
                                                            <li dangerouslySetInnerHTML={{__html: v.jobDesciption}} />
                                                            {/*<li>{v.jobDesciption}</li>*/}
                                                        </ol>
                                                        <br/>
                                                        <strong>Technical Skills::</strong>
                                                        <p>
                                                            {
                                                                v.Skills.map((skill,index) => (
                                                                    <div>{
                                                                        skill.concat(v.Skills.length !== index+1 ? ',' :  '')
                                                                    }
                                                                    </div>
                                                                ))
                                                            }
                                                        </p>
                                                        <strong>Experience:</strong><p>{v.Experience}</p>
                                                    </div>
                                                </div>
                                                <div className="column col-lg-6">
                                                    <div className="container">
                                                        <strong>Job Location:</strong><p>{v.city}</p>
                                                        <strong>Job Posted Date:</strong><p>{moment(v.Date).format("DD MMM , YYYY")}</p>
                                                        <strong>Contact Email:</strong><p>{v.email}</p>
                                                        <strong>Contact Phone:</strong><p
                                                        className="family">{v.phone}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flat-divider d40px"/>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Career;