import React from 'react';
import img1 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object1.png"
import img2 from "../../Assets/wp-content/uploads/2017/07/Vector-Smart-Object2.png"
import img3 from "../../Assets/wp-content/uploads/2017/07/Layer-46.png"
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'

const GraphicDesign = () => {
    window.scroll(0,0)
    return (
        <div className="bvm">
        <div id="wrap" className="">
            <section id="main-content" className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper"><br/>
                                            <h1 className="slider-heading"
                                                style={{color: '#113685', textAlign: 'center'}}><strong>Graphic Design
                                                Services</strong></h1>
                                            <p className="slider-sub-heading" style={{textAlign: 'center'}}>Graphic
                                                Design is all about communicating your message through visuals given
                                                form and structure,where by visual information is so as to communicate
                                                your unique message and reflect your unique identity.</p>
                                        </div>
                                    </div>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-2">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper"/>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-8">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={859} height={367}
                                                                     src={img1}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 859px) 100vw, 859px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-2">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="blox   full-container   w-animate " style={{
                paddingTop: 'px',
                paddingBottom: 'px',
                backgroundSize: 'cover',
                minHeight: 'px',
                backgroundColor: '#113685'
            }}>
                <div className="max-overlay" style={{backgroundColor: ''}}/>
                <div className="wpb_row vc_row-fluid full-row">
                    <div className="container">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-8">
                                            <div className="vc_column-inner" style={{marginLeft:"-60px"}}>
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_text_column wpb_content_element  vc_custom_1567490709187">
                                                        <div className="wpb_wrapper">
                                                            <p className="cust-heading">Hire A Graphic Designer</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-4">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <hr className="vertical-space1"/>
                                                    <div
                                                        className="vc_btn3-container  btn-cust button_11 vc_btn3-center">
                                                        <a style={{backgroundColor: '#4975dd', color: '#ffffff'}}
                                                           className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-custom" href="/service/dedicatedResource/hireDedicatedResource">Hier Graphic Designer</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row  top w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div
                                                        className="wpb_single_image wpb_content_element vc_align_center">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={506} height={433}
                                                                     src={img2}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 506px) 100vw, 506px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <hr className="vertical-space1"/>
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <p style={{fontSize: '17px'}}>BVM-Infotech – Graphic
                                                                Design Company India employs universally recognized and
                                                                established visual principles and elements.</p>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow2"><p/>
                                                        <ul className="listing">
                                                            <li>Brand Foundation</li>
                                                            <li>Corporate Presentation</li>
                                                            <li>Letterhead Design</li>
                                                            <li>Logo and Icon Design</li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <p style={{fontSize: '17px'}}>The components including space, shape, form,
                                                mass, line, texture, pattern, time, light, and color compose the basic
                                                vocabulary of visual design. Design policies, such as scale, proportion,
                                                balance, rhythm, emphasis, variety and unity constitute the broader
                                                structural aspects of the composition.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="wpb_row   w-animate full-row">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                            <div
                                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                <span className="vc_sep_holder vc_sep_holder_l"><span
                                    className="vc_sep_line"/></span><span
                                className="vc_sep_holder vc_sep_holder_r"><span className="vc_sep_line"/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row-wrapper-x">
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1563595319584">
                                        <div className="wpb_column vc_column_container vc_col-sm-7">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <h5><strong>How our Graphic Designers can help you stimulate
                                                                your visual appearance</strong></h5>
                                                        </div>
                                                    </div>
                                                    <ul className="arrow"><p/>
                                                        <ul className="listing">
                                                            <li>Our specialist Graphic designers at BVM-Infotech
                                                                have unbound imagination and professional expertise to
                                                                deliver a graphic design solution tailored to your
                                                                message. For us every graphic must comes as an
                                                                individual challenge and we push ourselves to the limits
                                                                to create something which belongs to only you.
                                                            </li>
                                                            <li>Graphics developed for use on the web are especially
                                                                formatted for the internet and designed for visual
                                                                impact. These graphics for the web may add photos, Flash
                                                                animation services, illustration, and other forms of
                                                                media.
                                                            </li>
                                                            <li>If required, BVM-Infotech can also reconfigure your
                                                                existing print logos for use on the web. We can also
                                                                design unique graphics to be used in print from an
                                                                existing logos used on the web. We employ technology
                                                                bundled with creativity to create fresh, innovative and
                                                                conceptual ideas that are bound to stand out.
                                                            </li>
                                                            <li>We secure that graphics designed for the web are fast
                                                                loading, are easy to navigate and enhance your web
                                                                solutions overall ranking in search engine and
                                                                directories.
                                                            </li>
                                                            <li>BVM-Infotech's design team specializes in designing
                                                                all types of business stationery, Brochures,
                                                                Letterheads/envelopes, posters, flyers, catalogs, print
                                                                advertisements and other business media product for both
                                                                online and offline usages.
                                                            </li>
                                                            <li>So if you are in need of an outstanding visual tool to
                                                                convey your unique business message or identity, you can
                                                                trust BVM-Infotech graphic designers to deliver it
                                                                for you.
                                                            </li>
                                                        </ul>
                                                        <p/></ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-5">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_single_image wpb_content_element vc_align_left">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div
                                                                className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width={475} height={550}
                                                                     src={img3}
                                                                     className="vc_single_image-img attachment-full"
                                                                     alt=""
                                                                     sizes="(max-width: 475px) 100vw, 475px"/></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wpb_row   w-animate">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </div>
        </div>
    )
};

export default GraphicDesign;