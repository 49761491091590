import React from 'react';
import pic1 from '../../Assets/images/ReactJS-images/android.png'
import pic2 from '../../Assets/images/ReactJS-images/android_img.png'
import pic3 from '../../Assets/images/ReactJS-images/angularjs_img2.png'
 import pic5 from '../../Assets/images/ReactJS-images/custom-plugins.png'
import pic6 from '../../Assets/images/ReactJS-images/seamless-migration.png'
import pic7 from '../../Assets/images/ReactJS-images/maintenance.png'
import '../../web.scss'
import '../../Assets/wp-content/themes/easyweb/custom.css'
import '../../Assets/wp-content/themes/easyweb/custom-style.scss'

const ReactJSDevelopment = () => {
    window.scroll(0,0)
    return (
        <>
            <div className="bvm">
                <div>
                    <section id="main-content" className="container">
                        <div className="row-wrapper-x">
                            <section className="wpb_row   w-animate">
                                <div className="wpb_column vc_column_container vc_col-sm-8">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_text_column wpb_content_element  vc_custom_1573187804981">
                                                <div className="wpb_wrapper"><br/>
                                                    <h2 style={{color: '#113685'}}><strong>Android&nbsp;Development</strong></h2>
                                                </div>
                                            </div>
                                            <div
                                                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_20 vc_sep_border_width_2 vc_sep_pos_align_left vc_separator_no_text">
                                            <span className="vc_sep_holder vc_sep_holder_l"><span
                                                style={{borderColor: '#113685'}} className="vc_sep_line"/></span><span
                                                className="vc_sep_holder vc_sep_holder_r"><span
                                                style={{borderColor: '#113685'}} className="vc_sep_line"/></span>
                                            </div>
                                            <div className="wpb_text_column wpb_content_element ">
                                                <div className="wpb_wrapper">
                                                    <div className="intricate">
                                                        <h2>Get the open source advantage from licensing, royalty-free, and the best technology framework offered by the Android community. The architecture of the Android SDK is open-source which means you can actually interact with the community for the upcoming expansions of android mobile application development.</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-4">
                                    <div className="vc_column-inner vc_custom_1570682517718">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_single_image wpb_content_element vc_align_left">
                                                <figure className="wpb_wrapper vc_figure">
                                                    <div className="vc_single_image-wrapper   vc_box_border_grey"><img
                                                        width={225} height={200} src={pic1}
                                                        className="vc_single_image-img attachment-full" alt=""/></div>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                    <section className="blox      w-animate " style={{
                        paddingTop: 'px',
                        paddingBottom: 'px',
                        backgroundSize: 'cover',
                        minHeight: 'px',
                        backgroundColor: '#f9f9f9'
                    }}>
                        <div className="max-overlay" style={{backgroundColor: ''}}/>
                        <div className="wpb_row vc_row-fluid full-row">
                            <div className="container">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                    <div className="vc_column-inner vc_custom_1570682775068">
                                        <div className="wpb_wrapper">
                                            <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                <div className="wpb_column vc_column_container vc_col-sm-6">
                                                    <div className="vc_column-inner ">
                                                        <div className="wpb_wrapper">
                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <p>Are you looking for complex technical customization and integration of a web application or just a Smartphone application you already have? Yes. Then an android app can be the right solution for you. The entire platform is ready for customization. You can integrate and tweak the mobile app according to your business need. Android is the best mobile platform between the application and processes architecture. Most of the platforms allow background processes helping you to integrate the apps.</p>
                                                                    <p>Android applications are scripted in Java language with the help of a rich set of libraries. Anyone can build Android applications with the knowledge of Java. According to a recent survey, a lot of Java programmers find it easy to adopt and script code for mobile applications in the Android OS. It is now very beneficial for Java developers to transition the code script into a mobile application, and can also implement android application development services in the app.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="wpb_column vc_column_container vc_col-sm-6">
                                                    <div className="vc_column-inner ">
                                                        <div className="wpb_wrapper">
                                                            <div
                                                                className="wpb_single_image wpb_content_element vc_align_left">
                                                                <figure className="wpb_wrapper vc_figure">
                                                                    <div
                                                                        className="vc_single_image-wrapper   vc_box_border_grey">
                                                                        <img width={500} height={500} src={pic2}
                                                                             className="vc_single_image-img attachment-full"
                                                                             alt=""
                                                                             sizes="(max-width: 500px) 100vw, 500px"/></div>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="container">
                        <div className="row-wrapper-x"/>
                    </section>
                    <section className="blox padding_bottom w-animate " style={{
                        paddingTop: 'px',
                        paddingBottom: 'px',
                        background: 'url("../wp-content/uploads/2019/10/factBg.jpg") no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        minHeight: 'px'
                    }}>
                        <div className="max-overlay" style={{backgroundColor: ''}}/>
                        <div className="wpb_row vc_row-fluid full-row">
                            <div className="container">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                    <div className="vc_column-inner vc_custom_1570682775068">
                                        <div className="wpb_wrapper">
                                            <div className="vc_row wpb_row vc_inner vc_row-fluid flex_section">
                                                <div className="wpb_column vc_column_container vc_col-sm-6">
                                                    <div className="vc_column-inner ">
                                                        <div className="wpb_wrapper">
                                                            <div
                                                                className="wpb_single_image wpb_content_element vc_align_left">
                                                                <figure className="wpb_wrapper vc_figure">
                                                                    <div
                                                                        className="vc_single_image-wrapper   vc_box_border_grey">
                                                                        <img width={500} height={500} src={pic3}
                                                                             className="vc_single_image-img attachment-full"
                                                                             alt=""
                                                                             sizes="(max-width: 500px) 100vw, 500px"/></div>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="wpb_column vc_column_container vc_col-sm-6">
                                                    <div className="vc_column-inner ">
                                                        <div className="wpb_wrapper">
                                                            <div className="wpb_text_column wpb_content_element ">
                                                                <div className="wpb_wrapper">
                                                                    <ul>
                                                                        <li><strong>Low Investment & High ROI:
                                                                             </strong>Android comparatively has a low barrier to entry. Android provides freely its Software Development Kit (SDK) to the developer community which minimizes the development and licensing costs. The development costs can be divided into three stages:
                                                                        </li>
                                                                        <li><strong>Multiple Sales Channels:</strong> applications for vertical markets, to develop new application stores, and also place it on your website. You build it, you publish it. With your choice of promotional strategy, you can reach your end users through multiple channels.
                                                                        </li>
                                                                        <li><strong> Open Source:</strong> Get the open source advantage from licensing, royalty-free, and the best technology framework offered by the Android community. The architecture of the Android SDK is open-source which means you can actually interact with the community for the upcoming expansions of android mobile application development.
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="container">
                        <div className="row-wrapper-x">
                            <section className="wpb_row   w-animate">
                                <div className="margin_top wpb_column vc_column_container vc_col-sm-12">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_text_column wpb_content_element  vc_custom_1581328209457">
                                                <div className="wpb_wrapper">
                                                    <h2 style={{textAlign: 'center'}}>Our scope of Android development
                                                        services</h2>
                                                    <p style={{textAlign: 'center'}}>Android app developers for hire follows the most tested strategies and methods to deliver you with the best quality product.. Our scope of Android
                                                        development services mainly comprises of:</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="wpb_row  react_js_content w-animate">
                                <div className="wpb_column vc_column_container vc_col-sm-6">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_text_column wpb_content_element ">
                                                <div className="wpb_wrapper">
                                                    <div className="row">
                                                        <div className="col-sm-2 col-md-2"><img
                                                            src={pic1} alt="Android"/>
                                                        </div>
                                                        <div className="col-sm-8 col-md-8">
                                                            <div className="titles">Personalized Android Development</div>
                                                            <div className="desc">Our experienced Android developers can
                                                                help you build stunning and interactive UIs for applications
                                                                and potent front-ends and that you and your potential customers
                                                                can appreciate.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-2 col-md-2"><img
                                                            src={pic5}
                                                            alt="React"/></div>
                                                        <div className="col-sm-8 col-md-8">
                                                            <div className="titles">
                                                                Open source facilities</div>
                                                            <div className="desc">BVM-Infotech has provide our hire android developer the latest infrastructure including the open source environment, so that they could deliver a bug-free and best user-experience.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-6">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_text_column wpb_content_element ">
                                                <div className="wpb_wrapper">
                                                    <div className="row">
                                                        <div className="col-sm-2 col-md-2"><img
                                                            src={pic6}
                                                            alt="React"/></div>
                                                        <div className="col-sm-8 col-md-8">
                                                            <div className="titles">Multi Channel selling</div>
                                                            <div className="desc">We allows you to develop the new features and functions for your product to gain more competitive advantages.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-2 col-md-2"><img
                                                            src={pic7}
                                                            alt="React"/></div>
                                                        <div className="col-sm-8 col-md-8">
                                                            <div className="titles">Support and Maintenance</div>
                                                            <div className="desc">Our seasoned team of highly experienced
                                                                Android developers is just a call away to provide you
                                                                dedicated and round-the-clock SLA based support and
                                                                maintenance services, even after the completion of the
                                                                development process.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
};

export default ReactJSDevelopment;