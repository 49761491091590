import React from 'react';
import client from "../Assets/images/img-single/img-clients-min.jpg";

const Client = () => {
    return(
        <div className="flat-row bg-f7f7f7 pad-top0px pad-bottom0px">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="section-testimonial">
                            <div className="title-section style_2">
                                <h2 className="title text-center" >What our<br/>clients say about us</h2>
                            </div>
                            <div className="flat-divider d20px"/>
                            <div className="carousel slide" data-ride="carousel" id="quote-carousel"
                                 data-interval={5000}>
                                <div className="carousel-inner text-center">
                                    <div className="item active">
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-sm-8 col-sm-offset-2">
                                                    <p className="text-justify">BVM-Infotech was instrumental in
                                                        providing us the
                                                        expertise and scale required to delivery successfully on
                                                        key
                                                        BI/DW projects. They have a very customer friendly
                                                        resourcing
                                                        model with the right experience levels and skill set
                                                        that can
                                                        help a spectrum of companies.!</p>
                                                    <div className="right">
                                                        <p className="name">Shachar Bialick</p>
                                                        <p className="small">CEO, Neo-bank</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                    <div className="item">
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-sm-8 col-sm-offset-2">
                                                    <p className="text-justify">BVM-Infotech Technologies, ably led by
                                                        Rajesh, has
                                                        been an excellent partner for us in our data warehousing
                                                        projects. The company has supplied us with well-trained
                                                        engineers who also have excellent work ethic and
                                                        analytical
                                                        skills. </p>
                                                    <div className="right">
                                                        <p className="name">Matthew Roggenburg</p>
                                                        <p className="small">Founder, CEO, Munivisor</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                    <div className="item">
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-sm-8 col-sm-offset-2">
                                                    <p className="text-justify">BVM-Infotech Technologies has been a
                                                        trusted
                                                        partner of Activecubes. They have done an excellent job
                                                        in
                                                        addressing our BI/DW staffing needs as they understand
                                                        this
                                                        space very well. Their consultants have always delivered
                                                        as
                                                        expected or exceeded expectations in some cases...</p>
                                                    <div className="right">
                                                        <p className="name">Frederik Roy Rasmussen</p>
                                                        <p className="small">CEO and President,InnRoad</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                                <ol className="carousel-indicators">
                                    <li data-target="#quote-carousel" data-slide-to={0} className="active"><img
                                        className="img-responsive " alt=""/>
                                    </li>
                                    <li data-target="#quote-carousel" data-slide-to={1}><img
                                        className="img-responsive" alt=""/>
                                    </li>
                                    <li data-target="#quote-carousel" data-slide-to={2}><img
                                        className="img-responsive" alt=""/>
                                    </li>
                                </ol>
                                <a data-slide="prev" href="#quote-carousel" className="left carousel-control"><i
                                    className="fa fa-chevron-left"/></a>
                                <a data-slide="next" href="#quote-carousel"
                                   className="right carousel-control"><i className="fa fa-chevron-right"/></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="image-single">
                            <img src={client} alt="images"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Client;